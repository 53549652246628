import { Box, Button, Text } from "@hkexpressairwayslimited/ui/src";
import { Option as BaseOption, OptionProps as BaseOptionProps, optionClasses } from "@mui/base";
import { SelectListboxSlotProps } from "@mui/base/Select";
import { styled } from "@mui/material/styles";
import { clsx } from "clsx";
import { ForwardedRef, forwardRef } from "react";
import classes from "./DropdownListbox.module.scss";
import { TriangleDropdownListBox } from "./TriangleDropdownListBox";

export const DropdownListbox = forwardRef(
  <OptionValue extends string, Multiple extends boolean>(
    {
      open,
      ownerState,
      className,
      trianglePopupTitleLabel,
      isGrouping,
      closeListBox,
      ...others
    }: {
      open?: boolean;
      trianglePopupTitleLabel?: string;
      isGrouping?: boolean;
      closeListBox: () => void;
    } & SelectListboxSlotProps<OptionValue, Multiple>,
    ref: ForwardedRef<HTMLUListElement>
  ) => {
    return isGrouping ? (
      <TriangleDropdownListBox closeListBox={closeListBox} titleLabel={trianglePopupTitleLabel} className={className}>
        <Box className={clsx(classes.selectGroupListbox, open && classes.selectListboxOpen)}>
          <Box component='ul' className={classes.selectListbox_list} {...others} ref={ref} />
        </Box>
      </TriangleDropdownListBox>
    ) : (
      <Box className={clsx(classes.selectListbox, open && classes.selectListboxOpen, className)}>
        <Box component='ul' className={classes.selectListbox_list} {...others} ref={ref} />
      </Box>
    );
  }
);
DropdownListbox.displayName = "DropdownListbox";

export type DropdownOptionProps<OptionValue extends string> = BaseOptionProps<OptionValue>;

const Option = styled(BaseOption)(
  ({ theme, isGrouping }) => `
  &.${optionClasses.selected} {
    color: ${theme.palette.purple.default};
    position: relative;
    padding-right: ${isGrouping ? "30px" : "0"};
  }
  &.${optionClasses.selected}::after {
    display: ${isGrouping ? "block" : "none"};
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    right: 10px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNiA0QzkuMzcyNTggNCA0IDkuMzcyNTggNCAxNkM0IDIyLjYyNzQgOS4zNzI1OCAyOCAxNiAyOEMyMi42Mjc0IDI4IDI4IDIyLjYyNzQgMjggMTZDMjggOS4zNzI1OCAyMi42Mjc0IDQgMTYgNFpNMiAxNkMyIDguMjY4MDEgOC4yNjgwMSAyIDE2IDJDMjMuNzMyIDIgMzAgOC4yNjgwMSAzMCAxNkMzMCAyMy43MzIgMjMuNzMyIDMwIDE2IDMwQzguMjY4MDEgMzAgMiAyMy43MzIgMiAxNloiIGZpbGw9IiM3MDJiOTEiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yMi42MzE0IDEyLjVMMTQuNTY1NyAyMC41NjU3QzE0LjQxNTYgMjAuNzE1NyAxNC4yMTIyIDIwLjggMTQgMjAuOEMxMy43ODc4IDIwLjggMTMuNTg0MyAyMC43MTU3IDEzLjQzNDMgMjAuNTY1N0w5LjM2ODY1IDE2LjVMMTAuNSAxNS4zNjg3TDE0IDE4Ljg2ODdMMjEuNSAxMS4zNjg3TDIyLjYzMTQgMTIuNVoiIGZpbGw9IiM3MDJiOTEiLz4KPC9zdmc+Cg==");
    background-repeat: no-repeat;
    background-size: cover;
    transform: translate(0, -50%);
  }
  &:hover:not(.${optionClasses.disabled}) {
    color: ${theme.palette.purple.default};
  }
  `
);

export const DropdownOption = forwardRef(
  <OptionValue extends string>(
    { isGrouping, children, className, ...others }: { isGrouping?: boolean } & DropdownOptionProps<OptionValue>,
    ref: ForwardedRef<HTMLLIElement>
  ) => {
    return (
      <Option className={className} {...others} ref={ref} isGrouping={isGrouping}>
        <Button
          className={clsx(
            isGrouping && classes.dropdownOption_group_button,
            !isGrouping && classes.dropdownOption_button
          )}
          custom
        >
          <Text small>{children}</Text>
        </Button>
      </Option>
    );
  }
);
DropdownOption.displayName = "DropdownOption";
