import { Input, InputProps } from "@mui/material";
import { clsx } from "clsx";
import {
  ChangeEvent,
  ChangeEventHandler,
  ReactElement,
  ReactNode,
  SVGProps,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { AppContext, Box, Button, FormFieldProps, SvgIcon, Text, getInnerText } from "@hkexpressairwayslimited/ui/src";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import classes from "./TextInput.module.scss";

export enum TextInputVariant {
  Primary = "Primary",
}

export type TextInputProps = {
  variant?: TextInputVariant;
  center?: boolean;
  icon?: ReactNode;
  leadingIcon?: ReactElement<SVGProps<SVGSVGElement>>;
  trailingIcon?: ReactElement<SVGProps<SVGSVGElement>>;
  value?: string;
  isPassword?: boolean;
  placeholder?: ReactNode;
  displayText?: ReactNode;
} & Partial<Omit<FormFieldProps, "value" | "error">> &
  Omit<InputProps, "placeholder">;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      isPassword,
      value,
      placeholder,
      className,
      variant = TextInputVariant.Primary,
      fullWidth,
      center,
      icon,
      leadingIcon,
      trailingIcon,
      onFocus,
      onBlur,
      onChange,
      onInput,
      error,
      invalid,
      isDirty,
      isTouched,
      isValidating,
      displayText,
      groupSubFieldHandle,
      endAdornment,
      "aria-label": ariaLabel,
      ...others
    },
    ref
  ) => {
    const { isInEditor } = useContext(AppContext);
    const [formValue, setFormValue] = useState(value);
    const [focused, setFocused] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const finalAriaLabel = useMemo(
      () => ariaLabel || getInnerText(placeholder) || getInnerText(displayText) || formValue,
      [ariaLabel, placeholder, displayText, formValue]
    );

    const handleValueChange = (val: TextInputProps["value"]) => {
      setFormValue(val);
    };

    const handleInput: ChangeEventHandler<HTMLInputElement> = (event) => {
      onInput && onInput(event);
      handleValueChange(event.target.value);
      if (onChange) {
        onChange(event);
      }
    };

    const handlePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    };

    useEffect(() => {
      handleValueChange(value);
      onInput &&
        onInput({
          target: {
            value: value,
          },
        } as ChangeEvent<HTMLInputElement>);
    }, [value]);

    return (
      <Box
        className={clsx(classes.textInput, className, {
          [classes.textInput__center]: center,
          [classes.textInput__fullWidth]: fullWidth,
          [classes.textInput__isInEditor]: isInEditor,
          [classes.textInput__hideInput]: !!displayText,
        })}
      >
        <Box className={classes.textInput_inputWrapper}>
          {icon && <Box className={classes.textInput_iconWrapper}>{icon}</Box>}
          {leadingIcon && <Box className={classes.textInput_iconWrapper}>{leadingIcon}</Box>}
          <div className={classes.textInput_inputElement}>
            <Input
              type={!isPassword || passwordVisible ? "text" : "password"}
              className={classes.textInput_input}
              onChange={handleInput}
              fullWidth
              onFocus={(...args) => {
                setFocused(true);
                onFocus?.(...args);
              }}
              onBlur={(...args) => {
                setFocused(false);
                onBlur?.(...args);
              }}
              {...others}
              aria-label={finalAriaLabel}
              hidden={others.hidden}
              value={!isInEditor ? formValue : ""}
              readOnly={others.readOnly || isInEditor}
              ref={ref}
              endAdornment={
                endAdornment
                  ? endAdornment
                  : isPassword && (
                      <Button custom onClick={handlePasswordVisibility}>
                        <SvgIcon className={classes.textInput_visible}>
                          {passwordVisible ? <Visibility /> : <VisibilityOff />}
                        </SvgIcon>
                      </Button>
                    )
              }
            />
            {displayText && <Text>{displayText}</Text>}
            {((!focused && !formValue && !displayText) || isInEditor) && (
              <Text className={classes.textInput_placeholder}>{placeholder}</Text>
            )}
          </div>
          {trailingIcon && <Box className={classes.textInput_iconWrapper}>{trailingIcon}</Box>}
        </Box>
      </Box>
    );
  }
);
TextInput.displayName = "TextInput";
