import { EPixel, ESpacing } from "@hkexpressairwayslimited/ui/src";
import React, { ReactNode } from "react";

export type SizeMapping = Record<string, ESpacing | EPixel | string>;
const desktopSpacingMapping: SizeMapping = {
  l: `${ESpacing._sm} ${ESpacing._lg}`,
  m: `${ESpacing._s} ${ESpacing._s}`,
  s: `${ESpacing._s} ${ESpacing._2xs}`,
};
const mobileSpacingMapping: SizeMapping = {
  l: `${ESpacing._s} ${ESpacing._s}`,
  m: `${ESpacing._s} ${ESpacing._s}`,
  s: `${ESpacing._s} ${ESpacing._s}`,
};

export const iconFSMapping: SizeMapping = {
  l: EPixel.px28,
  m: EPixel.px24,
  s: EPixel.px22,
};
export const fsMapping: SizeMapping = {
  l: EPixel.px32,
  m: EPixel.px28,
  s: EPixel.px24,
};
export const getSpacing = (size = "m", isMobile = false): string => {
  if (isMobile) {
    return mobileSpacingMapping[size];
  }
  return desktopSpacingMapping[size];
};

export function propTrueValue(prop: any): boolean {
  return prop === true || prop === "true";
}
export type SlotMapping = {
  content?: ReactNode;
  header?: ReactNode;
  footer?: ReactNode;
};
export function slotMapping(children?: ReactNode): SlotMapping {
  const map: SlotMapping = {
    content: undefined,
    header: undefined,
    footer: undefined,
  };
  if (children) {
    React.Children.forEach(children, (element, index) => {
      try {
        if (element && (element as any).type.displayName === "CardHeader") {
          map.header = element;
        }
        if (element && (element as any).type.displayName === "CardContent") {
          map.content = element;
        }
        if (element && (element as any).type.displayName === "CardFooter") {
          map.footer = element;
        }
      } catch (e) {
        // console.log("slotMapping error: " + e);
      }
    });
  }
  return map;
}
