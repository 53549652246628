/* eslint-disable @typescript-eslint/ban-types */
import { FormControl, InputLabel, MenuItem, Select, FormHelperText } from "@mui/material";
import { Controller, useFormContext, FieldError, useWatch } from "react-hook-form";
import React, { useEffect } from "react";
import { MenuItemType } from "../../Organisms/PaymentRequestForm/paymentTypes";
import { set } from "lodash";

export interface ControlledCustomMenuProps {
  data: MenuItemType;
  label: string;
  id: string;
  name: string;
  autoFillData?: string;
  autoFillTarget?: string;
}

export interface CustomMenuProps extends ControlledCustomMenuProps {
  selected: string;
  onChange: () => void;
  onBlur: () => void;
  error: FieldError | undefined;
}

const CustomMenu = ({
  data,
  label,
  id,
  selected,
  onChange,
  onBlur,
  error,
  autoFillData,
  autoFillTarget,
}: CustomMenuProps) => {
  const { setValue, getFieldState } = useFormContext();

  const found = Object?.values(data).find((item) => item.value === selected);

  useEffect(() => {
    const sales_split = autoFillTarget?.split(".");
    const currency = `${sales_split?.[0]}.${sales_split?.[1]}.currency`;
    const description = `${sales_split?.[0]}.${sales_split?.[1]}.description`;
    if (
      found &&
      autoFillData &&
      autoFillTarget &&
      !getFieldState(autoFillTarget).isDirty &&
      autoFillTarget !== "notificationEmail"
    ) {
      setValue(autoFillTarget, found[autoFillData]);
      setValue(currency, "HKD");
    } else if (selected === "" && autoFillTarget && autoFillTarget !== "notificationEmail") {
      setValue(autoFillTarget, 0);
      setValue(description, "");
      setValue(currency, "");
    } else if (found && autoFillData && autoFillTarget && autoFillTarget === "notificationEmail") {
      setValue(autoFillTarget, found[autoFillData]);
    }
  }, [selected]);

  return (
    <FormControl fullWidth variant='filled' error={!!error}>
      <InputLabel id={id} error={!!error}>
        {label}
      </InputLabel>
      <Select
        value={selected}
        onChange={onChange}
        onBlur={onBlur}
        labelId={id}
        id={id}
        label={label}
        defaultValue={""}
        sx={{
          borderRadius: 0,
          border: "solid 1px lightgrey",
          backgroundColor: "neutral.white",
        }}
      >
        <MenuItem value=''>{label}</MenuItem>
        {Object?.values(data).map((value, index) => {
          return (
            <MenuItem key={index} value={value.value}>
              {value.code}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{error ? error.message : ""}</FormHelperText>
    </FormControl>
  );
};

const PaymentMenu = ({ data, label, id, name, autoFillData, autoFillTarget }: ControlledCustomMenuProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <CustomMenu
          error={error}
          name={name}
          data={data}
          label={label}
          id={id}
          selected={value}
          onChange={onChange}
          onBlur={onBlur}
          autoFillData={autoFillData}
          autoFillTarget={autoFillTarget}
        />
      )}
    />
  );
};
export { PaymentMenu };
