import { clsx } from "clsx";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { ForwardedRef, MouseEventHandler, ReactNode, TouchEventHandler, forwardRef, useContext } from "react";

import {
  AppContext,
  Button,
  ButtonVariant,
  PartialBy,
  Text,
  TextProps,
  TextSize,
} from "@hkexpressairwayslimited/ui/src";
import { Link as MuiLink, LinkProps as MuiLinkProps } from "@mui/material";

import classes from "./Link.module.scss";

export type LinkProps = {
  size?: TextSize;
  white?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  onMouseEnter?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  onTouchStart?: TouchEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  unbold?: boolean;
  color?: TextProps["color"];
  href?: string;
} & PartialBy<Omit<NextLinkProps, "onClick">, "href"> &
  MuiLinkProps;

const WrappedTextButton = forwardRef<
  HTMLButtonElement,
  {
    color?: TextProps["color"];
    children?: ReactNode;
    onClick?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
    onMouseEnter?: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
    onTouchStart?: TouchEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  } & NextLinkProps
>(({ color, children, onClick, onMouseEnter, onTouchStart, ...props }, ref) => {
  return (
    <Button
      variant={ButtonVariant.Text}
      color={color}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onTouchStart={onTouchStart}
      ref={ref}
    >
      {children}
    </Button>
  );
});
WrappedTextButton.displayName = "WrappedTextButton";

export const Link = forwardRef(
  (
    { className, size, white, children, color, href = "#", unbold, onClick, ...others }: LinkProps,
    ref: ForwardedRef<HTMLAnchorElement>
  ) => {
    const { isInEditor } = useContext(AppContext);
    // Accessibility: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#onclick_events
    const isButton = typeof href === "string" && (href === "#" || href === "javascript:void(0)") && onClick;
    const link = (
      <MuiLink
        className={clsx(classes.link, className, {
          [classes.link__white]: white,
          [classes.link__unbold]: unbold,
        })}
        component={isButton ? WrappedTextButton : NextLink}
        href={isInEditor ? "#" : href}
        onClick={onClick}
        {...others}
        ref={ref}
      >
        {color ? (
          <Text color={color} span>
            {children}
          </Text>
        ) : (
          children
        )}
      </MuiLink>
    );

    return size ? (
      <Text size={size} span>
        {link}
      </Text>
    ) : (
      link
    );
  }
);
Link.displayName = "Link";
