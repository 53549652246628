import { Box, Button, Grid, ListItemText, MenuItem, MenuList, Popper, Stack, styled, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const StyledPopper = styled(Popper)(({ theme }) => ({
  top: "20px !important",
  background: "#fff",
  width: "100%",
}));

const PopperWrapper = styled(Box)(({ theme }) => ({
  width: "1100px",
  margin: "0 auto",
  padding: "30px 14px 14px 30px",
  backgroundColor: "#fff",
  boxShadow: "0 0 8px 0 rgba(0,0,0,.27);",
  transition: "opacity .14s ease-in-out,top .27s ease-in-out;",
}));

export interface NavListProps {
  menus: NavList[];
  className?: string;
  scrolled: boolean;
}

export interface LinkItem {
  label?: string;
  href?: string;
  icon?: "";
  mt?: string;
}
export interface LinkList extends LinkItem {
  links?: LinkItem[];
}
export interface NavList extends LinkItem {
  links?: LinkList[][];
}

const MenuStack = (props: NavListProps) => {
  const { menus, className, scrolled } = props;
  const menuListEl = useRef<any>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [menuItemFocusIndex, setMenuItemFocusIndex] = useState<number>(-1);
  const [subItemFocusIndex, setSubItemFocusIndex] = useState<number>(0);
  const open = Boolean(anchorEl) && Boolean(menus[menuItemFocusIndex]?.links?.length);

  useEffect(() => {
    menuListEl.current.children[menuItemFocusIndex]?.focus();
  }, [menuItemFocusIndex]);

  let timer: string | number | NodeJS.Timeout | undefined = "";

  const handleMenuEnter = (index: number) => (event: React.MouseEvent<HTMLElement> | React.FocusEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuItemFocusIndex(index);
    clearTimeout(timer);
  };
  const handleMenuLeave = (e: React.MouseEvent) => {
    timer = setTimeout(function () {
      if (!poPerEntered) {
        closePopover();
      }
    }, 300);
  };
  let poPerEntered = false;
  const handlePoPerMouseEnter = () => {
    poPerEntered = true;
  };
  const handlePoPerMouseLeave = () => {
    poPerEntered = false;
    closePopover();
  };
  const closePopover = () => {
    setAnchorEl(null);
    setSubItemFocusIndex(0);
  };

  const handlePopoverItemKeyDown = (e: React.KeyboardEvent) => {
    if (e.code === "ArrowLeft") {
      if (subItemFocusIndex - 1 >= 0) {
        setSubItemFocusIndex(subItemFocusIndex - 1);
      } else {
        anchorEl?.focus();
        closePopover();
      }
    }

    if (e.code === "ArrowRight") {
      const subItemLength = menus[menuItemFocusIndex]?.links?.length || 0;
      if (subItemFocusIndex + 1 < subItemLength) {
        setSubItemFocusIndex(subItemFocusIndex + 1);
      } else {
        anchorEl?.focus();
        closePopover();
      }
    }

    if (e.code === "Enter") {
      (e.target as HTMLElement).click();
    }
  };

  const handleMenuKeyDown = (e: React.KeyboardEvent) => {
    if (!open) {
      if (e.code === "ArrowLeft") {
        menuItemFocusIndex - 1 >= 0 && setMenuItemFocusIndex(menuItemFocusIndex - 1);
      }

      if (e.code === "ArrowRight") {
        menuItemFocusIndex + 1 < menus.length && setMenuItemFocusIndex(menuItemFocusIndex + 1);
      }

      if (e.code === "Enter") {
        setAnchorEl(e.target as HTMLElement);
      }
    }
  };

  const handleSubMenuClick = (item: any) => {
    if (item.onClick) {
      item.onClick();
    } else {
      // window.location = item.href;
    }
  };

  return (
    <Stack
      sx={{ width: "100%" }}
      className={className}
      direction='row'
      justifyContent='space-around'
      alignItems='baseline'
      spacing={2}
      onKeyDown={handleMenuKeyDown}
      ref={menuListEl}
    >
      {menus.map((menu, index) => (
        <Button key={menu.label} onMouseEnter={handleMenuEnter(index)} onMouseLeave={handleMenuLeave}>
          <Typography color={scrolled ? "white" : "black"} fontWeight={700}>
            {menu.label}
          </Typography>
        </Button>
      ))}
      {/*  Frequent menu switching can lead to Popper content render issue */}
      <StyledPopper open={open} anchorEl={anchorEl} onKeyDown={handlePopoverItemKeyDown}>
        <PopperWrapper onMouseEnter={handlePoPerMouseEnter} onMouseLeave={handlePoPerMouseLeave}>
          <Grid sx={{ width: "100%" }} container spacing={2}>
            {menus[menuItemFocusIndex]?.links?.map((subItem, index) => (
              <Grid
                sx={{ maxWidth: "25%" }}
                key={subItem[0].label}
                xs
                display='flex'
                justifyContent='center'
                alignItems='baseline'
              >
                <Stack spacing={2}>
                  {subItem.map((item) => (
                    <>
                      <Typography key={item.label} color='secondary' variant='h5'>
                        {item.label}
                      </Typography>
                      {item.links?.length && (
                        <MenuList
                          key={item.label}
                          autoFocus={subItemFocusIndex === index} // bug here need to fix
                        >
                          {item.links?.map((link) =>
                            link ? (
                              <MenuItem key={link.label} onClick={() => handleSubMenuClick(link)}>
                                <ListItemText color='secondary'>{link.label}</ListItemText>
                              </MenuItem>
                            ) : null
                          )}
                        </MenuList>
                      )}
                    </>
                  ))}
                </Stack>
              </Grid>
            ))}
          </Grid>
        </PopperWrapper>
      </StyledPopper>
    </Stack>
  );
};

export { MenuStack };
