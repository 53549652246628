import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps } from "@mui/material";
import clsx from "clsx";
import { CSSProperties, ReactElement, ReactNode, forwardRef } from "react";

import {
  Button,
  ButtonVariant,
  CloseIcon,
  SvgIcon,
  SvgIconSize,
  Title,
  TitleOrders,
  colors,
} from "@hkexpressairwayslimited/ui/src";

import { merge } from "lodash";
import classes from "./Drawer.module.scss";

type DrawerProps = {
  title?: string | ReactNode;
  titleIcon?: ReactElement;
  titleIconColor?: CSSProperties["color"];
  noBorderRadius?: boolean;
  noPadding?: boolean;
  fullWidth?: boolean;
  hideBackdrop?: boolean;
} & MuiDrawerProps;

export const Drawer = forwardRef<HTMLDivElement, DrawerProps>(
  (
    {
      onClose,
      title,
      noBorderRadius = false,
      noPadding = false,
      fullWidth = false,
      hideBackdrop = false,
      titleIcon,
      titleIconColor = colors.actionPurpleDefault,
      className,
      children,
      anchor = "right",
      ModalProps,
      ...rest
    },
    ref
  ) => (
    <MuiDrawer
      className={clsx(className, classes.drawer, {
        [classes.drawer__left]: anchor === "left",
        [classes.drawer__right]: anchor === "right",
        [classes.drawer__noBorderRadius]: noBorderRadius,
        [classes.drawer__noPadding]: noPadding,
        [classes.drawer__fullWidth]: fullWidth,
        [classes.drawer__hideBackdrop]: hideBackdrop,
      })}
      disableScrollLock
      onClose={onClose}
      ModalProps={merge({}, { disablePortal: true }, ModalProps)}
      anchor={anchor}
      {...rest}
      ref={ref}
    >
      {title && (
        <div className={classes.drawer_upper}>
          <div className={classes.drawer_title}>
            {titleIcon && (
              <SvgIcon size={SvgIconSize.DenseLayout} color={titleIconColor}>
                {titleIcon}
              </SvgIcon>
            )}
            <Title order={TitleOrders.H2Headline} color={colors.neutralBlack}>
              {title}
            </Title>
          </div>

          <Button
            color={colors.neutralBlack}
            variant={ButtonVariant.Icon}
            noBorder
            onClick={(event) => onClose?.(event, "escapeKeyDown")}
          >
            <CloseIcon />
          </Button>
        </div>
      )}
      <div className={classes.drawer_content}>{children}</div>
    </MuiDrawer>
  )
);
Drawer.displayName = "Drawer";
