import { createContext, Dispatch, SetStateAction } from "react";

import { MilesPlusCashPaymentOptionsProps } from "./MilesPlusCashPaymentOptions";

export enum MilesPlusCashPaymentOptionsSteps {
  Form = "Form",
  View = "View",
}

type MilesPlusCashPaymentContextValues = {
  step: MilesPlusCashPaymentOptionsSteps;
  setStep: Dispatch<SetStateAction<MilesPlusCashPaymentOptionsSteps>>;
  milesUsed: number;
  setMilesUsed: Dispatch<SetStateAction<number>>;
  milesUsedInCash: number;
  setMilesUsedInCash: Dispatch<SetStateAction<number>>;
  readyOnly?: boolean;
} & MilesPlusCashPaymentOptionsProps;

export const MilesPlusCashPaymentContext = createContext<MilesPlusCashPaymentContextValues>({} as any);
