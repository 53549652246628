import { Button, ButtonVariant, EFont, Font } from "@hkexpressairwayslimited/ui/src";
import { CopyIcon } from "@hkexpressairwayslimited/ui/src/assets";
import { Box } from "@mui/material";
import { Text } from "../../Atoms/Text";
import { Title } from "../../Atoms/Title";

export interface ActionCardProps {
  buttonManage?: string | JSX.Element | JSX.Element[];
  buttonAction?: () => void;
  copyLabel: string | JSX.Element | JSX.Element[];
  referenceDes: string | JSX.Element | JSX.Element[];
  referenceLabel: string | JSX.Element | JSX.Element[];
  desc: string | JSX.Element | JSX.Element[];
}

const copyRef = (ref: string) => {
  navigator.clipboard.writeText(ref);
};

const ActionCard = (props: ActionCardProps) => {
  const { buttonManage, buttonAction, copyLabel, referenceDes, referenceLabel, desc } = props;
  return (
    <Box border={"1px solid #D6D6D8"} borderRadius={4} padding={2}>
      <Box display={"flex"} justifyContent={"space-between"} flexDirection={{ xs: "column", sm: "row" }}>
        <Box>
          <Text bold>{referenceDes}</Text>
          <Box display={"flex"} alignItems={"center"} marginTop={{ xs: 1, sm: 0 }}>
            <Title order={2} size={26}>
              {referenceLabel}
            </Title>
            <Box ml={1} style={{ cursor: "pointer" }}>
              <Box display={"flex"} alignItems={"center"} onClick={() => copyRef(referenceLabel as string)}>
                <Text color={`#702b91`}>{copyLabel}</Text>
                <Box ml={1} display={"flex"} alignItems={"center"}>
                  <CopyIcon style={{ width: 12 }} />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box marginTop={{ xs: 1, sm: 0 }}>
          {buttonManage && (
            <Button onClick={buttonAction} variant={ButtonVariant.Secondary}>
              {buttonManage}
            </Button>
          )}
        </Box>
      </Box>
      <Box marginTop={{ xs: 1, sm: 0 }}>
        <Font variant={EFont.p3}>{desc}</Font>
      </Box>
    </Box>
  );
};

export { ActionCard };
