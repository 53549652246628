import { Box, Button, CrossOutlineIcon, EFont, ESpacing, Font, SvgIcon } from "@hkexpressairwayslimited/ui/src";
import { Stack } from "@mui/material";
import { clsx } from "clsx";
import { ReactNode } from "react";
import classes from "./BasicModalTemplate.module.scss";

export type BasicModalTemplateProps = {
  children: ReactNode;
  autoWidth: boolean;
  onCrossClick?: () => void;
  isSetMaxHeight?: boolean;
  noPadding?: boolean;
};
export const BasicModalTemplate = ({ children, isSetMaxHeight, autoWidth, noPadding }: BasicModalTemplateProps) => {
  const isNeedMaxHeight = isSetMaxHeight === undefined ? true : isSetMaxHeight;
  return (
    <Box
      className={clsx(classes.basicModalTemplate, {
        [classes.basicModalTemplate__autoWidth]: autoWidth,
        [classes.basicModalTemplate__noPadding]: noPadding,
      })}
    >
      <Box
        className={clsx(
          classes.basicModalTemplate_child,
          !isNeedMaxHeight && classes.basicModalTemplate_overflow_initial
        )}
      >
        {children}
      </Box>
    </Box>
  );
};

export type BasicModalContentProps = { title?: ReactNode; children: ReactNode; onCrossClick?: () => void };
export const BasicModalContent = ({ title, children, onCrossClick }: BasicModalContentProps) => {
  const closeBtn = onCrossClick && (
    <Button custom className={classes.basicModalTemplate_closeButton} onClick={onCrossClick}>
      <SvgIcon sx={{ width: ESpacing._md, height: ESpacing._md }}>
        <CrossOutlineIcon />
      </SvgIcon>
    </Button>
  );

  return (
    <Box
      className={clsx(classes.basicModalTemplate_content, {
        [classes.basicModalTemplate_content__noTitle]: !title,
      })}
    >
      {title ? (
        <Stack direction='row' justifyContent='space-between' alignItems='flex-start'>
          <Font variant={EFont.h2} fontWeight='fontWeightBold' sx={{ overflow: "hidden" }}>
            {title}
          </Font>
          {closeBtn}
        </Stack>
      ) : (
        closeBtn
      )}
      <Box>{children}</Box>
    </Box>
  );
};
