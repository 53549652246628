import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function CheckedIcon() {
  return (
    <SvgIcon>
      {/* credit: plus icon from https://heroicons.com/ */}
      <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16ZM10 11.6296L14.3704 16L10 20.3704L11.6296 22L16 17.6296L20.3704 22L22 20.3704L17.6296 16L22 11.6296L20.3704 10L16 14.3704L11.6296 10L10 11.6296Z'
          fill='#21B9DA'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M28 16C28 22.6274 22.6274 28 16 28C9.37258 28 4 22.6274 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16ZM10 11.6296L14.3704 16L10 20.3704L11.6296 22L16 17.6296L20.3704 22L22 20.3704L17.6296 16L22 11.6296L20.3704 10L16 14.3704L11.6296 10L10 11.6296Z'
          fill='#21B9DA'
        />
        <mask id='path-3-outside-1_412_664' maskUnits='userSpaceOnUse' x='3' y='3' width='26' height='26' fill='black'>
          <rect fill='white' x='3' y='3' width='26' height='26' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z'
          />
        </mask>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z'
          fill='white'
        />
        <path
          d='M27 16C27 22.0751 22.0751 27 16 27V29C23.1797 29 29 23.1797 29 16H27ZM16 5C22.0751 5 27 9.92487 27 16H29C29 8.8203 23.1797 3 16 3V5ZM5 16C5 9.92487 9.92487 5 16 5V3C8.8203 3 3 8.8203 3 16H5ZM16 27C9.92487 27 5 22.0751 5 16H3C3 23.1797 8.8203 29 16 29V27Z'
          fill='black'
          mask='url(#path-3-outside-1_412_664)'
        />
      </svg>
    </SvgIcon>
  );
}
