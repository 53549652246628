import * as React from "react";
import clsx from "clsx";
import { Typography } from "../../../styles";
import { CheckboxField, CheckboxFieldProps } from "../CheckboxField/CheckboxField";
import { TextField } from "../TextField/TextField";
import { Grid } from "@mui/material";

export interface SelectionSection {
  title: string;
  options: CheckboxFieldProps[];
}

export interface Filter {
  id: string;
  label: string;
  placeholder: string;
}

export interface FilterSelectionProps {
  selectionSections: SelectionSection[];
  filter: Filter;
  onChange?: (selectedOptionKeys: string[]) => void;
}

const escapeRegExp = (targetStr: string) => {
  return targetStr.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

/**
 * Filter the mismatch options
 * @param selectionSections
 * @param keywords
 * @returns
 */
const filterFunc = (selectionSections: SelectionSection[], keywords: string) => {
  const filteredSelectionSections = [] as SelectionSection[];

  selectionSections.forEach((selectionSection) => {
    const options = selectionSection.options;

    const filteredOptions = options.filter((option) => {
      const searchTargetNoSpace = option.itemList[0].label
        .toLowerCase()
        .replace(new RegExp(escapeRegExp(" "), "g"), "");
      const keywordsNoSpace = keywords.toLowerCase().replace(new RegExp(escapeRegExp(" "), "g"), "");
      return searchTargetNoSpace.match(keywordsNoSpace);
    });

    filteredSelectionSections.push({
      title: selectionSection.title,
      options: filteredOptions,
    });
  });

  return filteredSelectionSections;
};

const FilterSelection = (props: FilterSelectionProps) => {
  /** Filter Logic */
  const [filteredSelectionSections, setFilteredSelectionSections] = React.useState<SelectionSection[]>(
    props.selectionSections
  );
  const filterOnChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const keywords = e.target.value;
      const filteredResults = filterFunc(props.selectionSections, keywords);
      setFilteredSelectionSections(filteredResults);
    },
    [props.selectionSections]
  );

  /** Selected Options Logic */
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);
  const onSelectOption = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      const optionKey = e.target.value;

      let newSelectedOptions: string[] = [];
      if (isChecked) {
        newSelectedOptions = [...selectedOptions, optionKey];
      } else {
        newSelectedOptions = selectedOptions.filter((v) => v !== optionKey);
      }

      setSelectedOptions(newSelectedOptions);
      props.onChange && props.onChange(newSelectedOptions);
    },
    [selectedOptions]
  );

  return (
    <>
      <Grid container spacing={3} columns={12}>
        <Grid item xs={12}>
          <TextField
            id={props.filter.id}
            label={props.filter.label}
            helperText={props.filter.placeholder}
            defaultValue={""}
            onChange={filterOnChange}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          {filteredSelectionSections &&
            filteredSelectionSections.map((selectionSection: SelectionSection, index: number) => {
              return (
                <div key={index}>
                  <div className={clsx(Typography.SUBTITLE1)}>{selectionSection.title}</div>

                  {selectionSection.options &&
                    selectionSection.options.map((option: CheckboxFieldProps, index: number) => {
                      return (
                        <CheckboxField
                          key={index}
                          id={option.id}
                          label={option.label}
                          itemList={option.itemList}
                          onChange={onSelectOption}
                        ></CheckboxField>
                      );
                    })}
                </div>
              );
            })}
        </Grid>
      </Grid>
    </>
  );
};

export { FilterSelection };
