import { Tab } from "@mui/base/Tab";
import { TabPanel } from "@mui/base/TabPanel";
import { Tabs } from "@mui/base/Tabs";
import { TabsList } from "@mui/base/TabsList";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import { clsx } from "clsx";
import { MouseEvent, useState } from "react";

import { DateRangeCalendarValue } from "@hkexpressairwayslimited/ui/src";
import { Box } from "../../Atoms/Box";
import { Button, ButtonVariant } from "../../Atoms/Button/Button";
import { Link } from "../../Atoms/Link";
import { TextInput } from "../../Atoms/TextInput";
import { Title } from "../../Atoms/Title";
import { FromToField, FromToFieldValue, PopperPanel } from "./FromToField/FromToField";
import { PanelContent, PassengerField } from "./PassengerField/PassengerField";
import { TravelDateRangePicker, TravelPanelContent } from "./TravelDateRangePicker/TravelDateRangePicker";

import classes from "./FlightSearch.module.scss";

export interface FlightSearchProps {
  title?: string;
  tabs: {
    one: string;
    round: string;
    multi: string;
  };
  fields: {
    from: string;
    to: string;
    passenger: string;
    outbound: string;
    returning: string;
    promoCode: string;
  };
  promoCodeLink: string;
  submit: string;
  fromPanel: PopperPanel;
  toPanel: PopperPanel;
  adultLabel: string;
  childrenLabel: string;
  infantLabel: string;
  passengerPanel: PanelContent;
  travelPanel: TravelPanelContent;
  onSubmit?: (value: { trips: FromToFieldValue[] } & DateRangeCalendarValue) => void;
  searchHistory?: {
    from?: string;
    to?: string;
    trip?: { from?: string; to?: string };
  }[];
}

const TripTypes = { ONE: "ONE", ROUND: "ROUND", MULTI: "MULTI" };
enum DateFields {
  FROM = "FROM",
  TO = "TO",
}

const FlightTabPanelContent = ({ children }: { children?: React.ReactNode }) => (
  <Box className={classes.flightSearch_formGrid}>{children}</Box>
);

export const FlightSearch = ({
  title,
  tabs,
  fields,
  promoCodeLink,
  submit,
  fromPanel,
  toPanel,
  passengerPanel,
  travelPanel,
  adultLabel,
  childrenLabel,
  infantLabel,
  onSubmit,
  searchHistory,
}: FlightSearchProps) => {
  const tabList = [
    { value: TripTypes.ONE, label: tabs.one },
    { value: TripTypes.ROUND, label: tabs.round },
    { value: TripTypes.MULTI, label: tabs.multi },
  ];

  const [showPromoCodeField, setShowPromoCodeField] = useState(false);
  const [fromTo1, setFromTo1] = useState<FromToFieldValue>();
  const [fromTo2, setFromTo2] = useState<FromToFieldValue>();
  const [schedule, setSchedule] = useState<DateRangeCalendarValue>();

  const handlePromoCodeClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setShowPromoCodeField(true);
  };

  const handleSubmit = () => {
    const trips = [];

    if (fromTo1) {
      trips.push(fromTo1);
    }
    if (fromTo2) {
      trips.push(fromTo2);
    }
    if (onSubmit) {
      onSubmit({
        trips,
        ...schedule,
      });
    }
  };

  const handleSchedulePicked = (value?: DateRangeCalendarValue, field?: DateFields) => {
    if (!field) {
      setSchedule(value);
    } else if (field === DateFields.FROM) {
      setSchedule({ ...schedule, from: value?.from });
    } else if (field === DateFields.TO) {
      setSchedule({ ...schedule, to: value?.to });
    }
  };

  const submitBtn = (
    <Button className={classes.flightSearch_submit} onClick={handleSubmit} full>
      {submit}
    </Button>
  );

  return (
    <Box className={classes.flightSearch}>
      <div className={classes.flightSearch_titleWrapper}>
        <Title className={classes.flightSearch_title}>{title}</Title>
        <div className={classes.flightSearch_searchHistory}>
          {searchHistory &&
            searchHistory?.map((record) => (
              <Link white key={`${record.trip?.from}-${record.trip?.to}-${record?.from}-${record?.to}`}>
                {`${record.trip?.from} - ${record.trip?.to}`} <br />
                {`${record?.from} - ${record?.to}`}
              </Link>
            ))}
        </div>
      </div>
      <Tabs defaultValue={TripTypes.ROUND}>
        <TabsList
          slotProps={{
            root: {
              className: clsx(classes.flightSearch_tripTabs, classes.flightSearch_formGrid),
            },
          }}
        >
          <Box className={classes.flightSearch_tripList}>
            {tabList.map((tab) => (
              <Tab
                value={tab.value}
                slots={{
                  root: Button,
                }}
                slotProps={{
                  root: (ownerState) => ({
                    className: classes.flightSearch_tripSelect,
                    clicked: ownerState.selected,
                    variant: ButtonVariant.Secondary,
                    full: true,
                  }),
                }}
                key={tab.value}
              >
                {tab.label}
              </Tab>
            ))}
          </Box>
          <Box>
            <PassengerField
              className={classes.flightSearch_field}
              passenger={fields.passenger}
              adultLabel={adultLabel}
              childrenLabel={childrenLabel}
              infantLabel={infantLabel}
              panel={passengerPanel}
            />
          </Box>
          <Box>
            {showPromoCodeField ? (
              <TextInput className={classes.flightSearch_field} placeholder={fields.promoCode} />
            ) : (
              <Box className={classes.flightSearch_promoCode}>
                <Link className={classes.flightSearch_promoCodeLink} href='#' onClick={handlePromoCodeClick}>
                  {promoCodeLink}
                </Link>
              </Box>
            )}
          </Box>
        </TabsList>

        <TabPanel value={TripTypes.ONE}>
          <FlightTabPanelContent>
            <FromToField
              value={fromTo1}
              onChange={setFromTo1}
              divider={<AutorenewOutlinedIcon />}
              from={fields.from}
              to={fields.to}
              fromPanel={fromPanel}
              toPanel={toPanel}
            />
            <Box>
              <TravelDateRangePicker
                onChange={handleSchedulePicked}
                value={schedule}
                panel={travelPanel}
                start={fields.outbound}
              />
            </Box>
            <Box>{submitBtn}</Box>
          </FlightTabPanelContent>
        </TabPanel>

        <TabPanel value={TripTypes.ROUND}>
          <FlightTabPanelContent>
            <FromToField
              divider={<AutorenewOutlinedIcon />}
              value={fromTo1}
              onChange={setFromTo1}
              from={fields.from}
              to={fields.to}
              fromPanel={fromPanel}
              toPanel={toPanel}
            />
            <Box>
              <TravelDateRangePicker
                onChange={handleSchedulePicked}
                value={schedule}
                panel={travelPanel}
                divider={"~"}
                start={fields.outbound}
                end={fields.returning}
              />
            </Box>
            <Box>{submitBtn}</Box>
          </FlightTabPanelContent>
        </TabPanel>

        <TabPanel value={TripTypes.MULTI}>
          <FlightTabPanelContent>
            <Box className={classes.flightSearch_formGridInner}>
              <FromToField
                value={fromTo1}
                onChange={setFromTo1}
                divider={"|"}
                from={fields.from}
                to={fields.to}
                fromPanel={fromPanel}
                toPanel={toPanel}
              />
              <FromToField
                value={fromTo2}
                onChange={setFromTo2}
                divider={"|"}
                from={fields.from}
                to={fields.to}
                fromPanel={fromPanel}
                toPanel={toPanel}
              />
            </Box>
            <Box className={classes.flightSearch_formGridInner}>
              <TravelDateRangePicker value={{ from: schedule?.from }} panel={travelPanel} start={fields.outbound} />
              <TravelDateRangePicker value={{ from: schedule?.to }} panel={travelPanel} end={fields.returning} />
            </Box>
            <Box>{submitBtn}</Box>
          </FlightTabPanelContent>
        </TabPanel>
      </Tabs>
    </Box>
  );
};
