import { envUiConfig } from "../env";

if (typeof window !== "undefined") {
  window.localStorage.debug = envUiConfig.debug;
}

// const consoleBrowserLogger = debug("general:browser:console:log");
// const consoleBrowserInfoLogger = debug("general:browser:console:info");
// const consoleBrowserWarnLogger = debug("general:browser:console:warn");
// const consoleBrowserErrorLogger = debug("general:browser:console:error");

// const consoleServerLogger = debug("general:server:console:log");
// const consoleServerInfoLogger = debug("general:server:console:info");
// const consoleServerWarnLogger = debug("general:server:console:warn");
// const consoleServerErrorLogger = debug("general:server:console:error");

// const middlewareLogger = debug("general:middleware:console:log");
// const middlewareLoggerInfoLogger = debug("general:middleware:console:info");
// const middlewareLoggerWarnLogger = debug("general:middleware:console:warn");
// const middlewareLoggerErrorLogger = debug("general:middleware:console:error");

// let consoleLogger = consoleServerLogger;
// let consoleInfoLogger = consoleServerInfoLogger;
// let consoleWarnLogger = consoleServerWarnLogger;
// let consoleErrorLogger = consoleServerErrorLogger;

// export const initLoggers = () => {
//   if (typeof window !== "undefined") {
//     consoleLogger = consoleBrowserLogger;
//     consoleLogger.log = window.console.log.bind(window.console);
//     window.console.log = consoleLogger;
//     consoleInfoLogger = consoleBrowserInfoLogger;
//     consoleInfoLogger.log = window.console.info.bind(window.console);
//     window.console.info = consoleInfoLogger;
//     consoleWarnLogger = consoleBrowserWarnLogger;
//     consoleWarnLogger.log = window.console.warn.bind(window.console);
//     window.console.warn = consoleWarnLogger;
//     consoleErrorLogger = consoleBrowserErrorLogger;
//     consoleErrorLogger.log = window.console.error.bind(window.console);
//     window.console.error = consoleErrorLogger;
//   } else {
//     global.console.log = consoleLogger;
//     global.console.info = consoleInfoLogger;
//     global.console.warn = consoleWarnLogger;
//     global.console.error = consoleErrorLogger;
//   }
//   console.info("Logger initialized with debug:", envUiConfig.debug);

//   return { consoleErrorLogger, consoleInfoLogger, consoleLogger, consoleWarnLogger };
// };

// export const initMiddlewareLoggers = () => {
//   global.console.log = middlewareLogger;
//   global.console.info = middlewareLoggerInfoLogger;
//   global.console.warn = middlewareLoggerWarnLogger;
//   global.console.error = middlewareLoggerErrorLogger;

//   console.info("Middleware Logger initialized with debug:", envUiConfig.debug);

//   return { middlewareLogger, middlewareLoggerInfoLogger, middlewareLoggerWarnLogger, middlewareLoggerErrorLogger };
// };

const consoleBrowserLogger = console.log;
const consoleBrowserInfoLogger = console.info;
const consoleBrowserWarnLogger = console.warn;
const consoleBrowserErrorLogger = console.error;

const consoleServerLogger = console.log;
const consoleServerInfoLogger = console.info;
const consoleServerWarnLogger = console.warn;
const consoleServerErrorLogger = console.error;

const middlewareLogger = console.log;
const middlewareLoggerInfoLogger = console.info;
const middlewareLoggerWarnLogger = console.warn;
const middlewareLoggerErrorLogger = console.error;

let consoleLogger = consoleServerLogger;
let consoleInfoLogger = consoleServerInfoLogger;
let consoleWarnLogger = consoleServerWarnLogger;
let consoleErrorLogger = consoleServerErrorLogger;

export const initLoggers = () => {
  if (typeof window !== "undefined") {
    consoleLogger = consoleBrowserLogger;
    // consoleLogger.log = window.console.log.bind(window.console);
    window.console.log = consoleLogger;
    consoleInfoLogger = consoleBrowserInfoLogger;
    // consoleInfoLogger.log = window.console.info.bind(window.console);
    window.console.info = consoleInfoLogger;
    consoleWarnLogger = consoleBrowserWarnLogger;
    // consoleWarnLogger.log = window.console.warn.bind(window.console);
    window.console.warn = consoleWarnLogger;
    consoleErrorLogger = consoleBrowserErrorLogger;
    // consoleErrorLogger.log = window.console.error.bind(window.console);
    window.console.error = consoleErrorLogger;
  } else {
    global.console.log = consoleLogger;
    global.console.info = consoleInfoLogger;
    global.console.warn = consoleWarnLogger;
    global.console.error = consoleErrorLogger;
  }
  console.info("Logger initialized with debug:", envUiConfig.debug);

  return { consoleErrorLogger, consoleInfoLogger, consoleLogger, consoleWarnLogger };
};

export const initMiddlewareLoggers = () => {
  global.console.log = middlewareLogger;
  global.console.info = middlewareLoggerInfoLogger;
  global.console.warn = middlewareLoggerWarnLogger;
  global.console.error = middlewareLoggerErrorLogger;

  console.info("Middleware Logger initialized with debug:", envUiConfig.debug);

  return { middlewareLogger, middlewareLoggerInfoLogger, middlewareLoggerWarnLogger, middlewareLoggerErrorLogger };
};
