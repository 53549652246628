import { constants } from "@hkexpressairwayslimited/ui/src/styles/constants";
import useMuiMediaQuery from "@mui/material/useMediaQuery";

export const useMediaQuery = (...args: Parameters<typeof useMuiMediaQuery>) => useMuiMediaQuery(...args);

export const useMobileQuery = () => useMediaQuery(constants.mobileQuery);

export const useTabletQuery = () => useMediaQuery(constants.tabletQuery);

export const useDesktopQuery = () => useMediaQuery(constants.desktopQuery);

export const useMediaQueries = () => {
  const isMobile = useMobileQuery();
  const isTablet = useTabletQuery();
  const isDesktop = useDesktopQuery();

  return { isMobile, isTablet, isDesktop };
};
