import parse, { DOMNode, Element, HTMLReactParserOptions, attributesToProps, domToReact } from "html-react-parser";
import { ComponentProps, useCallback, useMemo } from "react";
import sanitizeHtml from "sanitize-html";
import { Image, Link } from "../components";

// Type for translating content with HTML tags
export type I18nStr<TransHook extends Record<string, any> = Record<string, any>> = ReturnType<
  ReturnType<typeof useTransContent<TransHook>>["t"]
>;

export const parseReactOptions: HTMLReactParserOptions = {
  replace(domNode) {
    if (domNode instanceof Element) {
      // Look for an img tag and replace it with Image.
      if (domNode.name === "img") {
        const imgProps = attributesToProps(domNode.attribs) as ComponentProps<"img">;

        return <Image {...imgProps} />;
      }
      if (domNode.name === "a") {
        const anchorProps = attributesToProps(domNode.attribs) as ComponentProps<"a">;
        const children = domToReact(domNode.children as DOMNode[], parseReactOptions);

        return <Link {...anchorProps}>{children}</Link>;
      }
    }
  },
};

// Parse HTML string to React component
export const parseHtmlToReact = (html: string) => parse(html, parseReactOptions);

// Custom hook for translating content with HTML tags
// This hook will return a function that will parse the content and return React component nodes
// for HTML tags and plain text for other content
// This hook will also sanitize the HTML content to prevent XSS attacks
// This hook will also memoize the parsed content to prevent re-rendering
export function useTransContent<TransHook extends Record<string, any>>(translationHook: TransHook) {
  const t = useCallback(
    (...args: Parameters<TransHook["t"]>): ReturnType<typeof parse> => {
      const content = translationHook.t(...args);

      if (typeof content === "string") {
        const html = sanitizeHtml(content, {
          allowedAttributes: false, // Allow all attributes
          allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"]),
        });

        return parseHtmlToReact(html);
      } else {
        return content;
      }
    },
    [translationHook.t]
  );

  const transContent: Omit<TransHook, "t"> & { t: typeof t; tPlain: TransHook["t"] } = useMemo(
    () => ({
      ...translationHook,
      tPlain: translationHook.t,
      t,
    }),
    [translationHook]
  );

  return transContent;
}

// For interpolating string with values for React component nodes
export const strInterpolator = <T extends Record<string, any>>(str: string, values: T) => {
  return str.replace(/{{(.*?)}}/g, (match, p1) => {
    if (p1.includes(".")) {
      const keyPath = p1.split(".");
      let currentObj = values;
      for (let i = 0; i < keyPath.length; i++) {
        const key = keyPath[i];
        if (currentObj[key] !== void 0 && typeof currentObj[key] === "object") {
          currentObj = currentObj[key];
        } else {
          return currentObj[key] !== void 0 ? currentObj[key] : "";
        }
      }
    } else {
      return values[p1] !== void 0 ? values[p1] : "";
    }
  });
};
