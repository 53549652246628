import { clsx } from "clsx";
import { forwardRef } from "react";

import {
  StatusWarningIcon,
  SvgIcon,
  SvgIconSize,
  Text,
  TextProps,
  TextSize,
  colors,
} from "@hkexpressairwayslimited/ui/src";

import classes from "./ErrorLabel.module.scss";

export const ErrorLabel = forwardRef<HTMLParagraphElement | HTMLSpanElement, TextProps>(
  ({ className, children, ...others }, ref) => (
    <Text
      className={clsx(className, classes.errorLabel)}
      color={colors.additionalCriticalDefault}
      size={TextSize.P3Regular}
      {...others}
      ref={ref}
    >
      <SvgIcon size={SvgIconSize.Mobile}>
        <StatusWarningIcon />
      </SvgIcon>
      <Text span className={classes.errorLabel_value}>
        {children}
      </Text>
    </Text>
  )
);
ErrorLabel.displayName = "ErrorLabel";
