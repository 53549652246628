import { Header, HeaderPropsType, ListView, MainContainer } from "@hkexpressairwayslimited/ui/src";
import { Box } from "@mui/material";
import React from "react";

export interface ListingTemplateProps {
  headerData: HeaderPropsType;
  // footerData: FooterPropsType;
  children: React.ReactNode[];
}

const ListingTemplate = (props: ListingTemplateProps) => {
  const { headerData, children } = props;

  return (
    <Box>
      <Header {...headerData} />
      <MainContainer>
        <ListView>{children}</ListView>
      </MainContainer>
      {/* <Footer {...footerData} /> */}
    </Box>
  );
};

export { ListingTemplate };
