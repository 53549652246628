import { ESpacing, useMediaQueries } from "@hkexpressairwayslimited/ui/src";
import { Theme, styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFnsV3";
import {
  DateRangeCalendarProps,
  DateRangeCalendar as MuiDateRangeCalendar,
} from "@mui/x-date-pickers-pro/DateRangeCalendar";
import { addMonths } from "date-fns";
import { useEffect, useMemo, useRef } from "react";
const today = new Date();

const StyledDateRangeCalendar = styled(MuiDateRangeCalendar)(({
  theme,
  ismobile,
}: { theme: Theme } & { ismobile: string }) => {
  return {
    "&.MuiDateRangeCalendar-root": {
      flexDirection: ismobile === "true" ? "column" : "row",
      // display: ismobile === 'true' ?'inline-flex':'flex',
    },
    "& .MuiPickersArrowSwitcher-root": {
      justifyContent: ismobile === "true" ? "center" : "space-between",
    },
    "& .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition": {
      // minHeight:isMobile ?'250px':'264px',
    },
    "& .MuiPickersArrowSwitcher-button": {
      display: "block",
    },
    "& .MuiDateRangeCalendar-monthContainer:not(:last-of-type)": {
      borderRight: "none",
      marginRight: ESpacing._2xs,
    },
    "& .MuiDayCalendar-header": {
      borderBottom: `1px solid ${theme.palette.neutral.borderNeutral}`,
    },
    "& button.MuiPickersDay-today": {
      border: "none",
      fontWeight: "bold",
    },
    "& button.MuiPickersDay-today::before,button.MuiDateRangePickerDay-dayInsideRangeInterval.MuiPickersDay-today:hover::before":
      {
        content: '""',
        display: "inline-block",
        height: "2px",
        backgroundColor: theme.palette.neutral.black,
        minWidth: "12px",
        bottom: "10px",
        left: "13px",
        position: "fixed",
      },
    "& button.MuiPickersDay-today:hover::before": {
      content: '""',
      display: "inline-block",
      height: "2px",
      backgroundColor: theme.palette.neutral.black,
      bottom: "9px",
      left: "12px",
    },
    "& .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition:not(.MuiDateRangeCalendar-dayDragging) .MuiDateRangePickerDay-dayOutsideRangeInterval:hover":
      {
        borderColor: theme.palette.purple.default,
      },
  };
});

export function DateRangePicker({
  calendars,
  currentMonthCalendarPosition,
  minDate = addMonths(today, -1),
  maxDate,
  ...rest
}: DateRangeCalendarProps<Date>) {
  const { isMobile } = useMediaQueries();
  const { calenderRenderNum, currentMonthCalendarRenderPosition } = useMemo(() => {
    return {
      calenderRenderNum: calendars ?? 2,
      currentMonthCalendarRenderPosition: currentMonthCalendarPosition ? currentMonthCalendarPosition : 1,
    };
  }, [isMobile]);
  const ref = useRef(null);
  useEffect(() => {
    const target = ref.current;

    let observer: any = { disconnect: () => {} };
    if (target) {
      if (isMobile) {
        (target as HTMLDivElement)
          .querySelectorAll<HTMLDivElement>(".MuiDateRangeCalendar-monthContainer")
          .forEach((e) => {
            if ((e as HTMLDivElement).querySelector("button.MuiPickersDay-today")) {
              window.scrollTo({ top: (e as HTMLDivElement).offsetTop });
            }
          });
      }
      observer = new MutationObserver((mutationList) => {
        (target as HTMLDivElement)
          .querySelectorAll<HTMLDivElement>(".MuiDateRangeCalendar-monthContainer")
          .forEach((e) => {
            if ((e as HTMLDivElement).querySelector("button.MuiPickersDay-today")) {
              window.scrollTo({ top: (e as HTMLDivElement).offsetTop });
            }
          });
      });
      // Start observing the target node for configured mutations
      observer.observe(target, { childList: true });
    }
    return () => {
      // Later, you can stop observing
      observer.disconnect();
    };
  }, []);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledDateRangeCalendar
        ref={ref}
        calendars={calenderRenderNum as 1}
        currentMonthCalendarPosition={currentMonthCalendarRenderPosition as 1}
        minDate={minDate}
        maxDate={maxDate}
        ismobile={isMobile.toString()}
        {...(rest as any)}
      />
    </LocalizationProvider>
  );
}
