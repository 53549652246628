import { CardMediaProps, CardMedia as MuiCardMedia } from "@mui/material";
import { forwardRef } from "react";
import { Image } from "../../Atoms/Image";

export const CardMedia = forwardRef<HTMLElement, CardMediaProps>(({ children, src, sx, ...rest }, ref) => (
  <MuiCardMedia {...rest}>
    {src && <Image src={src} alt='' sx={sx} />}
    {children}
  </MuiCardMedia>
));
CardMedia.displayName = "CardMedia";
