import clsx from "clsx";
import localFont from "next/font/local";

export const plutoSans = localFont({
  src: "./PlutoSans-Regular.otf",
  display: "swap",
  variable: "--font-pluto-sans",
});

export const plutoSansBold = localFont({
  src: "./PlutoSans-Bold.otf",
  display: "swap",
  variable: "--font-pluto-sans-bold",
});

export const plutoSansMedium = localFont({
  src: "./PlutoSans-Medium.otf",
  display: "swap",
  variable: "--font-pluto-sans-medium",
});

export const plutoSansLight = localFont({
  src: "./PlutoSans-Light.otf",
  display: "swap",
  variable: "--font-pluto-sans-light",
});

export const fonts = clsx(
  plutoSans.variable,
  plutoSansBold.variable,
  plutoSansMedium.variable,
  plutoSansLight.variable
);
