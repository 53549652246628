import * as React from "react";
import { RadioGroup } from "../../Atoms/RadioGroup/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Radio } from "../../Atoms/Radio/Radio";
import { FormField } from "../../Atoms/FormField/FormField";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";

export interface RadioGroupFieldProps {
  id: string;
  label: string;
  itemList: { [key: string]: string };
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<Element>) => void;
  isError?: boolean;
  helperText?: string;
}
const RadioGroupField = (props: RadioGroupFieldProps) => (
  <FormField>
    <FormLabel id={props.id} error={props.isError}>
      {props.label}
    </FormLabel>
    <RadioGroup aria-labelledby={props.id} defaultValue={props.defaultValue} name='radio-buttons-group'>
      {props.itemList &&
        Object.entries(props.itemList).map(([key, value], index) => {
          return (
            <FormControlLabel
              key={key}
              value={key}
              control={
                <Radio
                  onChange={(e: React.ChangeEvent<Element>) => {
                    props.onChange && props.onChange(e);
                  }}
                />
              }
              label={value}
            />
          );
        })}
    </RadioGroup>
    {props.helperText && (
      <FormHelperText error={props.isError} variant='standard'>
        {props.helperText}
      </FormHelperText>
    )}
  </FormField>
);

export { RadioGroupField };
