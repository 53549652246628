import Link from "next/link";
import { HomeOutlined } from "@mui/icons-material";
import classes from "./RouteMapBar.module.scss";
import React from "react";

export type RouteMapBarProps = {
  routePath: {
    path: string[];
    page: string;
  };
  hyperIndex?: number;
  link?: string;
};

const RouteMapBar = (props: RouteMapBarProps) => {
  const routePath = props.routePath;

  return (
    <div className={classes.routeMapBar}>
      {routePath?.path.map((path: string, index: number) => {
        if (index === 0) {
          return (
            <Link href='/' key={index}>
              <HomeOutlined sx={{ color: "#702B91" }} />
            </Link>
          );
        } else if (index === props.hyperIndex) {
          return (
            <div key={index} className={classes.routeSegment}>
              <span className={classes.routeSegment_path}>/</span>
              <Link className={classes.routeSegment_hyperLink} href={props.link ? props.link : "/"}>
                <span>{path}</span>
              </Link>
            </div>
          );
        } else {
          return (
            <div key={index} className={classes.routeSegment}>
              <span className={classes.routeSegment_path}>/</span>
              <span>{path}</span>
            </div>
          );
        }
      })}
    </div>
  );
};

export { RouteMapBar };
