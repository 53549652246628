import { Box, ESpacing, PaymentMenu, PaymentTextField, useFormContext } from "@hkexpressairwayslimited/ui/src";
import { Grid, Stack, Typography } from "@mui/material";
import { PaymentDataProps } from "../paymentTypes";

const PaymentFormRequiredInformation = ({ data }: PaymentDataProps) => {
  const { control } = useFormContext();
  return (
    <>
      <Stack direction='row' spacing={2} paddingBottom={4} borderBottom={1} paddingTop={4}>
        <Grid container>
          <Grid item xs={3}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography variant='subtitle1' color={"#702B91"}>
                Required Information
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>Select customer prefered language for their payment.</Typography>
              </Grid>
              <Grid item xs={12}>
                <PaymentMenu name='language' data={data.language} id='language' label='Language' />
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>Select a timeout for the payment link.</Typography>
              </Grid>
              <Grid item xs={12}>
                <PaymentMenu name='expiry_time' data={data.timeOut} id='expiry_time' label='Expired After' />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
      <Grid container>
        <Grid item xs={3}>
          <Box sx={{ display: "flex", justifyContent: "center" }} />
        </Grid>
        <Grid item xs={9}>
          <Box marginBlock={ESpacing._sm}>
            <PaymentTextField id='note' label='Note' variant='filled' name={"note"} customControl={control} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export { PaymentFormRequiredInformation };
