/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import Grid from "@mui/material/Grid";
import { Checkbox } from "../../Atoms/Checkbox/Checkbox";
import { FormField } from "../../Atoms/FormField/FormField";

export interface CheckboxItemProps {
  key: string;
  label: string | React.ReactNode;
  defaultChecked: boolean;
}

export interface CheckboxSingleFieldProps {
  id: string;
  item: CheckboxItemProps;
  onChange?: Function;
  isError?: boolean;
  errorText?: string;
  required?: boolean;
}
const CheckboxSingleField = (props: CheckboxSingleFieldProps) => (
  <FormField>
    <Grid direction={"column"}>
      <FormControlLabel
        key={props.item.key}
        control={
          <Checkbox
            value={props.item.key}
            defaultChecked={props.item.defaultChecked}
            onChange={(e: any) => props.onChange && props.onChange(e)}
            required={props.required}
          />
        }
        // label={props.item.label}
        label={""}
        style={{ marginRight: 0 }}
      />
      {props.item.label && <FormLabel focused={false}>{props.item.label}</FormLabel>}
    </Grid>

    {props.errorText && (
      <FormHelperText error={props.isError} variant='standard'>
        {props.errorText}
      </FormHelperText>
    )}
  </FormField>
);

export { CheckboxSingleField };
