import * as React from "react";
import { styled } from "@mui/material/styles";
import {
  DataGrid as MuiDataGrid,
  GridColDef,
  GridCellEditCommitParams,
  GridSelectionModel,
  GridCallbackDetails,
  MuiEvent,
  MuiBaseEvent,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { Box } from "@mui/material";

interface Props {}
const StlyedDataGrid = styled(MuiDataGrid)<Props>(({ theme }) => ({}));

interface DataGridProps {
  columns: GridColDef[];
  rows: any[];
  checkboxSelection: boolean;
  processRowUpdate: (
    newRow: GridValidRowModel,
    oldRow: GridValidRowModel
  ) => Promise<GridValidRowModel> | GridValidRowModel;
  onSelectionModelChange: (selectionModel: GridSelectionModel, details: GridCallbackDetails) => void;
}

export const DataGrid = (props: DataGridProps) => {
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <StlyedDataGrid
        rows={props.rows}
        columns={props.columns}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        checkboxSelection={props.checkboxSelection}
        disableSelectionOnClick={true}
        experimentalFeatures={{ newEditingApi: true }}
        processRowUpdate={props.processRowUpdate}
        onSelectionModelChange={props.onSelectionModelChange}
      />
    </Box>
  );
};
