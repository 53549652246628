import * as React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "../../Atoms/Accordion/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import clsx from "clsx";
import { Typography } from "../../../styles";

export interface AccordionProps {
  title: string | JSX.Element;
  children?: React.ReactNode;
}
const IesfeAccordion = (props: AccordionProps) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
      <span className={clsx(Typography.SUBTITLE1)}>{props.title}</span>
    </AccordionSummary>
    <AccordionDetails>{props.children}</AccordionDetails>
  </Accordion>
);

export { IesfeAccordion as Accordion };
