import { useEffect, useMemo, useState } from "react";

import {
  Button,
  ButtonVariant,
  CloseIcon,
  Drawer,
  ErrorLabel,
  FieldInputGroup,
  SelectedItem,
  SvgIcon,
  SvgIconSize,
  Text,
  TextInput,
  TextSize,
  Title,
  TitleOrders,
  VoucherIcon,
  colors,
} from "@hkexpressairwayslimited/ui/src";

import classes from "./VoucherDrawer.module.scss";

export type VoucherDrawerProps = {
  open: boolean;
  onClose: () => void;
  onAddVoucher?: (voucherCode: string) => Promise<VoucherItem>;
  onRemoveVoucher?: (voucherCode: string[]) => Promise<VoucherItem[]>;
  onApplyVouchers?: (vouchers: VoucherItem[]) => void;
  price: number;
  priceFormatter?: (price: number) => string | JSX.Element;
  title?: string;
  desc?: string;
  voucherCodeHelper?: string;
  currency?: string;
  addBtnLabel?: string;
  selectedItemContent?: {
    removeBtnLabel?: string;
    amountAvailableLabel?: string;
    amountRemainingLabel?: string;
  };
  amountToDeduceLabel?: string;
  applyBtnLabel?: string;
  appliedVouchers?: VoucherItem[];
  errorLabelDuplicateVoucherCode?: string;
};

export type VoucherItem = {
  voucherCode: string;
  amount: number;
  availableAmount: number;
  remainingAmount: number;
};

export const VoucherDrawer = ({
  open,
  onClose,
  onAddVoucher,
  onRemoveVoucher,
  onApplyVouchers,
  price,
  title,
  voucherCodeHelper,
  addBtnLabel,
  amountToDeduceLabel,
  applyBtnLabel,
  appliedVouchers,
  errorLabelDuplicateVoucherCode,
  priceFormatter = (price: number) => price.toString(),
  ...props
}: VoucherDrawerProps) => {
  const [voucherCode, setVoucherCode] = useState("");
  const [voucherCodes, setVoucherCodes] = useState(appliedVouchers || []);
  // const remainingAmounts = useMemo(() => {
  //   let subtotal = price;

  //   return voucherCodes.map((voucher) => {
  //     const diff = voucher.amount - subtotal;

  //     subtotal -= voucher.amount;

  //     return Math.max(0, diff);
  //   });
  // }, [voucherCodes]);
  const total = useMemo(
    () =>
      Math.min(
        price,
        voucherCodes.reduce((sum, next) => sum + next.amount, 0)
      ),
    [voucherCodes]
  );

  const [addVoucherDisabled, setAddVoucherDisabled] = useState(true);
  useEffect(() => {
    if (voucherCodes?.length >= 5) {
      setAddVoucherDisabled(true);
    } else {
      setAddVoucherDisabled(false);
    }
  }, [voucherCodes]);

  useEffect(() => {
    voucherCode ? setAddVoucherDisabled(false) : setAddVoucherDisabled(true);
  }, [voucherCode]);

  const [foundCode, setFoundCode] = useState(false);

  const handleAddVoucher = async (voucherCode?: string) => {
    if (!voucherCode) return;
    const foundCode = voucherCodes.some((voucher) => voucher.voucherCode === voucherCode);
    setFoundCode(foundCode);
    if (foundCode) return;

    const voucherInfo: VoucherItem | undefined = await onAddVoucher?.(voucherCode);
    if (voucherInfo) {
      setVoucherCode("");
      setVoucherCodes([...voucherCodes, voucherInfo]);
    }
  };

  const handleApplyVouchers = () => {
    onApplyVouchers?.(voucherCodes);
  };

  const handleRemoveVoucher = async (voucherCode: string) => {
    const voucherInfo: VoucherItem[] | undefined = await onRemoveVoucher?.(
      voucherCodes.filter((e) => e.voucherCode !== voucherCode).map((e) => e.voucherCode)
    );

    if (voucherInfo) {
      setVoucherCodes(voucherInfo);
    }
  };

  useEffect(() => {
    setVoucherCodes(appliedVouchers || []);
  }, [appliedVouchers]);

  return (
    <div className={classes.voucherDrawerContainer}>
      <Drawer
        open={open}
        ModalProps={{ disablePortal: true }}
        onClose={() => {
          onClose();
          setVoucherCode("");
          setVoucherCodes(appliedVouchers || []);
          setFoundCode(false);
        }}
        {...props}
      >
        <div className={classes.voucherDrawer}>
          <div className={classes.voucherDrawer_header}>
            <Title order={TitleOrders.H2Headline} className={classes.voucherDrawer_title}>
              <SvgIcon color={colors.actionPurpleDefault} size={SvgIconSize.DenseLayout}>
                <VoucherIcon />
              </SvgIcon>
              <Text span color={colors.neutralBlack}>
                {title}
              </Text>
            </Title>
            <Button
              color={colors.neutralBlack}
              variant={ButtonVariant.Icon}
              noBorder
              onClick={() => {
                onClose();
                setVoucherCode("");
                setVoucherCodes(appliedVouchers || []);
                setFoundCode(false);
              }}
            >
              <CloseIcon />
            </Button>
          </div>

          <div className={classes.voucherDrawer_content}>
            <div className={classes.voucherDrawer_voucherCodeSection}>
              <Text>{props.desc}</Text>
              <div className={classes.voucherDrawer_voucherCodeInput}>
                <FieldInputGroup fullWidth helperText={voucherCodeHelper}>
                  <TextInput fullWidth value={voucherCode} onChange={(e) => setVoucherCode(e.target.value)} />
                </FieldInputGroup>

                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={() => {
                    handleAddVoucher(voucherCode);
                  }}
                  disabled={addVoucherDisabled}
                >
                  {addBtnLabel}
                </Button>
              </div>
              <ErrorLabel style={{ display: foundCode ? "block" : "none" }}>
                {errorLabelDuplicateVoucherCode}
              </ErrorLabel>
              <hr />
            </div>

            {voucherCodes.map((voucher, index) => (
              <SelectedItem
                key={voucher.voucherCode}
                name={voucher.voucherCode}
                removeBtnLabel={props.selectedItemContent?.removeBtnLabel}
                onRemove={() => handleRemoveVoucher(voucher.voucherCode)}
              >
                <SelectedItem.ContentTable>
                  <SelectedItem.TableRow>
                    <Text span>{props.selectedItemContent?.amountAvailableLabel}</Text>
                    <Text bold color={colors.actionPurpleDefault} span>
                      {priceFormatter(voucher.availableAmount)}
                    </Text>
                  </SelectedItem.TableRow>
                  <SelectedItem.TableRow>
                    <Text span>{props.selectedItemContent?.amountRemainingLabel}</Text>
                    <Text bold color={colors.actionPurpleDefault} span>
                      {priceFormatter(voucher.remainingAmount)}
                    </Text>
                  </SelectedItem.TableRow>
                </SelectedItem.ContentTable>
              </SelectedItem>
            ))}
          </div>

          <div className={classes.voucherDrawer_footer}>
            <div className={classes.voucherDrawer_amountToDeduceWrapper}>
              <div className={classes.voucherDrawer_amountToDeduce}>
                <Text size={TextSize.ButtonRegular}>{amountToDeduceLabel}</Text>
                <div className={classes.voucherDrawer_amountToDeduceValue}>{priceFormatter(total)}</div>
              </div>
              <Button variant={ButtonVariant.Secondary} onClick={handleApplyVouchers}>
                {applyBtnLabel}
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
