import { TextField, TextFieldVariants, styled } from "@mui/material";
import { Controller } from "react-hook-form";
import { useFormContext, Control } from "react-hook-form";
import React from "react";

export interface MuiTextFieldProps {}

const StyledTextField = styled(TextField)<MuiTextFieldProps>`
  & .MuiFilledInput-root {
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 0;
  }
  & .MuiFilledInput-input {
    padding: 25px 32px 8px 12px;
  }
  & .Mui-disabled {
    background-color: lightgrey;
  }
  & .MuiFilledInput-underline {
    border-bottom-color: white;
  }
`;

export type PaymentTextFieldProps = {
  name: string;
  id: string;
  label: string;
  variant: TextFieldVariants;
  disabled?: boolean;
  maxLength?: number;
  uppercase?: boolean;
  type?: string;
  customControl?: Control;
};
const PaymentTextField = (props: PaymentTextFieldProps) => {
  return (
    <Controller
      name={props.name}
      control={props.customControl}
      render={({ field, fieldState: { error } }) => (
        <StyledTextField
          {...field}
          error={!!error}
          helperText={error ? (error?.message as unknown as string) : ""}
          type={props.type}
          fullWidth
          id={props.id}
          label={props.label}
          variant={props.variant}
          name={props.name}
          disabled={props.disabled}
          inputProps={{ maxLength: props.maxLength, style: { textTransform: props.uppercase ? "uppercase" : "none" } }}
        />
      )}
    />
  );
};

export { PaymentTextField };
