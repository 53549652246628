import { ReactNode, MouseEvent, useState, useEffect } from "react";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { clsx } from "clsx";
import dayjs from "dayjs";

import {
  Popper,
  TextInput,
  Box,
  TitleOrders,
  Title,
  DateRangeCalendar,
  DateRangeCalendarValue,
  PopperHandle,
  DateCalendar,
} from "../../..";
import { Dayjs } from "dayjs";

import classes from "./TravelDateRangePicker.module.scss";

export type TravelPanelContent = {
  title: string;
};

export type TravelDateRangePickerProps = {
  className?: string;
  divider?: ReactNode;
  start?: string;
  end?: string;
  panel: TravelPanelContent;
  value?: DateRangeCalendarValue;
  onChange?: (value?: DateRangeCalendarValue) => void;
};

export const TravelDateRangePicker = ({
  className,
  value,
  onChange,
  start,
  end,
  panel,
}: TravelDateRangePickerProps) => {
  const [popperHandle, setPopperHandle] = useState<PopperHandle | null>(null);
  const [rangeValue, setRangeValue] = useState<DateRangeCalendarValue>();
  const useEnd = !start;
  const startPlaceholder = useEnd ? end : start;
  const isDateRange = start && end;

  const handleFieldClick = (event: MouseEvent<HTMLDivElement>) => {
    popperHandle?.open(event.currentTarget);
  };

  const handleClosePanel = () => {
    popperHandle?.close();
  };

  const handleRangePicked = (value?: DateRangeCalendarValue) => {
    setRangeValue(value);
    if (onChange) {
      onChange(value);
    }
    if (value?.from && value?.to) {
      handleClosePanel();
    }
  };

  useEffect(() => {
    handleRangePicked(value);
  }, [value]);

  return (
    <Box className={clsx(classes.travelDateRangePicker, className)}>
      <div className={classes.travelDateRangePicker_inner} onClick={handleFieldClick}>
        {startPlaceholder && (
          <Box className={classes.travelDateRangePicker_subField}>
            <TextInput
              className={classes.travelDateRangePicker_input}
              icon={<CalendarMonthOutlinedIcon />}
              placeholder={startPlaceholder}
              center
              readOnly
              value={rangeValue?.from?.format("DD/MM/YYYY") || ""}
            />
          </Box>
        )}

        {isDateRange ? <Box className={classes.travelDateRangePicker_divider}>~</Box> : null}

        {!useEnd && end && (
          <Box className={classes.travelDateRangePicker_subField}>
            <TextInput
              className={classes.travelDateRangePicker_input}
              placeholder={end}
              center
              readOnly
              value={rangeValue?.to?.format("DD/MM/YYYY") || ""}
            />
          </Box>
        )}
      </div>

      <Popper arrow ref={setPopperHandle}>
        <Box>
          <Title className={classes.travelDateRangePicker_panelTitle} order={TitleOrders.HeadingSmall} center>
            {panel.title}
          </Title>
          {isDateRange ? (
            <DateRangeCalendar value={rangeValue} onChange={handleRangePicked} />
          ) : (
            <DateCalendar value={rangeValue?.from} onChange={(value) => handleRangePicked({ from: value })} />
          )}
        </Box>
      </Popper>
    </Box>
  );
};
