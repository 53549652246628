const b1 = "1px solid #D6D6D8";
const b2 = "1px solid #702B91";
const b3 = `1px solid #59107D`;
const secondary = "1px solid #0DA9CB";
const disabled = "1px solid #AEB2BB";
const boldb2 = "2px solid #702B91";
const error = "1px solid #ED145B";

export const EBorders = {
  b1,
  b2,
  b3,
  secondary,
  disabled,
  boldb2,
  error,
};
