import { DestinationCard } from "../../Molecules/DestinationCard/DestinationCard";
import { DestinationDetailed } from "../../Molecules/DestinationCard/destinationtypes";
import classes from "./EqualHeightGridCard.module.scss";

export type DestinationsProps = {
  destinations: DestinationDetailed[];
  lang?: string;
};

const EqualHeightGridCard = ({ destinations, lang }: DestinationsProps) => {
  // console.log("Equal Height", lang);
  return (
    <div className={classes.equalHeightGrid}>
      {destinations.map((destination: any, index: number) => {
        return (
          <div key={index}>
            <DestinationCard destination={destination} key={index} lang={lang} />
          </div>
        );
      })}
    </div>
  );
};

export { EqualHeightGridCard };
