import { ReactNode, useMemo } from "react";
import { getInnerText } from "../utils/component";

export const useInnerText = (node: ReactNode, config = { enable: true }) => {
  const innerText = useMemo(() => {
    if (config.enable) {
      return getInnerText(node);
    }
  }, [node, config]);

  return innerText;
};
