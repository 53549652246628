import { constants } from "@hkexpressairwayslimited/ui/src";
import { SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import { Tabs as AtomsTabs, Tab, TabPanel } from "../../Atoms/Tabs/Tabs";

// interface tripList {
//   arItems: {};
//   date: string;
//   destination: string;
//   fares: [];
//   flight_time: string;
//   journey_key: string;
//   origin: string;
//   segments: [];
//   service_charge: [];
// }

export interface Tab<D = {}> {
  label: string;
  children: React.ReactNode;
  data?: D;
  disabled?: boolean;
  // tripDetail?: tripList;
}

export interface TabsProps {
  id: string;
  tabs: Tab[];
  noPadding?: boolean;
  responsive?: boolean;
  onChange?: (value: number) => void;
  scrollable?: boolean;
  sx?: SxProps<Theme> | undefined;
}

function a11yProps(id: string, index: number) {
  return {
    id: `${id}-${index}`,
    "aria-controls": `${id}-${index}`,
  };
}

export const Tabs = (props: TabsProps) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    props.onChange && props.onChange(newValue);
  };

  const noPadding = props.noPadding || false;
  const responsive = props.responsive || false;
  return (
    <Box sx={{ width: "100%", ...props.sx }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <AtomsTabs
          sx={{
            ".MuiButtonBase-root.MuiTab-root": {
              textTransform: "initial",
              fontWeight: "bold",
              fontSize: "18px",
              fontFamily: constants.varFontPlutoSans,
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          variant={props.scrollable ? "scrollable" : "standard"}
          scrollButtons={false}
        >
          {props.tabs &&
            props.tabs.map((tab, index) => {
              return (
                <Tab
                  disabled={tab.disabled}
                  key={tab.label}
                  style={responsive ? { flex: "1", maxWidth: "unset" } : undefined}
                  label={tab.label}
                  {...a11yProps(props.id, index)}
                />
              );
            })}
        </AtomsTabs>
      </Box>
      {props.tabs &&
        props.tabs.map((tab, index) => {
          return (
            <TabPanel key={tab.label} id={props.id} value={value} index={index} noPadding={noPadding}>
              {tab.children}
            </TabPanel>
          );
        })}
    </Box>
  );
};
