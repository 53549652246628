import { Box, InfoIcon, Popper, PopperHandle, PurpleInfoIcon, SvgIcon } from "@hkexpressairwayslimited/ui/src";
import { PopperPlacementType } from "@mui/material/Popper";
import { clsx } from "clsx";
import { MouseEvent, ReactNode, forwardRef, useState } from "react";

import classes from "./Tooltip.module.scss";

export type TooltipProps = {
  className?: string;
  children: ReactNode;
  arrow?: boolean;
  variant?: "default" | "purple";
  placement?: PopperPlacementType;
};

export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  ({ className, children, arrow, variant, placement }, ref) => {
    const [popperHandle, setPopperHandle] = useState<PopperHandle | null>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const onAnchorEnter = (event: MouseEvent<HTMLElement>) => {
      popperHandle?.open(event.currentTarget);
      setIsOpen(true);
    };

    const onAnchorClose = () => {
      popperHandle?.close();
      setIsOpen(false);
    };

    const onAnchorClick = (event: MouseEvent<HTMLElement>) => {
      if (isOpen) {
        popperHandle?.close();
      } else {
        popperHandle?.open(event.currentTarget);
      }
      setIsOpen(!isOpen);
    };

    return (
      <Box className={clsx(classes.tooltip, className)} ref={ref}>
        <Box
          className={classes.tooltip_icon}
          onClick={onAnchorClick}
          onMouseEnter={onAnchorEnter}
          onMouseLeave={onAnchorClose}
        >
          <SvgIcon>{variant === "purple" ? <PurpleInfoIcon /> : <InfoIcon />}</SvgIcon>
        </Box>

        <Popper ref={setPopperHandle} placement={placement || "top"} arrow={arrow} tooltip>
          {!arrow ? <Box className={classes.tooltip_content}>{children}</Box> : <>{children}</>}
        </Popper>
      </Box>
    );
  }
);
Tooltip.displayName = "Tooltip";
