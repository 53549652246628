import { ForwardedRef, forwardRef, ReactNode } from "react";
import { clsx } from "clsx";
import { Box, CloseIcon, EPixel } from "@hkexpressairwayslimited/ui/src";
import { Stack } from "@mui/material";
import classes from "./TriangleDropdownListBox.module.scss";

export type TriangleDropdownListBoxProps = {
  className?: string;
  titleLabel?: string;
  children: ReactNode;
  closeListBox: () => void;
};

export const TriangleDropdownListBox = forwardRef(
  (props: TriangleDropdownListBoxProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { titleLabel = "", className, children, closeListBox } = props;
    return (
      <Box className={clsx(classes.triangleDropdownListBox, className)}>
        <Stack
          className={classes.triangleDropdownListBoxTitle}
          direction='row'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box sx={{ fontSize: EPixel.px20, fontWeight: "bold" }}>{titleLabel}</Box>
          <CloseIcon className={classes.triangleDropdownListBoxTitleCloseIcon} onClick={closeListBox} />
        </Stack>
        <Box>{children}</Box>
      </Box>
    );
  }
);
TriangleDropdownListBox.displayName = "TriangleDropdownListBox";
