import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import * as React from "react";
import { Checkbox } from "../../Atoms/Checkbox/Checkbox";
import { FormField } from "../../Atoms/FormField/FormField";

export interface CheckboxItemProps {
  key: string;
  label: React.ReactNode;
  defaultChecked: boolean;
}

export interface CheckboxFieldProps {
  id: string;
  label: React.ReactNode;
  itemList: CheckboxItemProps[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isError?: boolean;
  helperText?: string;
}
const CheckboxField = (props: CheckboxFieldProps) => (
  <FormField>
    <FormLabel id={props.id} error={props.isError}>
      {props.label}
    </FormLabel>

    {props.itemList &&
      props.itemList.map((item: CheckboxItemProps, index) => {
        return (
          <FormControlLabel
            key={item.key}
            control={
              <Checkbox
                value={item.key}
                defaultChecked={item.defaultChecked}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange && props.onChange(e)}
              />
            }
            label={item.label}
          />
        );
      })}

    {props.helperText && (
      <FormHelperText error={props.isError} variant='standard'>
        {props.helperText}
      </FormHelperText>
    )}
  </FormField>
);

export { CheckboxField };
