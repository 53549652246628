import { forwardRef, ReactElement, SVGProps } from "react";
import { clsx } from "clsx";
import { SvgIcon as MuiSvgIcon, SvgIconProps as MuiSvgIconProps } from "@mui/material";
import { Text, TextColor } from "@hkexpressairwayslimited/ui/src";

import classes from "./SvgIcon.module.scss";

// New Design: https://www.figma.com/file/m0ZGcb6bl29nGbJWJYXvkl/01---HKE---Design-system?type=design&node-id=2-740&mode=design&t=4xisL6ZG1sn04FY1-0
export enum SvgIconSize {
  Mobile = "Mobile", // 20px
  Desktop = "Desktop", // 24px
  DenseLayout = "DenseLayout", // 32px
  Display = "Display", // 40px;
  Headline = "Headline", // 64px
}

export type SvgIconProps = {
  className?: string;
  size?: SvgIconSize;
  color?: TextColor;
} & Omit<MuiSvgIconProps, "color">;

export const SvgIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  ({ className, color, children, size, ...others }, ref) => {
    const content = (
      <MuiSvgIcon className={clsx(classes.svgIcon, className)} {...others} ref={ref}>
        {children}
      </MuiSvgIcon>
    );

    return size || color ? (
      <Text
        className={clsx(classes.svgIcon__textWrapper, {
          [classes.svgIcon__mobile]: size === SvgIconSize.Mobile,
          [classes.svgIcon__desktop]: size === SvgIconSize.Desktop,
          [classes.svgIcon__denseLayout]: size === SvgIconSize.DenseLayout,
          [classes.svgIcon__display]: size === SvgIconSize.Display,
          [classes.svgIcon__headline]: size === SvgIconSize.Headline,
        })}
        span
        color={color}
      >
        {content}
      </Text>
    ) : (
      content
    );
  }
);
SvgIcon.displayName = "SvgIcon";
