import {
  Box,
  CardContentTypeMap,
  CardContent as MuiCardContent,
  CardContentProps as MuiCardContentProps,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Theme, styled } from "@mui/material/styles";
import { ReactNode, forwardRef } from "react";
import { propTrueValue } from "./common";
export type CardContentProps = {
  children?: ReactNode;
  withBGC?: boolean | string | "primary-light";
} & MuiCardContentProps;

const StyledCardContentWrapper = styled(Box)(({ theme, withBGC }: CardContentProps & { theme: Theme }) => {
  const backgroundColor =
    withBGC === "primary-light"
      ? theme.palette.purple.surfaceSubdued
      : propTrueValue(withBGC)
        ? theme.palette.neutral.borderSubdued
        : "unset";
  return {
    backgroundColor,
  };
});

const StyledCardContent = styled<OverridableComponent<CardContentTypeMap<CardContentProps, "div">>>(MuiCardContent)(({
  theme,
}) => {
  return {
    padding: 0,
  };
});

export const CardContent = forwardRef<HTMLElement, CardContentProps>(({ withBGC, children, ...rest }, ref) => (
  <StyledCardContentWrapper withBGC={withBGC} className='ContentWrapper'>
    <StyledCardContent {...rest}>{children}</StyledCardContent>
  </StyledCardContentWrapper>
));
CardContent.displayName = "CardContent";
