import { createTheme } from "@mui/material";
import "@mui/material/Typography";
import "@mui/material/styles";
import { Palette } from "@mui/material/styles/createPalette";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { constants } from "../styles/constants";

export enum EFont {
  h0 = "h0",
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  p1 = "p1",
  p2 = "p2",
  p3 = "p3",
  l1 = "l1",
  l2 = "l2",
  l3 = "l3",
  m1 = "m1",
}

export const EFontSize = {
  h0: "44px",
  h1: "32px",
  h2: "26px",
  h3: "22px",
  p1: "16px",
  p2: "14px",
  p3: "12px",
  l1: "16px",
  l2: "14px",
  l3: "12px",
  m1: "24px",
};

export const ELineHeight = {
  h0: "48px",
  h1: "40px",
  h2: "32px",
  h3: "32px",
  p1: "24px",
  p2: "20px",
  p3: "16px",
  l1: "24px",
  l2: "20px",
  l3: "16px",
  m1: "32px",
};
declare module "@mui/material/styles" {
  interface TypographyVariants {
    h0: React.CSSProperties;
    p1: React.CSSProperties;
    p2: React.CSSProperties;
    p3: React.CSSProperties;
    l1: React.CSSProperties;
    l2: React.CSSProperties;
    l3: React.CSSProperties;
    m1: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    // body3?: React.CSSProperties;
    h0?: React.CSSProperties;
    p1?: React.CSSProperties;
    p2?: React.CSSProperties;
    p3?: React.CSSProperties;
    l1?: React.CSSProperties;
    l2?: React.CSSProperties;
    l3?: React.CSSProperties;
    m1?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h0: true;
    p1: true;
    p2: true;
    p3: true;
    l1: true;
    l2: true;
    l3: true;
    m1: true;
  }
}
const theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});

export const typography: TypographyOptions | ((palette: Palette) => TypographyOptions) = {
  fontFamily: constants.varFontPlutoSans,
  h0: {
    fontSize: EFontSize.h0,
    lineHeight: ELineHeight.h0,
    fontWeight: theme.typography.fontWeightMedium,
  },
  h1: {
    fontSize: EFontSize.h1,
    lineHeight: ELineHeight.h1,
    fontWeight: theme.typography.fontWeightMedium,
  },
  h2: {
    fontSize: EFontSize.h2,
    lineHeight: ELineHeight.h2,
    fontWeight: theme.typography.fontWeightMedium,
  },
  h3: {
    fontSize: EFontSize.h3,
    lineHeight: ELineHeight.h2,
    fontWeight: theme.typography.fontWeightMedium,
  },
  p1: {
    fontSize: EFontSize.p1,
    lineHeight: ELineHeight.p1,
    fontWeight: theme.typography.fontWeightRegular,
  },
  p2: {
    fontSize: EFontSize.p2,
    lineHeight: ELineHeight.p2,
    fontWeight: theme.typography.fontWeightRegular,
  },
  p3: {
    fontSize: EFontSize.p3,
    lineHeight: ELineHeight.p3,
    fontWeight: theme.typography.fontWeightRegular,
  },
  l1: {
    fontSize: EFontSize.p1,
    lineHeight: ELineHeight.p1,
    fontWeight: theme.typography.fontWeightRegular,
  },
  l2: {
    fontSize: EFontSize.p2,
    lineHeight: ELineHeight.p2,
    fontWeight: theme.typography.fontWeightRegular,
  },
  l3: {
    fontSize: EFontSize.p3,
    lineHeight: ELineHeight.p3,
    fontWeight: theme.typography.fontWeightRegular,
  },
  m1: {
    fontSize: EFontSize.m1,
    lineHeight: ELineHeight.m1,
    fontWeight: theme.typography.fontWeightMedium,
  },
  // Please remove body3 and related configuration items next batch
  h4: undefined,
  h5: undefined,
  h6: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  body1: undefined,
  body2: undefined,
};
