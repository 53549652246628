import { styled } from "@mui/material";
import { NonTickedIcon } from "./NonTickedIcon";

export const CheckedIcon = styled(NonTickedIcon)(({ theme }) => ({
  backgroundColor: theme.palette.purple.default,
  border: "none !important",
  "&::before": {
    width: 23,
    height: 23,
    display: "block",
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath" +
      " d='M19.4478 7.73131C19.476 7.70319 19.4918 7.66504 19.4918 7.62526C19.4918 7.58548 19.476 7.54732 19.4479 7.51918L18.741 6.81187C18.6824 " +
      "6.75328 18.5875 6.75325 18.5289 6.81181L10.1247 15.2112L5.46699 10.5617C5.43883 10.5336 5.40066 10.5179 5.36088 10.5179C5.3211 10.5179 5.28296 " +
      " 10.5338 5.25485 10.5619L4.54838 11.2697C4.48985 11.3283 4.48994 11.4233 4.54857 11.4818L9.66581 16.5899C9.91967 16.8433 " +
      "10.3308 16.8432 10.5845 16.5896L19.4478 7.73131Z' fill='white' stroke='white' stroke-width='0.4'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:disabled ~ &": {
    backgroundColor: theme.palette.neutral.background,
  },
}));
