import {
  AdditionalBaseFieldInfo,
  Box,
  Button,
  ButtonVariant,
  DateRangePickerField,
  Dropdown,
  EPixel,
  FieldInputGroup,
  Form,
  Grid,
  Text,
  TextInput,
  useForm,
} from "@hkexpressairwayslimited/ui/src";
import { styled } from "@mui/material";
import { addDays } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { BookingProps } from "../Booking";
import { TripFromTo } from "../TripFromTo/TripFromTo";

const StyledButton = styled(Button)(({ theme }) => ({
  minHeight: "56px !important",
  minWidth: "initial !important",
}));

enum Fields {
  SearchBy = "SearchBy",
  From = "From",
  To = "To",
  FlightNumber = "FlightNumber",
  DepartDate = "DepartDate",
}

const searchByValue = {
  flightNumber: "flight-number",
  route: "station",
};

const defaultValues = {
  [Fields.SearchBy]: searchByValue.flightNumber,
  [Fields.From]: "",
  [Fields.To]: "",
  [Fields.FlightNumber]: "",
  [Fields.DepartDate]: [null, null],
};

type FormValues = typeof defaultValues;

const today = new Date();
const minDate = addDays(today, -4);
const maxDate = addDays(today, 6);

export function FlightStatus({ flightRouteOptions, handleFlightStatusSubmit, i18nContent, curTab }: BookingProps) {
  const [searchBy, setSearchBy] = useState(searchByValue.flightNumber);
  const SearchByOptions = useMemo(() => {
    return [
      {
        label: i18nContent.flightStatus.flightNumber,
        value: searchByValue.flightNumber,
      },
      {
        label: i18nContent.flightStatus.route,
        value: searchByValue.route,
      },
    ];
  }, [i18nContent.flightStatus.flightNumber, i18nContent.flightStatus.route]);
  const FormSchemas = {
    [Fields.SearchBy]: yup.string().required(),
    [Fields.From]: yup.string().when(Fields.SearchBy, (value, schema) => {
      return value?.[0] === searchByValue.route
        ? schema.required(i18nContent.flightStatus.fromRequired)
        : schema.notRequired();
    }),
    [Fields.To]: yup.string().when(Fields.SearchBy, (value, schema) => {
      return value?.[0] === searchByValue.route
        ? schema.required(i18nContent.flightStatus.toRequired)
        : schema.notRequired();
    }),
    [Fields.FlightNumber]: yup.string().when(Fields.SearchBy, (value, schema) => {
      return value?.[0] === searchByValue.flightNumber
        ? schema.required(i18nContent.flightStatus.flightNumberRequired)
        : schema.notRequired();
    }),
    [Fields.DepartDate]: yup.array().compact().min(1, i18nContent.flightStatus.departDateRequired),
  };
  const formHook = useForm<FormValues>({
    defaultValues,
    schema: yup.object().shape(FormSchemas),
  });

  useEffect(() => {
    if (curTab !== 3) {
      formHook.clearErrors();
    }
  }, [curTab]);

  const handSubmit = (formValues: any) => {
    handleFlightStatusSubmit && handleFlightStatusSubmit(formValues);
  };
  const handleSearchByValueChange = (info: AdditionalBaseFieldInfo, e: string) => {
    setSearchBy(e);
    setTimeout(() => {
      formHook.clearErrors();
    });
  };

  const tripFromToContent = {
    from: i18nContent.bookTrip.from,
    to: i18nContent.bookTrip.to,
    airportCode: i18nContent.bookTrip.airportCode,
  };
  return (
    <Form formHook={formHook} onSubmit={handSubmit}>
      <Box sx={{ mb: EPixel.px16 }}>
        <Text>{i18nContent.flightStatus.text}</Text>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={10} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={searchBy === searchByValue.flightNumber ? 4 : 3} xs={12}>
              <FieldInputGroup onValueChange={handleSearchByValueChange} fullWidth names={[Fields.SearchBy]}>
                <Dropdown fullWidth options={SearchByOptions} />
              </FieldInputGroup>
            </Grid>
            {searchBy === searchByValue.flightNumber && (
              <Grid item md={4} xs={12}>
                <FieldInputGroup fullWidth names={[Fields.FlightNumber]} helperText={i18nContent.flightStatus.searchBy}>
                  <TextInput fullWidth placeholder={i18nContent.flightStatus.flightNumber} />
                </FieldInputGroup>
              </Grid>
            )}
            {searchBy === searchByValue.route && (
              <Grid item md={6} xs={12}>
                <TripFromTo
                  i18nContent={tripFromToContent}
                  formHook={formHook}
                  isChangeLeftRight
                  flightRouteOptions={flightRouteOptions}
                  names={[Fields.From, Fields.To]}
                />
              </Grid>
            )}
            <Grid item md={searchBy === searchByValue.flightNumber ? 4 : 3} xs={12}>
              <FieldInputGroup fullWidth names={[Fields.DepartDate]} helperText={i18nContent.flightStatus.departDate}>
                <DateRangePickerField
                  isSingleDate
                  placeholder={[i18nContent.flightStatus.departDate]}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              </FieldInputGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2} xs={12} display='flex' alignItems='flex-start'>
          <StyledButton borderRadius={EPixel.px28} submit fullWidth variant={ButtonVariant.Primary}>
            {i18nContent.flightStatus.search}
          </StyledButton>
        </Grid>
      </Grid>
    </Form>
  );
}
