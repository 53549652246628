import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import DotSvg from "../../assets/icons/dot.svg";

export function DotIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      {/* credit: plus icon from https://heroicons.com/ */}
      <DotSvg />
    </SvgIcon>
  );
}
