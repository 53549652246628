import { getSubdomain } from "tldts";

const sitecoreApiHost = process.env.SITECORE_API_HOST || "";

export const envUiConfig = {
  publicUrl: process.env.NEXT_PUBLIC_PUBLIC_URL || "",
  sitecoreHost: process.env.NEXT_PUBLIC_SITECORE_HOST || "",
  sitecoreSiteName: process.env.SITECORE_SITE_NAME || "",
  quantumMetricsScripts: process.env.NEXT_PUBLIC_QUANTUM_METRICS_SCRIPT?.split(",") || [],
  personalizeScript: process.env.NEXT_PUBLIC_PERSONALIZE_SCRIPT || "",
  personalizeClientKey: process.env.NEXT_PUBLIC_PERSONALIZE_CLIENT_KEY,
  personalizePOS: process.env.NEXT_PUBLIC_PERSONALIZE_POS,
  debug: process.env.NEXT_PUBLIC_DEBUG,
  muiLicenseKey: process.env.NEXT_PUBLIC_MUI_LICENSE_KEY,
  sitecoreApiHost,
  sitecoreInstanceId: getSubdomain(sitecoreApiHost)?.replace(/^xmc-/, ""),
};
