import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import VectorSvg from "../../assets/icons/vector.svg";
import ArrivalSvg from "../../assets/icons/Arrival.svg";

export function VectorIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      {/* credit: plus icon from https://heroicons.com/ */}
      <VectorSvg />
    </SvgIcon>
  );
}
export function ArrivalIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <ArrivalSvg />+{" "}
    </SvgIcon>
  );
}
