import { Text, Title, TitleOrders } from "@hkexpressairwayslimited/ui/src";

import classes from "./PressRelease.module.scss";

export type PressReleaseProps = {
  date: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  style?: React.CSSProperties;
};

export const PressRelease = ({ date, title, description, style }: PressReleaseProps) => {
  return (
    <div className={classes.pressRelease} style={style}>
      <Text bold>{date}</Text>
      <div>
        <Title order={TitleOrders.H3Title}>{title}</Title>
        <div>{description}</div>
      </div>
    </div>
  );
};
