import { EPixel } from "@hkexpressairwayslimited/ui/src";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ChangeEvent, forwardRef, useEffect, useState } from "react";
import { Box, Button, ButtonVariant, Modal, ModalHandle, OtpType, Text } from "../..";

interface CustomProps {
  onChooseVerifyMethod: (value: string) => void;
  i18nContent?: { [key: string]: string | JSX.Element | JSX.Element[] };
  value?: string | null;
}
export const ChooseOtpMethod = forwardRef<ModalHandle, CustomProps>((props: CustomProps, ref) => {
  const { onChooseVerifyMethod, value } = props;
  const i18nContent = props.i18nContent || {};
  const [curUpdateType, setCurUpdateType] = useState<OtpType | null>();
  const handleVerifyMethodChange = (event: ChangeEvent<HTMLInputElement>, value: string) => {
    setCurUpdateType(value as OtpType);
  };
  const handleChooseVerifyMethod = () => {
    curUpdateType && onChooseVerifyMethod(curUpdateType);
  };
  useEffect(() => {
    setCurUpdateType(value as OtpType);
  }, [value]);
  return (
    <Modal title={i18nContent.title} ref={ref}>
      <Box sx={{ mb: EPixel.px16 }}>
        <Text>{i18nContent.content}</Text>
      </Box>
      <RadioGroup sx={{ flexDirection: "row" }} value={curUpdateType} onChange={handleVerifyMethodChange}>
        <FormControlLabel value={OtpType.Email} control={<Radio />} label={i18nContent.email} />
        <FormControlLabel value={OtpType.Mobile} control={<Radio />} label={i18nContent.mobile} />
      </RadioGroup>
      <Box sx={{ mt: EPixel.px16, padding: "1px" }}>
        <Button onClick={handleChooseVerifyMethod} fullWidth variant={ButtonVariant.Primary} disabled={!curUpdateType}>
          {i18nContent.verifyNow}
        </Button>
      </Box>
    </Modal>
  );
});
ChooseOtpMethod.displayName = "ChooseOtpMethod";
