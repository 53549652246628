/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { TimePicker } from "../../Atoms/TimePicker/TimePicker";
import dayjs, { Dayjs } from "dayjs";

export interface TimePickerFieldProps {
  id: string;
  label: string;
  defaultValue?: string;
  onChange?: Function;
  isError?: boolean;
  helperText?: string;
}
const TimePickerField = (props: TimePickerFieldProps) => {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs(props.defaultValue));

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
    props.onChange && props.onChange(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <TimePicker
        label={props.label}
        value={value}
        onChange={(e: any) => handleChange(e)}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            error={props.isError}
            helperText={props.helperText}
          />
        )}
      /> */}
    </LocalizationProvider>
  );
};

export { TimePickerField };
