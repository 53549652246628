import { EBorders } from "@hkexpressairwayslimited/ui/src/themes";
import { Box, CardActions as MuiCardActions, CardActionsProps as MuiCardActionsProps } from "@mui/material";
import { Theme, styled } from "@mui/material/styles";
import { ReactNode, forwardRef } from "react";
import { propTrueValue } from "./common";
export type CardFooterProps = {
  children?: ReactNode;
  variant?: "default" | "primary" | "white" | "surfaceSubdued";
  borderTop?: "primary";
};

const StyledCardFooter = styled(MuiCardActions)(({ theme }) => {
  return {
    padding: 0,
  };
});

const Wrapper = styled(Box)(({
  theme,
  variant = "default",
  borderTop,
}: { theme: Theme } & MuiCardActionsProps & CardFooterProps) => {
  const backgroundColor =
    variant === "surfaceSubdued"
      ? theme.palette.purple.surfaceSubdued
      : variant === "primary"
        ? theme.palette.purple.default
        : variant === "white"
          ? "inherit"
          : propTrueValue(variant)
            ? theme.palette.neutral.borderSubdued
            : "unset";
  return {
    color:
      variant === "default"
        ? theme.palette.purple.default
        : variant === "surfaceSubdued"
          ? theme.palette.purple.default
          : theme.palette.neutral.white,
    backgroundColor,
    ...(borderTop === "primary" && { borderTop: EBorders.boldb2 + "!important" }),
  };
});

export const CardFooter = forwardRef<HTMLElement, CardFooterProps>(({ children, ...rest }, ref) => (
  <Wrapper {...rest} className='FooterWrapper'>
    <StyledCardFooter {...rest}>{children}</StyledCardFooter>
  </Wrapper>
));
CardFooter.displayName = "CardFooter";
