import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { clsx } from "clsx";
import { ChangeEventHandler, ComponentProps, forwardRef, useEffect, useState } from "react";

import { Button } from "@hkexpressairwayslimited/ui/src";
import { Box } from "../Box/Box";
import { FormFieldProps } from "../Form";
import { TextInput, TextInputProps } from "../TextInput";

import classes from "./NumberInput.module.scss";

export enum NumberInputVariant {
  Primary = "Primary",
}

export type NumberInputProps = {
  variant?: NumberInputVariant;
  editable?: boolean;
  custom?: boolean;
} & ComponentProps<"input"> &
  Partial<Omit<FormFieldProps, "value">> &
  TextInputProps;

export const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      value = 0,
      children,
      className,
      custom,
      variant = !custom && NumberInputVariant.Primary,
      editable,
      min = 0,
      max,
      step = 1,
      error,
      isValidating,
      isDirty,
      invalid,
      isTouched,
      groupSubFieldHandle,
      onChange,
      onBlur,
      ...others
    },
    ref
  ) => {
    const [inputEl, setInputEl] = useState<HTMLInputElement | null>();
    const [formValue, setFormValue] = useState(Number(value));

    const handleValueChange = (number: number) => {
      setFormValue(number);
    };

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      handleValueChange(Number(event.target.value));
      if (onChange) {
        onChange(event);
      }
    };

    const handleMinus = () => {
      inputEl?.stepDown();

      const ev = new Event("change", { bubbles: true });

      inputEl?.dispatchEvent(ev);
    };

    const handlePlus = () => {
      inputEl?.stepUp();

      const ev = new Event("change", { bubbles: true });

      inputEl?.dispatchEvent(ev);
    };

    useEffect(() => {
      handleValueChange(Number(value));
    }, [value]);
    return (
      <Box
        className={clsx(classes.numberInput, className, {
          [classes.numberInput__primary]: variant === NumberInputVariant.Primary,
        })}
      >
        {!custom && (
          <Button
            className={clsx(classes.numberInput_minus, {
              [classes.numberInput_minus__hitLimit]: min !== undefined && formValue <= Number(min),
            })}
            custom
            aria-label={"minus"}
            onClick={handleMinus}
            disabled={min !== undefined && formValue <= Number(min)}
          >
            <RemoveOutlinedIcon />
          </Button>
        )}
        <Box className={classes.numberInput_inputWrapper}>
          <TextInput
            disabled={
              (max !== undefined && formValue >= Number(max)) || (min !== undefined && formValue <= Number(min))
            }
            type='number'
            className={classes.numberInput_input}
            value={String(formValue)}
            onChange={handleChange}
            onBlur={() => {
              if (onBlur) {
                onBlur();
              }
            }}
            inputRef={setInputEl}
            readOnly={!editable && !custom}
            inputProps={{
              min,
              max,
            }}
            {...others}
            ref={ref}
          />
        </Box>
        {!custom && (
          <Button
            className={clsx(classes.numberInput_plus, {
              [classes.numberInput_plus__hitLimit]: max !== undefined && formValue >= Number(max),
            })}
            custom
            aria-label={"plus"}
            onClick={handlePlus}
          >
            <AddOutlinedIcon />
          </Button>
        )}
      </Box>
    );
  }
);
NumberInput.displayName = "NumberInput";
