import { Stack, Box } from "@mui/material";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import FlightLandOutlinedIcon from "@mui/icons-material/FlightLandOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import { Font } from "../../Atoms/Font/Font";
import { EFont, EPixel } from "@hkexpressairwayslimited/ui/src";

export function DepartureDestinationSelector() {
  return (
    <Stack direction='row' justifyContent='center' alignItems='center' spacing={1}>
      <FlightTakeoffOutlinedIcon />
      <Box sx={{ flex: "auto", textAlign: "center" }}>
        <Font>Hong Kong (HKG)</Font>
        <Font variant={EFont.p2}>Hong Kong SAR</Font>
      </Box>
      <AutorenewOutlinedIcon />
      <FlightLandOutlinedIcon />
      <Box sx={{ flex: "auto", textAlign: "center" }}>
        <Font>Ningbo (NGB)</Font>
        <Font variant={EFont.p2} fontWeight='fontWeightLight'>
          Chinese Mainland
        </Font>
      </Box>
    </Stack>
  );
}
