import { Color, createTheme } from "@mui/material";
import { PaletteOptions } from "@mui/material/styles/createPalette";
type Neutral = {
  black: string;
  grey: string;
  disable: string;
  borderNeutral: string;
  borderSubdued: string;
  background: string;
  white: string;
};
type HKEColor = {
  focus?: string;
  default?: string;
  hover?: string;
  decorative?: string;
  disabled?: string;
  surface?: string;
  surfaceSubdued?: string;
};

// Augment the palette to include a salmon color
declare module "@mui/material/styles" {
  interface Palette {
    blue: HKEColor;
    orange: Partial<Color>;
    // purple: Partial<Color>;
    neutral: Neutral;
    purple: HKEColor;
    yellow: HKEColor;
    brown: HKEColor;
    cash: HKEColor;
    hke_success: HKEColor;
    critical: HKEColor;
  }

  interface PaletteOptions {
    submit?: PaletteOptions["primary"];
    blue: HKEColor;
    orange?: Partial<Color>;
    neutral: Neutral;
    purple: HKEColor;
    yellow: HKEColor;
    brown: HKEColor;
    hke_success: HKEColor;
    critical: HKEColor;
  }
}

// Update the Button's color options to include a salmon option
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    blue: true;
    orange: true;
    purple: true;
    neutral: true;
    yellow: true;
    brown: true;
    hke_success: true;
    critical: true;
  }
}

const theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});

const palette: PaletteOptions = {
  mode: "light",
  primary: {
    main: "#702B91",
  },
  neutral: {
    black: "#000000",
    grey: "#4D4D4D",
    disable: "#AEB2BB",
    borderNeutral: "#d6d6d8",
    borderSubdued: "#F7F7F7",
    background: "#F5F5F5",
    white: "#ffffff",
  },
  purple: {
    focus: "#59107D",
    default: "#702B91",
    hover: "#8A53A5",
    decorative: "#A980BD",
    disabled: "#C6AAD3",
    surface: "#E2D5E9",
    surfaceSubdued: "#F6F4F9",
  },
  blue: {
    focus: "#0DA9CB",
    default: "#21B9DA",
    hover: "#4DC7E1",
    decorative: "#7AD5E9",
    disabled: "#A6E3F0",
    surface: "#D3F1F8",
    surfaceSubdued: "#E9F8FB",
  },
  yellow: {
    focus: "#FEEA00",
    default: undefined,
    hover: undefined,
    decorative: undefined,
    disabled: undefined,
    surface: "#FFFBCC",
    surfaceSubdued: undefined,
  },
  brown: {
    focus: "#6A280A",
    default: "#8A3F1E",
    hover: undefined,
    decorative: undefined,
    disabled: undefined,
    surface: "#F3ECE8",
    surfaceSubdued: undefined,
  },
  hke_success: {
    default: "#006554",
    surface: "#E5F0EE",
  },
  critical: {
    focus: undefined,
    default: "#ED145B",
    hover: undefined,
    decorative: undefined,
    disabled: undefined,
    surface: "#FDE7EF",
    surfaceSubdued: undefined,
  },
};

export { palette };
