import { MutableRefObject, ReactElement, cloneElement, useMemo } from "react";
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldError,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";

import { GroupSubFieldHandle } from "@hkexpressairwayslimited/ui/src";
import { isPlainObject } from "lodash";
import { useController } from "../useController";

// type ControllerReturnType = Pick<ReturnType<typeof useController>, "field" | "fieldState">;

export type FormFieldProps<TFieldValues extends FieldValues = FieldValues, Checkbox extends boolean = false> = {
  groupSubFieldHandle?: MutableRefObject<GroupSubFieldHandle | undefined>;
  disabled?: boolean;
  "aria-label"?: string;
} & Partial<ControllerRenderProps<TFieldValues>> &
  Partial<ControllerFieldState> &
  (Checkbox extends true
    ? {
        checked?: boolean;
      }
    : {});

export type AdditionalBaseFieldInfo<TFieldValues extends FieldValues = FieldValues> = {
  index?: number;
  name: Path<TFieldValues>;
};

export type BaseFieldInputProps<TFieldValues extends FieldValues, Checkbox extends boolean> = {
  index?: number;
  name: Path<TFieldValues>;
  children: ReactElement<Partial<FormFieldProps<TFieldValues, Checkbox>>>;
  checkbox?: boolean;
  groupSubFieldHandle?: MutableRefObject<GroupSubFieldHandle | undefined>;
  onProcessValue?: (
    value: PathValue<TFieldValues, Path<TFieldValues>> | undefined,
    info: AdditionalBaseFieldInfo<TFieldValues>
  ) => PathValue<TFieldValues, Path<TFieldValues>>;
  onValueChange?: (info: AdditionalBaseFieldInfo<TFieldValues>, ...event: any[]) => any;
  onBlur?: () => void;
  disabled?: boolean;
  "aria-label"?: string;
};

export const BaseFieldInput = <TFieldValues extends FieldValues, Checkbox extends boolean>({
  index,
  children,
  name,
  checkbox,
  groupSubFieldHandle,
  onProcessValue,
  onValueChange,
  onBlur,
  disabled,
  "aria-label": ariaLabel,
}: BaseFieldInputProps<TFieldValues, Checkbox>) => {
  const { field, fieldState } = useController<TFieldValues>({ name });
  const clonedElement = useMemo(() => {
    const fieldInfo = { index, name };
    const processedValue = onProcessValue ? onProcessValue(field?.value, fieldInfo) : undefined;
    let propsToPass: FormFieldProps<TFieldValues> = {
      ...(field || {}),
      ...(fieldState || {}),
      name,
      groupSubFieldHandle,
      value: processedValue === undefined ? field?.value : processedValue,
      onChange: (...event: unknown[]) => {
        const changedValue = onValueChange ? onValueChange(fieldInfo, ...event) : undefined;
        const value = changedValue === undefined ? event[0] : changedValue;

        field?.onChange(value, ...event);
      },
      onBlur: () => {
        field?.onBlur();
        onBlur?.();
      },
      disabled,
      "aria-label": ariaLabel,
    };

    if (checkbox) {
      propsToPass = {
        ...propsToPass,
        checked: field?.value,
      } as FormFieldProps<TFieldValues, true>;
    }

    return cloneElement(children, propsToPass);
  }, [index, children, field, fieldState, name, groupSubFieldHandle, onProcessValue, onValueChange, onBlur, ariaLabel]);

  return clonedElement;
};

export const flattenAllErrors = <TFieldValues extends FieldValues>(
  errors?: FieldErrors<TFieldValues>[Path<TFieldValues>]
): FieldError[] => {
  if (!errors) {
    return [];
  }

  const arr = [];
  if (Array.isArray(errors)) {
    for (const error of errors) {
      arr.push(...flattenAllErrors<TFieldValues>(error));
    }
  } else if (isPlainObject(errors)) {
    if (errors.message) {
      const { message, type, ref } = errors;

      arr.push({ message, type, ref });
    }
    Object.entries(errors).forEach(([key, error]) => {
      if (key !== "message" && key !== "type" && key !== "ref") {
        arr.push(...flattenAllErrors<TFieldValues>(error as FieldErrors<TFieldValues>[Path<TFieldValues>]));
      }
    });
  }
  return arr as FieldError[];
};
