import * as React from "react";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

interface FormControlProps {}
const StyledFormControl = styled(FormControl)<FormControlProps>(({}) => ({}));

interface StyledFormControlProps {
  children: any;
}
const FormField = (props: StyledFormControlProps) => <StyledFormControl fullWidth>{props.children}</StyledFormControl>;

export { FormField };
