import { Box, PaymentMenu, PaymentTextField, useFormContext } from "@hkexpressairwayslimited/ui/src";
import { Grid, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { PaymentDataProps } from "../paymentTypes";

const PaymentFormBookingInformation = ({ data }: PaymentDataProps) => {
  const { control } = useFormContext();
  function FormRow() {
    return (
      <>
        <Grid item xs={6}>
          <PaymentMenu data={data.market} label='Market' id='market' name='market' />
        </Grid>
        <Grid item xs={6}>
          <PaymentMenu data={data.appCode} label='App Code' id='app-app_code' name='app_code' />
        </Grid>
        {/* <Grid item xs={3}>
          <PaymentTextField
            id='sales_port'
            label='Sales Port'
            variant='filled'
            name='sales_port'
            maxLength={3}
            uppercase={true}
            customControl={control}
          />
        </Grid> */}
        <Grid item xs={12}>
          <PaymentTextField id='PNR' label='PNR' variant='filled' name='pnr' />
        </Grid>
        <Grid item xs={6}>
          <PaymentTextField
            id='last_name'
            label='Last Name'
            variant='filled'
            name='last_name'
            customControl={control}
          />
        </Grid>
        <Grid item xs={6}>
          <PaymentTextField
            id='first_name'
            label='First Name'
            variant='filled'
            name='first_name'
            customControl={control}
          />
        </Grid>

        {/* <Grid item xs={3}>
          <PaymentMenu data={data.brand} label='Brand' id='brand_code' name='brand_code' />
        </Grid> */}
      </>
    );
  }
  return (
    <>
      <Stack direction='row' spacing={2} paddingBottom={4} borderBottom={1}>
        <Grid container>
          <Grid item xs={3}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography variant='subtitle1' color={"#702B91"}>
                Booking Information
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <PaymentTextField
                  label='Notification Email'
                  id='noti-email'
                  variant='filled'
                  name='notificationEmail'
                  customControl={control}
                />
              </Grid>

              <FormRow />

              {/* <Grid item xs={6}>
                <PaymentTextField
                  id='sales_reference'
                  label='Sales Reference'
                  variant='filled'
                  name='sales_reference'
                  customControl={control}
                />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

PaymentFormBookingInformation.propTypes = {
  data: PropTypes.shape({
    appCode: PropTypes.array,
    brand: PropTypes.array,
    market: PropTypes.array,
  }),
};

export { PaymentFormBookingInformation };
