import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiSelect from "@mui/material/Select";
import MuiMenuItem from "@mui/material/MenuItem";

export interface StyledSelectProps {}
const StyledSelect = styled(MuiSelect)<StyledSelectProps>(({}) => ({}));

export interface StyledMenuItemProps {}
const StyledMenuItem = styled(MuiMenuItem)<StyledMenuItemProps>(({}) => ({}));

export { StyledSelect, StyledMenuItem };
