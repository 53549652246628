import {
  Box,
  Button,
  ButtonVariant,
  ESpacing,
  HKELogo,
  Image,
  SvgIcon,
  strInterpolator,
} from "@hkexpressairwayslimited/ui/src";
import { ReactNode, useCallback, useEffect, useState } from "react";
import notFound from "../../../assets/images/not_found.png";

export type ErrorPageProps = {
  title: ReactNode;
  text: ReactNode;
  desc: string;
  onCountdownEnd: () => void;
  auto?: boolean;
};

export const ErrorPage = ({ title, text, desc, onCountdownEnd, auto = true }: ErrorPageProps) => {
  const [count, setCount] = useState(auto ? 5 : 3);

  const handleCountdownEnd = useCallback(() => {
    onCountdownEnd();
  }, [onCountdownEnd]);

  const handleCountdown = useCallback(() => {
    setCount((count: number) => count - 1);
  }, [count, setCount]);

  useEffect(() => {
    if (count < 1) {
      if (auto) {
        handleCountdownEnd();
      }
    }

    const timerId = count > 0 ? setTimeout(handleCountdown, 1000) : undefined;

    return () => clearTimeout(timerId);
  }, [count, auto, handleCountdown]);

  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
      <Box margin={ESpacing._7xl} display={"flex"} flexDirection={"column"} maxWidth={"300px"}>
        <SvgIcon sx={{ width: "300px", height: "auto" }}>
          <HKELogo />
        </SvgIcon>
        <Box textAlign={"center"} marginBottom={ESpacing._xl}>
          <Box color='purple.default'>{title}</Box>
          <Box color='purple.default'>{text}</Box>
        </Box>
        <Button variant={ButtonVariant.Secondary} disabled={auto ? false : count > 0} onClick={handleCountdownEnd}>
          {auto ? strInterpolator(desc, { time: count > 0 ? count.toString() : "1" }) : desc}
        </Button>
      </Box>
      <Box margin={ESpacing._7xl}>
        <Image width={231} height={300} src={notFound} alt={"notFound.png"} />
      </Box>
    </Box>
  );
};
