import { ReactElement } from "react";

import {
  Button,
  CathayIcon,
  CheckmarkFilledIcon,
  SvgIcon,
  SvgIconSize,
  Text,
  Title,
  TitleOrders,
  colors,
  formatNumber,
} from "@hkexpressairwayslimited/ui/src";

import classes from "./LinkCx.module.scss";
import Image from "next/image";

type Benefit = {
  imageUrl: string;
  label: string;
  desc: string;
};

export type LinkCxProps = {
  step: LinkCxSteps;
  title?: string;
  benefits: Benefit[];
  linkBtnLabel?: string;
  backBtnLabel?: string;
  linkedSuccessMsg?: string;
  milesBalanceLabel?: string;
  accountNumberLabel?: string;
  unlinkBtnLabel?: string;
  reAuthBtnLabel?: string;
  milesBalance?: number;
  accountNumber?: string;
  onLinkCxClicked?: () => void;
  onUnlinkCxClicked?: () => void;
  onReAuthCxClicked?: () => void;
  onBackClicked?: () => void;
};

export enum LinkCxSteps {
  Intro = "Intro",
  Linked = "Linked",
  ReAuth = "ReAuth",
}

export const LinkCx = ({
  step,
  title,
  benefits,
  linkBtnLabel,
  backBtnLabel,
  linkedSuccessMsg,
  milesBalanceLabel,
  accountNumberLabel,
  unlinkBtnLabel,
  reAuthBtnLabel,
  milesBalance,
  accountNumber,
  onLinkCxClicked,
  onBackClicked,
  onUnlinkCxClicked,
  onReAuthCxClicked,
}: LinkCxProps) => {
  return (
    <div className={classes.linkCx}>
      <Title color={colors.neutralBlack} order={TitleOrders.H2Headline}>
        {title}
      </Title>

      {step === LinkCxSteps.Linked && (
        <div className={classes.linkCx_successMsg}>
          <Text className={classes.linkCx_successMsgText}>
            <Text span color={colors.actionPurpleDefault}>
              <SvgIcon size={SvgIconSize.DenseLayout}>
                <CheckmarkFilledIcon />
              </SvgIcon>
            </Text>{" "}
            <Text span>{linkedSuccessMsg}</Text>
          </Text>
          <div className={classes.linkCx_accountInfo}>
            <Text>
              {milesBalanceLabel}: {milesBalance || milesBalance === 0 ? formatNumber(milesBalance) : ""}
            </Text>
            <Text>
              {accountNumberLabel}: {accountNumber}
            </Text>
          </div>
        </div>
      )}
      {step === LinkCxSteps.ReAuth && (
        <div className={classes.linkCx_successMsg}>
          {/* <Text className={classes.linkCx_successMsgText}>
            <Text span color={colors.actionPurpleDefault}>
              <SvgIcon size={SvgIconSize.DenseLayout}>
                <CheckmarkFilledIcon />
              </SvgIcon>
            </Text>{" "}
            <Text span>{linkedSuccessMsg}</Text>
          </Text> */}
          <div className={classes.linkCx_accountInfo}>
            {/* <Text>
              {milesBalanceLabel}: {milesBalance ? formatNumber(milesBalance) : ""}
            </Text> */}
            <Text>
              {accountNumberLabel}: {accountNumber}
            </Text>
          </div>
        </div>
      )}
      <div className={classes.linkCx_benefits}>
        {benefits.map((benefit, index) => (
          <div key={index} className={classes.linkCx_benefit}>
            <Image alt='' src={benefit.imageUrl} width={100} height={100} />
            <Text color={colors.actionPurpleDefault}>{benefit.label}</Text>
            <Text>{benefit.desc}</Text>
          </div>
        ))}
      </div>

      <div className={classes.linkCx_ctaGroup}>
        {step === LinkCxSteps.Intro ? (
          <>
            <Text span color='#006B6E'>
              <Button alignAllColors leadingIcon={<CathayIcon />} onClick={onLinkCxClicked} fullWidth outlined>
                {linkBtnLabel}
              </Button>
            </Text>
            {/* <Button onClick={onBackClicked} fullWidth outlined>
              {backBtnLabel}
            </Button> */}
          </>
        ) : step === LinkCxSteps.Linked ? (
          <Button onClick={onUnlinkCxClicked} fullWidth>
            {unlinkBtnLabel}
          </Button>
        ) : step === LinkCxSteps.ReAuth ? (
          <Button onClick={onReAuthCxClicked} fullWidth>
            {reAuthBtnLabel}
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
