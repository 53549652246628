import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export interface ReviewTableItem {
  field: string;
  value: string;
}

export interface TableProps {
  title: string;
  itemList: ReviewTableItem[];
}
const ReviewTable = (props: TableProps) => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label='simple table'>
      <TableHead>
        <TableRow>
          <TableCell>
            <b>{props.title}</b>
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.itemList.map((item) => (
          <TableRow key={item.field} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component='th' scope='row'>
              {item.field}
            </TableCell>
            <TableCell component='th' scope='row'>
              {item.value}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export { ReviewTable };
