import { forwardRef } from "react";

import { OptionGroup as BaseOptionGroup, OptionGroupProps } from "@mui/base/OptionGroup";

import { styled } from "@mui/system";

const GroupRoot = styled("li")`
  list-style: none;
`;

const GroupHeader = styled("div")`
  font-size: 14px;
  color: #000000;
  background-color: #f7f7f7;
  padding: 4px 8px;
  border-radius: 4px;
`;

const GroupOptions = styled("ul")`
  list-style: none;
  margin-left: 0;
  padding: 0;
  > li {
    border-bottom: 1px solid #d6d6d8;
    &:last-child {
      border-bottom: none;
    }
  }
`;

export const DropdownOptionGroup = forwardRef(function CustomOptionGroup(
  props: OptionGroupProps,
  ref: React.ForwardedRef<any>
) {
  const slots: OptionGroupProps["slots"] = {
    root: GroupRoot,
    label: GroupHeader,
    list: GroupOptions,
    ...props.slots,
  };
  return <BaseOptionGroup {...props} ref={ref} slots={slots} />;
});

DropdownOptionGroup.displayName = "DropdownButtonGroup";
