import { clsx } from "clsx";
import * as React from "react";

import { ContainerProps, Container as MuiContainer } from "@mui/material";

import classes from "./Section.module.scss";

export type SectionProps = {
  /** Whether to remove the surrounding space, `false` by default */
  full?: boolean;
  fullWidth?: boolean;
} & ContainerProps;

export const Section = React.forwardRef<HTMLElement, SectionProps>(
  ({ className, full, fullWidth, component, ...others }, ref) => (
    <MuiContainer
      className={clsx(
        classes.section,
        {
          [classes.section__full]: full,
          [classes.section__fullWidth]: fullWidth,
        },
        className
      )}
      component={component || "section"}
      {...others}
      ref={ref}
    />
  )
);
Section.displayName = "Section";
