export interface DateRange {
  start?: Date;
  end?: Date;
}
export interface CountryCode {
  value: string;
  label: string;
  country: string;
}

export const COUNTRY_CODE: CountryCode[] = [
  {
    value: "852",
    label: "+852",
    country: "HK",
  },
  {
    value: "86",
    label: "+86",
    country: "CN",
  },
  {
    value: "82",
    label: "+82",
    country: "KR",
  },
  {
    value: "81",
    label: "+81",
    country: "JP",
  },
  {
    value: "66",
    label: "+66",
    country: "TH",
  },
  {
    value: "853",
    label: "+853",
    country: "MO",
  },
  {
    value: "1",
    label: "+1",
    country: "US",
  },
  {
    value: "44",
    label: "+44",
    country: "GB",
  },
];

export const CURRENCY_OPTIONS = [
  {
    value: "HKD",
  },
  {
    value: "CNY",
  },
  {
    value: "JPY",
  },
  {
    value: "KRW",
  },
  {
    value: "USD",
  },
  {
    value: "AED",
  },
  {
    value: "AUD",
  },
  {
    value: "BRL",
  },
  {
    value: "CAD",
  },
  {
    value: "CHF",
  },
  {
    value: "CLP",
  },
  {
    value: "COP",
  },
  {
    value: "CZK",
  },
  {
    value: "DKK",
  },
  {
    value: "EUR",
  },
  {
    value: "GBP",
  },
  {
    value: "IDR",
  },
  {
    value: "INR",
  },
  {
    value: "JOD",
  },
  {
    value: "MOP",
  },
  {
    value: "MXN",
  },
  {
    value: "MYR",
  },
  {
    value: "NOK",
  },
  {
    value: "NZD",
  },
  {
    value: "PHP",
  },
  {
    value: "PLN",
  },
  {
    value: "RON",
  },
  {
    value: "SAR",
  },
  {
    value: "SEK",
  },
  {
    value: "SGD",
  },
  {
    value: "THB",
  },
  {
    value: "TWD",
  },
  {
    value: "ZAR",
  },
];

export const EMAIL_REGEXP = /^(?!.*\.{2})[a-zA-Z0-9._+\-#]+@[a-zA-Z0-9._+\-#]+\.[A-Za-z]{2,}$/;
