import classes from "./RichTextArea.module.scss";

export type RichTextAreaProps = {
  header: string;
  text: string;
};

const RichTextArea = (props: RichTextAreaProps) => {
  return (
    <div>
      <div className={classes.context_h3}>{props.header}</div>
      <div className={classes.context_p}>{props.text}</div>
    </div>
  );
};

export { RichTextArea };
