import React from "react";
import { Box } from "@mui/material";
import { BigImageBox, AccordionHKE, EqualHeightGridCard, RichTextArea } from "@hkexpressairwayslimited/ui/src";
import { AccordionHKEBody } from "../../Molecules/AccordionHKE/AccordionHKEBody";
import classes from "./DestinationGuidesTemplate.module.scss";

export interface DestinationGuidesTemplateProps {
  BigImageBoxData: {
    Image: {
      url: string;
      width?: number;
      height?: number;
    };
  };
  CityInfo: {
    title: string;
    textList: any[];
  };
  AccordionHKEData: any[];
  QuestionData: any[];
  DestionationData: any[];
}

const DestinationGuidesTemplate = (props: DestinationGuidesTemplateProps) => {
  const { BigImageBoxData, CityInfo, AccordionHKEData, QuestionData, DestionationData } = props;

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <BigImageBox {...BigImageBoxData} />
      </div>
      <div className={classes.richTextArea}>
        <div className={classes.context_h1}>{CityInfo.title}</div>
        {CityInfo.textList.map((item: any, index: any) => (
          <div className={classes.context_p} key={index}>
            {item}
          </div>
        ))}
      </div>
      <div>
        {AccordionHKEData.map((item: any, index: any) => (
          <div key={index} className={classes.accordionItem}>
            <AccordionHKE {...item}></AccordionHKE>
          </div>
        ))}
      </div>
      <div className={classes.questionContainer}>
        <div className={classes.context_h2}>常见问题</div>
        {QuestionData.map((item: any, index: any) => (
          <div key={index}>
            <RichTextArea {...item} />
          </div>
        ))}
      </div>
      <div className={classes.context_h1}>精彩航点</div>
      <div>
        <EqualHeightGridCard destinations={DestionationData} />
      </div>
    </div>
  );
};

export { DestinationGuidesTemplate };
