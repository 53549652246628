import clsx from "clsx";

import { merge } from "lodash";
import { CSSProperties } from "react";
import classes from "./Divider.module.scss";

export type DividerProps = {
  inline?: boolean;
  vertical?: boolean;
  thickness?: number;
  className?: string;
  color?: CSSProperties["color"];
};

export const Divider = ({ vertical = false, inline = false, thickness = 1, className, color }: DividerProps) => {
  return (
    <hr
      className={clsx(className, classes.divider, {
        [classes.divider__horizontal]: !vertical,
        [classes.divider__vertical]: vertical,
        [classes.divider__inline]: inline,
      })}
      style={merge(
        {},
        { borderColor: color },
        vertical ? { borderRightWidth: thickness } : { borderTopWidth: thickness }
      )}
    />
  );
};
