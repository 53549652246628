import { forwardRef } from "react";
import { clsx } from "clsx";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import { DateCalendar } from "../DateCalendar";
import { Box } from "../../Box";

import classes from "./DateRangeCalendar.module.scss";

export type DateRangeCalendarValue = {
  from?: Dayjs;
  to?: Dayjs;
};

export type DateRangeCalendarProps = {
  className?: string;
  value?: DateRangeCalendarValue;
  onChange?: (value?: DateRangeCalendarValue) => void;
};

export const DateRangeCalendar = forwardRef<HTMLDivElement, DateRangeCalendarProps>(
  ({ className, value, onChange, ...others }, ref) => {
    const handleCalendarChange = (calendarValue: DateRangeCalendarValue) => {
      if (onChange) {
        onChange({
          ...(value || {}),
          ...calendarValue,
        });
      }
    };

    return (
      <Box className={clsx(classes.dateRangeCalendar, className)} ref={ref}>
        <DateCalendar
          value={value?.from}
          onChange={(value) =>
            handleCalendarChange({
              from: value,
            })
          }
          {...others}
        />
        <DateCalendar
          value={value?.to}
          onChange={(value) =>
            handleCalendarChange({
              to: value,
            })
          }
          {...others}
        />
      </Box>
    );
  }
);
DateRangeCalendar.displayName = "DateRangeCalendar";
