import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { styled } from "@mui/material/styles";
import * as React from "react";

interface Props {}
const StyledTabs = styled(Tabs)<Props>(({ theme }) => ({}));
const StyledTab = styled(Tab)<Props>(({ theme }) => ({}));

interface TabPanelProps {
  id: string;
  children?: React.ReactNode;
  index: number;
  value: number;
  noPadding: boolean;
}
const TabPanel = (props: TabPanelProps) => {
  const { id, children, value, index, noPadding, ...other } = props;
  return (
    <div role='tabpanel' hidden={value !== index} id={`${id}-${index}`} aria-labelledby={`${id}-${index}`} {...other}>
      {value === index && noPadding ? <Box sx={{ p: 0 }}>{children}</Box> : <Box sx={{ p: 2 }}>{children}</Box>}
    </div>
  );
};

export { StyledTab as Tab, TabPanel, StyledTabs as Tabs };
