import { ComponentsOverrides, ComponentsProps, ComponentsVariants, DrawerProps, Theme } from "@mui/material";
import { ERadius } from "../../Radius";
import { EPixel } from "../../spacing";

const anchorRadiusMapping: { [key: string]: string } = {
  left: `0 ${ERadius.r300} ${ERadius.r300} 0`,
  right: `${ERadius.r300} 0 0 ${ERadius.r300}`,
  top: `0 0 ${ERadius.r300} ${ERadius.r300}`,
  bottom: `${ERadius.r300} ${ERadius.r300} 0 0`,
};
export const MuiDrawer: {
  defaultProps?: ComponentsProps["MuiDrawer"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiDrawer"];
  variants?: ComponentsVariants<Theme>["MuiDrawer"];
} = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      const { anchor = "right" } = ownerState as DrawerProps;
      return {
        [`& > .MuiDrawer-paper`]: {
          borderRadius: anchorRadiusMapping[anchor],
          padding: `${EPixel.px40} ${EPixel.px48}`,
        },
      };
    },
  },
};
