import * as React from "react";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

interface ListViewProps {
  children: React.ReactNode[];
}

const ListView = (props: ListViewProps) => {
  const { children } = props;

  if (!children) {
    return null;
  }

  return (
    <Grid container spacing={3}>
      {children.map((node) => (
        <Grid item sm={12} md={4}>
          {node}
        </Grid>
      ))}
    </Grid>
  );
};

export { ListView };
