import { clsx } from "clsx";
import { fill, get } from "lodash";
import {
  Children,
  ForwardedRef,
  ReactElement,
  ReactNode,
  cloneElement,
  forwardRef,
  isValidElement,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Control, FieldError, FieldValues, Path, PathValue } from "react-hook-form";

import {
  AdditionalBaseFieldInfo,
  AppContext,
  Box,
  ErrorLabel,
  I18nStr,
  Text,
  TextSize,
  Tooltip,
  getInnerText,
  useController,
  useFormContext,
  useFormState,
  useMediaQueries,
} from "@hkexpressairwayslimited/ui/src";
import { BaseFieldInput, BaseFieldInputProps, FormFieldProps, flattenAllErrors } from "./BaseFieldInput";

import toolTipClasses from "../Tooltip/Tooltip.module.scss";
import classes from "./FieldInputGroup.module.scss";

export enum FieldInputGroupVariant {
  Basic = "Basic",
}

export type FieldInputGroupProps<TFieldValues extends FieldValues = FieldValues> = {
  className?: string;
  variant?: FieldInputGroupVariant;
  children?: ReactElement<Partial<FormFieldProps>> | ReactElement<Partial<FormFieldProps>>[];
  name?: Path<TFieldValues>;
  names?: Path<TFieldValues>[];
  helperText?: I18nStr | I18nStr[];
  fullWidth?: boolean;
  tooltip?: I18nStr;
  showToolTip?: boolean;
  divider?: boolean | ReactNode;
  dividers?: ReactNode[];
  customFormGroup?: boolean;
  vertical?: boolean;
  disconnected?: boolean;
  onProcessValue?: (
    value: PathValue<TFieldValues, Path<TFieldValues>> | undefined,
    info: AdditionalBaseFieldInfo<TFieldValues>
  ) => PathValue<TFieldValues, Path<TFieldValues>>;
  onValueChange?: (info: AdditionalBaseFieldInfo<TFieldValues>, ...event: any[]) => any;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean | boolean[];
  "aria-label"?: string;
  hideErrorMessage?: boolean;
};

type GroupSubFieldProps<TFieldValues extends FieldValues> = {
  name?: Path<TFieldValues>;
  helperText?: string | string[] | JSX.Element | JSX.Element[];
  hasError?: boolean;
  divider?: ReactNode;
  children: ReactElement<BaseFieldInputProps<TFieldValues, false> & FormFieldProps<TFieldValues>>;
  control?: Control<TFieldValues, TFieldValues>;
  onProcessValue?: (
    value: PathValue<TFieldValues, Path<TFieldValues>> | undefined,
    info: AdditionalBaseFieldInfo<TFieldValues>
  ) => PathValue<TFieldValues, Path<TFieldValues>>;
  onValueChange?: (info: AdditionalBaseFieldInfo<TFieldValues>, ...event: any[]) => any;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  "aria-label"?: string;
  tooltip?: I18nStr;
  showToolTip?: boolean;
};

export type GroupSubFieldHandle = {
  sharpenBottom: {
    set: (config?: { left?: boolean; right?: boolean }) => void;
    reset: () => void;
  };
};

const GroupSubField = <TFieldValues extends FieldValues>({
  name = "" as Path<TFieldValues>,
  helperText,
  hasError,
  divider,
  children,
  control,
  onProcessValue,
  onValueChange,
  onFocus,
  onBlur,
  disabled,
  tooltip,
  showToolTip,
  "aria-label": ariaLabel,
}: GroupSubFieldProps<TFieldValues>) => {
  const { field } = useController<TFieldValues>({ name: name as Path<TFieldValues>, control });
  const [focused, setFocused] = useState(false);
  const groupSubFieldHandle = useRef<GroupSubFieldHandle>();
  const [sharpenBottom, setSharpenBottom] = useState({ left: false, right: false });
  const { isInEditor } = useContext(AppContext);

  useImperativeHandle(
    groupSubFieldHandle,
    () => ({
      sharpenBottom: {
        set: (config) => {
          setSharpenBottom({ ...{ left: true, right: true }, ...config });
        },
        reset: () => {
          setSharpenBottom({ left: false, right: false });
        },
      },
    }),
    []
  );
  const finalAriaLabel = useMemo(
    () => ariaLabel || (typeof helperText === "string" ? helperText : getInnerText(children)),
    [ariaLabel, helperText]
  );

  return (
    <div className={classes.fieldInputGroup_groupCell}>
      <Box
        className={clsx(classes.fieldInputGroup_groupField, {
          [classes.fieldInputGroup_groupField__hasValue]:
            field?.value || children.props?.value || children.props?.children?.props?.value,
          [classes.fieldInputGroup_groupField__focused]: focused,
          [classes.fieldInputGroup_groupField__error]: hasError,
          [classes.fieldInputGroup_groupField__isInEditor]: isInEditor,
          [classes.fieldInputGroup_groupField__verticalLine]: divider === FieldInputDividers.VerticalLine,
          [classes.fieldInputGroup_groupField__sharpenBottomLeft]: sharpenBottom.left,
          [classes.fieldInputGroup_groupField__sharpenBottomRight]: sharpenBottom.right,
          [classes.fieldInputGroup_groupField__disabled]: disabled,
        })}
        onFocus={(event) => {
          if (disabled) return;
          setFocused(true);
          onFocus?.();
          event.stopPropagation();
        }}
        onBlur={() => {
          setFocused(false);
        }}
      >
        {tooltip && showToolTip ? (
          <Tooltip arrow className={toolTipClasses.tooltip_field} variant='purple'>
            {tooltip}
          </Tooltip>
        ) : (
          <></>
        )}
        <Text size={TextSize.Body5} className={classes.fieldInputGroup_groupFieldHelper}>
          {helperText}
        </Text>
        <Box className={classes.fieldInputGroup_formChild}>
          {cloneElement(children, {
            groupSubFieldHandle,
            onProcessValue,
            onValueChange,
            onBlur,
            disabled,
            "aria-label": finalAriaLabel,
          })}
        </Box>
        <div className={classes.fieldInputGroup_divider}>{divider}</div>
      </Box>
    </div>
  );
};

export enum FieldInputDividers {
  VerticalLine = "VerticalLine",
}

export const FieldInputGroupClasses = classes;

export const FieldInputGroup = forwardRef(
  <TFieldValues extends FieldValues>(
    {
      className,
      variant = FieldInputGroupVariant.Basic,
      children,
      name,
      names = name ? [name] : undefined,
      helperText,
      tooltip,
      showToolTip = false,
      vertical,
      divider,
      dividers = divider
        ? isValidElement(divider)
          ? fill(Array(Children.count(children) - 1), divider)
          : fill(Array(Children.count(children) - 1), FieldInputDividers.VerticalLine)
        : undefined,
      fullWidth,
      customFormGroup,
      disconnected,
      onProcessValue,
      onValueChange,
      onFocus,
      onBlur,
      disabled = false,
      hideErrorMessage = false,
    }: FieldInputGroupProps<TFieldValues>,
    ref?: ForwardedRef<HTMLDivElement>
  ) => {
    const disabledArray = Array.isArray(disabled) ? disabled : fill(Array(Children.count(children)), disabled);
    const context = useFormContext<TFieldValues>();
    const control = disconnected ? context?.control : undefined;
    const { isMobile } = useMediaQueries();
    const formChildren = children
      ? Children.map(children, (child, index) => {
          const name = names ? names[index] : undefined;

          if (isValidElement<Partial<FormFieldProps>>(child)) {
            if (name) {
              return (
                <BaseFieldInput index={index} name={name} key={name}>
                  {child}
                </BaseFieldInput>
              );
            } else {
              return child;
            }
          }
        }).filter((child): child is ReactElement<BaseFieldInputProps<TFieldValues, false>> => Boolean(child))
      : [];
    const { errors } = useFormState<TFieldValues>({ name: names, control });
    const fieldErrors = names?.map((name) => get(errors, name)) as FieldError[];
    const fields = formChildren.map((child, index) => {
      const name = names ? names[index] : undefined;
      const fieldError = fieldErrors ? fieldErrors[index] : undefined;
      const helper = helperText
        ? Array.isArray(helperText)
          ? helperText[index]
          : index === 0
            ? helperText
            : undefined
        : undefined;
      const disabled = disabledArray ? disabledArray[index] : false;
      const divider = dividers && index !== formChildren.length ? dividers[index] : undefined;

      return (
        <GroupSubField<TFieldValues>
          name={name}
          helperText={helper}
          hasError={Boolean(fieldError)}
          divider={divider}
          control={control}
          key={name}
          onProcessValue={onProcessValue}
          onValueChange={onValueChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          tooltip={tooltip}
          showToolTip={showToolTip}
        >
          {child}
        </GroupSubField>
      );
    });
    const fieldErrorList = formChildren.map((child, index) => {
      const name = names ? names[index] : undefined;
      const fieldError = fieldErrors ? fieldErrors[index] : undefined;
      const errorList = flattenAllErrors(fieldError);

      return (
        <div key={name}>
          {errorList &&
            errorList.map((error, index) => (
              <ErrorLabel className={classes.fieldInputGroup_errorLabel}>{error.message}</ErrorLabel>
            ))}
        </div>
      );
    });
    const inputElm = (
      <div
        className={clsx(classes.fieldInputGroup, className, {
          [classes.fieldInputGroup__basic]: variant === FieldInputGroupVariant.Basic,
          [classes.fieldInputGroup__fullWidth]: fullWidth,
          [classes.fieldInputGroup__horizontal]: !vertical,
          [classes.fieldInputGroup__vertical]: vertical,
        })}
        ref={ref}
        // tabIndex={disabled ? -1 : undefined}
      >
        <div className={clsx(classes.fieldInputGroup_group)}>
          <div className={clsx(classes.fieldInputGroup_groupRow, classes.fieldInputGroup_fieldList)}>
            {customFormGroup ? children : fields}
          </div>
          {!hideErrorMessage && <div className={clsx(classes.fieldInputGroup_groupRow)}>{fieldErrorList}</div>}
        </div>
      </div>
    );
    // return isMobile ? inputElm : <FormLabel>{inputElm}</FormLabel>;
    return inputElm;
  }
);
FieldInputGroup.displayName = "FieldInputGroup";
