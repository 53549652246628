/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiTextField from "@mui/material/TextField";

export interface MuiTextFieldProps {}
const StyledTextField = styled(MuiTextField)<MuiTextFieldProps>(({}) => ({}));

export interface TextFieldProps {
  id: string;
  label: string | JSX.Element | JSX.Element[];
  defaultValue: string;
  value?: string;
  isError?: boolean;
  helperText?: string | JSX.Element | JSX.Element[];
  onChange?: Function;
}
const TextField = (props: TextFieldProps) => (
  <StyledTextField
    variant='standard'
    fullWidth
    error={props.isError}
    helperText={props.helperText}
    id={props.id}
    label={props.label}
    defaultValue={props.defaultValue}
    value={props.value}
    onChange={(e) => props.onChange && props.onChange(e)}
  />
);

export { TextField };
