import {
  useFormState as useHookFormState,
  UseFormStateProps,
  UseFormStateReturn,
  FieldValues,
  useFormContext,
} from "react-hook-form";

export const useFormState = <TFieldValues extends FieldValues = FieldValues>(
  props?: UseFormStateProps<TFieldValues>
): Partial<UseFormStateReturn<TFieldValues>> => {
  const formContext = useFormContext<TFieldValues>();

  if (!formContext) return {};

  return useHookFormState<TFieldValues>(props);
};
