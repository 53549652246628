export enum EPaxType {
  Adult = "Adult",
  Child = "Child",
  Infant = "Infant",
}
export enum ECourtesy {
  Mr = "Mr",
  Mrs = "Mrs",
  Ms = "Ms",
  Master = "Master",
  Miss = "Miss",
}

export enum ETripType {
  oneWay = "oneWay",
  roundTrip = "roundTrip",
  return = "RETURN",
  multiCity = "multiCity",
}
export interface Trip {
  fromMarket?: string;
  toMarket?: string;
  from: string;
  to: string;
  date: string;
}

export type PassengerCount = {
  [K in EPaxType]: number;
};

export type PaxTypeI18n = { [k in EPaxType]: string };

export interface Station {
  country: string;
  name: string;
  sortName: string;
  airportName: string;
}
export interface Pax {
  id: string;
  paxType?: EPaxType;
  courtesy?: ECourtesy;
  surname: string;
  givenName: string;
  DOB: string;
  docExpirationDate?: string;
  docIssuingCountry?: string;
  docNationality?: string;
  docNumber?: string;
  docType?: string;
  ownExitRowSeat?: boolean;
  associated_adult_passenger_key?: string;
  country?: string;
}
export type PaxDocField = {
  docType: string;
  docIssuingCountry: string;
  docNationality: string;
  docNumber: string;
  docExpirationDate: string;
};
export type PaxContactField = {
  email?: string;
  mobile?: MobileField | null;
};

export type MobileField = {
  countryCode: string;
  mobileNumber: string;
};

export type ContactInfoField = {
  contactsCourtesy: ECourtesy | string;
  contactsSurname: string;
  contactsGivenName: string;
  contactsMobile: MobileField;
  contactsEmail: string;
  contactsDob?: string;
};
export type ContactAbleFiled = { primaryContact?: boolean };

export type IBEToChinaPax = Pax & ContactAbleFiled & PaxDocField;
export type IBENoToChinaPax = Pax & ContactAbleFiled;
export type IBEPax<C = undefined> = C extends undefined ? IBENoToChinaPax : IBEToChinaPax;
export type IBEPaxDetailExtraField = {
  receiveMarketingAndPromotional: boolean;
  rememberThisDetails: boolean;
  sendSMSitinerary: boolean;
  signUpNow: boolean;
};
export interface IBEPassengerDetail<C = undefined> extends Partial<IBEPaxDetailExtraField>, ContactInfoField {
  passengers: IBEPax<C>[];
}

export type MMBPax = Pax & PaxDocField & PaxContactField & ContactAbleFiled;
export interface MMBPassengersDetail extends Partial<ContactInfoField> {
  passengers: MMBPax[];
  isGDS?: boolean;
}

export type OLCIPax = Pax & PaxDocField;

export interface SearchingDetail {
  tripType: ETripType;
  trips: Trip[];
  passenger_count: PassengerCount;
  promoCode?: string;
  campaignCode?: string;
}

export interface IContactMobile {
  countryCode: string;
  mobileNumber: string;
  country?: string;
}

export interface OLCIPassengersDetails {
  passengers: OLCIPax[];
}

export type SeatType = {
  type: string;
  price?: number;
};

export type Seat = {
  section: string;
  type: string;
  id: string;
  availability: boolean;
};

export type SeatPriceGroup = {
  price: number;
  passenger_key: string;
  passenger_identifier_number: number;
  seat_group: number;
  seat_type: string;
};

export type SeatMatrix = {
  unit_designator: string;
  unit_key: string;
  seat_availability: string;
  is_prm_allowed: boolean;
  is_exit_row: boolean;
  has_extra_leg: boolean;
  is_infant_allowed: boolean;
  is_child_allowed: boolean;
  is_next_to_aisle: boolean;
  seat_group: number;
  row: number;
  column: string;
  sector: string;
};

export type SeatCabin = {
  carrier_type: string;
  price_groups: SeatPriceGroup[];
  seat_matrix: (SeatMatrix | null)[][];
};

export type SeatMap = {
  cabin: SeatCabin;
  segment_id: string;
};

type SSRByPassenger = {
  ssr_key: string;
  price: number;
  passenger_key: string;
};

export type ArItem = {
  // for arItem available, selected and purchased
  type: string;
  code: string;
  price?: number;
  spoilage?: number;
  currency_code?: string;
  availability?: number | null;
  cms_content_key?: string;
  ssr_by_passengers?: SSRByPassenger[];
  is_pre_order?: boolean;
  is_vegetarian?: boolean;
  byPax?: { paxId: string; amount: number; price?: number }[];
  arMetaData?: Record<string | " included", string | boolean | number>;
  unit_key?: string;
  is_exit_row?: boolean;
  limit_per_passenger?: number;
  seatType?: string;
};

export interface Duration {
  hour: number;
  minute?: number;
}

export type ArItems = {
  available: ArItem[];
  selected: ArItem[];
  purchased: ArItem[];
};

export type Segment = {
  segment_key: string;
  flight_number: string;
  destination: string;
  origin: string;
  arrival: string;
  arrival_time: string;
  arrival_date: string;
  departure: string;
  departure_time: string;
  departure_date: string;
  arrival_timezone: string;
  departure_timezone: string;
  origin_airport_terminal: string;
  destination_airport_terminal: string;
  duration: Duration;
  is_ferry: boolean;
  legs: Leg[];
  seatMap?: SeatMap;
  arItems: ArItems;
};

export interface OptionFlightDetail {
  key: string;
  value: string;
}

export type Leg = {
  leg_key: string;
};

export type BundleOffer = {
  bundle_code: string;
  adult_amount: number;
  child_amount: number;
  currency_code: string;
  type: string;
  bundles: BundleSsr[];
  selected?: boolean;
};

export type BundleSsr = {
  ssr_code: string;
  cms_key: string;
};
export interface FareDetail {
  service_charge: ServiceCharge[];
  available?: number; //Infant only
}

export interface Fare {
  fare_availability_key: string;
  currency_code: string;
  adult_fare_details: FareDetail;
  child_fare_details: FareDetail | null;
  infant_details: FareDetail | null;
  bundle_offers: BundleOffer[];
}
export interface ServiceCharge {
  type: string;
  code: string | null;
  currency_code: string;
  description: string | null;
  amount: number;
}

export type Journey = {
  index?: number;
  tripOrigin?: string;
  tripDestination?: string;
  arrival_date_time: string;
  departure_date_time: string;
  journey_key: string;
  original_journey_key?: string;
  fare: Fare;
  origin: string;
  destination: string;
  date: string;
  duration: Duration;
  segments: Segment[];
  is_promoted?: boolean;
  display_item_amount: number;
  flight_time: string;
  day_diff?: number;
  arItems: ArItems;
};

export interface TripDetail {
  id: string;
  index?: number;
  salesReference?: string;
  journeys: Journey[];
  arItems: ArItems;
}
