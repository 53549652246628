import {
  Box,
  Button,
  ESpacing,
  FieldInputGroup,
  Font,
  Form,
  FormProps,
  Grid,
  ModalHandle,
  Text,
  TextInput,
  TextSize,
  useForm,
} from "@hkexpressairwayslimited/ui/src";
import { useEffect, useState } from "react";
import * as yup from "yup";

import classes from "./Membership.module.scss";
enum Fields {
  OriginalPassword = "originalPassword",
  NewPassword = "newPassword",
  ConfirmPassword = "confirmPassword",
}
const defaultValues = {
  [Fields.OriginalPassword]: "" as string | undefined,
  [Fields.NewPassword]: "",
  [Fields.ConfirmPassword]: "",
};
const SetPassword = (args: FormProps & { i18nContent: any; isDisplayOriginPasswordField: boolean }) => {
  const [characterLength, setCharacterLength] = useState<boolean>(false);
  const [specialCharacter, setSpecialCharacter] = useState<boolean>(false);
  const [upperCaseCharacter, setUpperCaseCharacter] = useState<boolean>(false);
  const [lowerCaseCharacter, setLowerCaseCharacter] = useState<boolean>(false);
  const [numericalDigit, setNumericalDigit] = useState<boolean>(false);
  const [noSpace, setNoSpace] = useState<boolean>(false);
  const [noPeriod, setPeriod] = useState<boolean>(false);
  const [isShowValidationWording, setIsShowValidationWording] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<ModalHandle | null>();
  const isB2BResetPWDFlow = Boolean(args.isDisplayOriginPasswordField);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState<boolean>(isB2BResetPWDFlow ? true : false);

  const setAndCheckIsValidPassword = (value: string) => {
    const characterLengthTest = !/^.{8,15}$/.test(value);
    const specialCharacterTest = !/[^a-zA-Z\d,.~]+/.test(value);
    const upperCaseCharacterTest = !/^(?=.*?[A-Z])/.test(value);
    const lowerCaseCharacterTest = !/^(?=.*?[a-z])/.test(value);
    const numericalDigitTest = !/^(?=.*?\d)/.test(value);
    const noSpaceTest = !/\s/.test(value);
    const noPeriodTest = !/^(?=.*[,.~])/.test(value);

    characterLengthTest ? setCharacterLength(false) : setCharacterLength(true);
    specialCharacterTest ? setSpecialCharacter(false) : setSpecialCharacter(true);
    upperCaseCharacterTest ? setUpperCaseCharacter(false) : setUpperCaseCharacter(true);
    lowerCaseCharacterTest ? setLowerCaseCharacter(false) : setLowerCaseCharacter(true);
    numericalDigitTest ? setNumericalDigit(false) : setNumericalDigit(true);
    noSpaceTest ? setNoSpace(true) : setNoSpace(false);
    noPeriodTest ? setPeriod(true) : setPeriod(false);

    // !/^.{8,15}$/.test(value) ? setCharacterLength(false) : setCharacterLength(true);
    // !/[^a-zA-Z\d,.~]+/.test(value) ? setSpecialCharacter(false) : setSpecialCharacter(true);
    // !/^(?=.*?[A-Z])/.test(value) ? setUpperCaseCharacter(false) : setUpperCaseCharacter(true);
    // !/^(?=.*?[a-z])/.test(value) ? setLowerCaseCharacter(false) : setLowerCaseCharacter(true);
    // !/^(?=.*?\d)/.test(value) ? setNumericalDigit(false) : setNumericalDigit(true);
    // !/\s/.test(value) ? setNoSpace(true) : setNoSpace(false);
    // !/^(?=.*[,.~])/.test(value) ? setPeriod(true) : setPeriod(false);

    return (
      !characterLengthTest &&
      !specialCharacterTest &&
      !upperCaseCharacterTest &&
      !lowerCaseCharacterTest &&
      !numericalDigitTest &&
      noSpaceTest &&
      noPeriodTest
    );
  };

  const FormSchemas = {
    [Fields.OriginalPassword]: yup
      .string()
      .test("required in b2b", args.i18nContent?.originalPasswordField?.required, (value) => {
        return !args.isDisplayOriginPasswordField || Boolean(value);
      }),
    [Fields.NewPassword]: yup
      .string()
      .required(args.i18nContent.newPasswordField?.required)
      .test("follow-rules", args.i18nContent.newPasswordField?.noFollowRules, (value) => {
        return setAndCheckIsValidPassword(value);
      }),
    [Fields.ConfirmPassword]: yup
      .string()
      .required(args.i18nContent.confirmPasswordField?.required)
      .oneOf([yup.ref(Fields.NewPassword)], args.i18nContent?.errMsg),
  };

  type FormValues = typeof defaultValues; // Or define your own schema
  const formHook = useForm<FormValues>({
    defaultValues,
    schema: yup.object().shape(FormSchemas),
  });

  useEffect(() => {
    const subscription = formHook.watch((value, { name, type }) => {
      const isNewPasswordEmpty = !formHook.getValues(Fields.NewPassword);
      const isOriginalPasswordEmpty = !formHook.getValues(Fields.OriginalPassword);

      if (!isNewPasswordEmpty) {
        if (isB2BResetPWDFlow && isOriginalPasswordEmpty) {
          setIsShowValidationWording(false);
        } else {
          setIsShowValidationWording(true);
        }
      } else {
        setIsShowValidationWording(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [formHook, formHook.watch]);

  useEffect(() => {
    const isValid = formHook.formState.isValid;
    if (isB2BResetPWDFlow) {
      if (isValid) {
        setConfirmButtonDisabled(false);
      } else {
        setConfirmButtonDisabled(true);
      }
    } else {
      setConfirmButtonDisabled(false);
    }
  }, [formHook.formState]);

  const openModal = () => {
    modalOpen?.open();
  };

  const closeModal = () => {
    modalOpen?.close();
  };

  return (
    <>
      <Grid container>
        <Box className={classes.formStory}>
          <Form<FormValues>
            formHook={formHook}
            onSubmit={(formValues) => {
              return args.onSubmit ? args.onSubmit(formValues) : true;
            }}
          >
            <Grid>
              <Box className={classes.formStory_form_title}>{args.i18nContent?.title}</Box>
              <Box className={classes.formStory_form_content}>
                <Text size={TextSize.P1Regular}>{args.i18nContent?.text}</Text>

                <Box display={args.isDisplayOriginPasswordField ? "block" : "none"}>
                  <FieldInputGroup
                    helperText={args.i18nContent?.originalPasswordField?.helperText}
                    names={[Fields.OriginalPassword]}
                    fullWidth
                    onValueChange={(info, e) => {
                      formHook.setValue(Fields.OriginalPassword, e.target.value, { shouldValidate: true });
                    }}
                  >
                    <TextInput
                      fullWidth
                      placeholder={args.i18nContent?.originalPasswordField?.placeholder}
                      isPassword
                    />
                  </FieldInputGroup>
                </Box>

                <FieldInputGroup
                  helperText={args.i18nContent?.newPasswordField?.helperText}
                  names={[Fields.NewPassword]}
                  fullWidth
                  onValueChange={(info, e) => {
                    formHook.setValue(Fields.NewPassword, e.target.value, { shouldValidate: true });
                  }}
                >
                  <TextInput fullWidth placeholder={args.i18nContent?.newPasswordField?.placeholder} isPassword />
                </FieldInputGroup>
                <FieldInputGroup
                  helperText={args.i18nContent?.confirmPasswordField?.helperText}
                  names={[Fields.ConfirmPassword]}
                  fullWidth
                  onValueChange={(info, e) => {
                    formHook.setValue(Fields.ConfirmPassword, e.target.value, { shouldValidate: true });
                  }}
                >
                  <TextInput fullWidth placeholder={args.i18nContent?.confirmPasswordField?.placeholder} isPassword />
                </FieldInputGroup>

                <Button submit disabled={confirmButtonDisabled}>
                  {args.i18nContent?.submit}
                </Button>

                {isShowValidationWording ? (
                  <Text>
                    {args.i18nContent?.desc}
                    <Font
                      sx={{
                        color: characterLength ? "hke_success.default" : "critical.default",
                      }}
                    >
                      <Font mr={ESpacing._3xs}>{characterLength ? "✓" : "X"}</Font>{" "}
                      {args.i18nContent?.rules?.validation1}
                    </Font>
                    <Font
                      sx={{
                        color: specialCharacter ? "hke_success.default" : "critical.default",
                      }}
                    >
                      <Font mr={ESpacing._3xs}>{specialCharacter ? "✓" : "X"}</Font>
                      {args.i18nContent?.rules?.validation2}
                    </Font>
                    <Font
                      sx={{
                        color: upperCaseCharacter ? "hke_success.default" : "critical.default",
                      }}
                    >
                      <Font mr={ESpacing._3xs}>{upperCaseCharacter ? "✓" : "X"}</Font>{" "}
                      {args.i18nContent?.rules?.validation3}
                    </Font>
                    <Font
                      sx={{
                        color: lowerCaseCharacter ? "hke_success.default" : "critical.default",
                      }}
                    >
                      <Font mr={ESpacing._3xs}>{lowerCaseCharacter ? "✓" : "X"}</Font>{" "}
                      {args.i18nContent?.rules?.validation4}
                    </Font>
                    <Font
                      sx={{
                        color: numericalDigit ? "hke_success.default" : "critical.default",
                      }}
                    >
                      <Font mr={ESpacing._3xs}>{numericalDigit ? "✓" : "X"}</Font>{" "}
                      {args.i18nContent?.rules?.validation5}
                    </Font>
                    <Font
                      sx={{
                        color: noSpace ? "hke_success.default" : "critical.default",
                      }}
                    >
                      <Font mr={ESpacing._3xs}>{noSpace ? "✓" : "X"}</Font> {args.i18nContent?.rules?.validation6}
                    </Font>
                    <Font
                      sx={{
                        color: noPeriod ? "hke_success.default" : "critical.default",
                      }}
                    >
                      <Font mr={ESpacing._3xs}>{noPeriod ? "✓" : "X"}</Font> {args.i18nContent?.rules?.validation7}
                    </Font>
                  </Text>
                ) : null}
              </Box>
            </Grid>
          </Form>
        </Box>
      </Grid>
    </>
  );
};

export { SetPassword };
