import { AppContext, Box, DropdownOption, FormFieldProps } from "@hkexpressairwayslimited/ui/src";
import { getInnerText } from "@hkexpressairwayslimited/ui/src/utils/component";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Select as BaseSelect, SelectProps as BaseSelectProps, SelectPopupSlotProps } from "@mui/base/Select";
import { clsx } from "clsx";
import { ForwardedRef, ReactElement, ReactNode, forwardRef, useContext, useEffect, useState } from "react";
import classes from "./Dropdown.module.scss";
import { DropdownButton } from "./DropdownButton";
import { DropdownListbox } from "./DropdownListbox";
import { DropdownOptionGroup } from "./DropdownOptionGroup";

export enum SelectInputVariant {
  Primary = "Primary",
}

export interface SelectOption {
  label: ReactNode; // search input match unique standard
  value: string;
  displayLabel?: ReactNode | string;
}

export interface SelectGroup {
  groupLabel: string;
  options: SelectOption[];
}

export type SelectInputProps<OptionValue extends string, Multiple extends boolean> = {
  fullWidth?: boolean;
  placeholder?: string | JSX.Element | JSX.Element[];
  variant?: SelectInputVariant;
  isGrouping?: boolean;
  canSearch?: boolean;
  svgIcon?: ReactElement;
  options?: SelectOption[] | SelectGroup[];
  noDataLabel?: string;
  trianglePopupTitleLabel?: string;
  onChange?: (value: OptionValue) => void;
  onBlur?: (value: OptionValue) => void;
  hideArrow?: boolean;
  onDisplaySelection?: (option?: SelectOption) => ReactNode;
} & Partial<FormFieldProps> &
  Omit<BaseSelectProps<OptionValue, Multiple>, "onChange">;

const DropdownPopup = forwardRef(
  <OptionValue extends string, Multiple extends boolean>(
    {
      className,
      ownerState,
      isGrouping,
      isMounted,
      onHandleCloseBoxList,
      ...props
    }: { isMounted: boolean; isGrouping: boolean; onHandleCloseBoxList: () => void } & SelectPopupSlotProps<
      OptionValue,
      Multiple
    >,
    ref: ForwardedRef<HTMLElement>
  ) => {
    return isGrouping ? (
      <Box className={clsx(className, classes.select_group_popup, !isMounted && classes.hidden)} {...props} ref={ref} />
    ) : (
      <Box className={clsx(className, classes.select_popup, !isMounted && classes.hidden)} {...props} ref={ref} />
    );
  }
);
DropdownPopup.displayName = "DropdownPopup";

export const Dropdown = forwardRef(
  <OptionValue extends string, Multiple extends boolean = false>(
    {
      svgIcon,
      options,
      canSearch,
      isGrouping,
      trianglePopupTitleLabel,
      onDisplaySelection = (option) => option?.label,
      hideArrow = false,
      fullWidth = false,
      noDataLabel = "no data",
      error,
      isValidating,
      isDirty,
      invalid,
      isTouched,
      groupSubFieldHandle,
      ...props
    }: SelectInputProps<OptionValue, Multiple>,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const [open, setOpen] = useState(true);
    const [isMounted, setIsMounted] = useState(false);
    const [curOptions, setCurOptions] = useState(options);
    const { isInEditor } = useContext(AppContext);

    const onSearchInputChange = (value: string) => {
      if (canSearch) {
        if (value === "") {
          setCurOptions(options);
        } else if (isGrouping && options?.length) {
          const newOptions: any = [];
          for (let i = 0; i < options.length; i++) {
            if ((options[i] as SelectGroup).groupLabel?.toLowerCase().includes(value?.toLowerCase())) {
              newOptions.push(options[i]);
              continue;
            } else {
              const subNewOptions = (options[i] as SelectGroup)?.options?.filter((option) => {
                return getInnerText((option as SelectOption).label)
                  ?.toLowerCase()
                  .includes(value?.toLowerCase());
              });
              if (subNewOptions.length > 0) {
                newOptions.push({
                  groupLabel: (options[i] as SelectGroup)?.groupLabel,
                  options: subNewOptions,
                });
              }
            }
          }
          setCurOptions(newOptions as SelectGroup[]);
        } else {
          const newOptions = options?.filter((option) =>
            getInnerText((option as SelectOption).label)
              ?.toLowerCase()
              .includes(value?.toLowerCase())
          );
          setCurOptions(newOptions as SelectOption[]);
        }
      }
    };

    const onHandleCloseBoxList = () => {
      if (open) {
        setOpen(false);
      }
    };

    const onHandleOpenBoxList = () => {
      if (!open) {
        setCurOptions(options);
        setOpen(true);
      }
    };

    const slots: SelectInputProps<OptionValue, Multiple>["slots"] = {
      root: DropdownButton,
      listbox: DropdownListbox,
      popup: DropdownPopup,
      ...props.slots,
    };
    const slotProps = {
      popup: { disablePortal: true, isGrouping, isMounted },
      root: {
        open,
        svgIcon,
        canSearch,
        isGrouping,
        value: props.value,
        disabled: props.disabled,
        options,
        isMounted,
        onSearchInputChange,
        onHandleOpenBoxList,
        onDisplaySelection,
        onHandleCloseBoxList,
        hideArrow,
      },
      listbox: { trianglePopupTitleLabel, isGrouping, open, closeListBox: onHandleCloseBoxList },
    };

    const handleListboxOpenChange = (isOpen: boolean) => {
      setOpen(isOpen);
    };

    useEffect(() => {
      setTimeout(() => {
        setOpen(false);
        setIsMounted(true);
      });
    }, []);

    useEffect(() => {
      if (!isGrouping) {
        if (open) {
          groupSubFieldHandle?.current?.sharpenBottom.set();
        } else {
          groupSubFieldHandle?.current?.sharpenBottom.reset();
        }
      }
    }, [open]);

    useEffect(() => {
      setCurOptions(options);
    }, [options]);

    return (
      <ClickAwayListener onClickAway={!isInEditor ? onHandleCloseBoxList : () => {}}>
        {options ? (
          <div
            className={clsx(classes.select, {
              [classes.select__fullWidth]: fullWidth,
              [classes.select__separateField]: !groupSubFieldHandle,
            })}
            tabIndex={props.disabled ? -1 : undefined}
          >
            <BaseSelect
              {...props}
              onChange={(_, newValue) => {
                props.onChange?.(newValue as OptionValue);
                newValue && props.onBlur?.(newValue as OptionValue);
              }}
              slots={slots}
              slotProps={slotProps}
              listboxOpen={open}
              onListboxOpenChange={handleListboxOpenChange}
              ref={ref}
            >
              {curOptions?.length && curOptions?.length > 0 ? (
                curOptions.map((option, index: number) => {
                  return isGrouping ? (
                    <DropdownOptionGroup key={index} label={(option as SelectGroup).groupLabel}>
                      {(option as SelectGroup).options?.map((groupOption, optionIndex) => (
                        <DropdownOption isGrouping key={optionIndex} value={groupOption.value}>
                          {(groupOption as SelectOption)?.displayLabel || (groupOption as SelectOption)?.label}
                        </DropdownOption>
                      ))}
                    </DropdownOptionGroup>
                  ) : (
                    <DropdownOption key={index} value={(option as SelectOption).value}>
                      {(option as SelectOption)?.displayLabel || (option as SelectOption).label}
                    </DropdownOption>
                  );
                })
              ) : (
                <Box className={classes.noData}>{noDataLabel}</Box>
              )}
            </BaseSelect>
          </div>
        ) : (
          <Box className={classes.select} tabIndex={props.disabled ? -1 : undefined}>
            <BaseSelect
              {...props}
              onChange={(_, newValue) => {
                props.onChange?.(newValue as OptionValue);
                newValue && props.onBlur?.(newValue as OptionValue);
              }}
              slots={slots}
              slotProps={slotProps}
              ref={ref}
            />
          </Box>
        )}
      </ClickAwayListener>
    );
  }
);
Dropdown.displayName = "Dropdown";
