import { ERadius } from "@hkexpressairwayslimited/ui/src";
import { Box, styled } from "@mui/material";

export const NonTickedIcon = styled(Box)(({ theme }) => ({
  borderRadius: ERadius.r100,
  width: 24,
  height: 24,
  border: "1px solid #4D4D4D",
  "input:hover ~ &": {
    border: `1px solid ${theme.palette.purple.default}`,
  },
  "input:disabled ~ &": {
    border: "1px solid #E5E5E5 !important",
    backgroundColor: "#E5E5E5",
  },
}));
