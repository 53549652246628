import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  ButtonVariant,
  Popper,
  PopperHandle,
  PopperProps,
} from "@hkexpressairwayslimited/ui/src";
import clsx from "clsx";
import { forwardRef } from "react";

import classes from "./ButtonMenu.module.scss";

export type ButtonMenuProps = {
  className?: string;
  children?: React.ReactNode;
  dividers?: boolean;
  popperProps?: Omit<PopperProps, "children">;
  header?: React.ReactNode;
} & Omit<BoxProps, "ref">;

export const ButtonMenu = forwardRef<PopperHandle, ButtonMenuProps>(
  ({ header, className, children, dividers, popperProps = {}, ...others }, ref) => {
    return (
      <Popper baseUi focusOutClose {...popperProps} ref={ref}>
        <Box {...others} className={clsx(classes.buttonMenu, { [classes.buttonMenu__dividers]: dividers }, className)}>
          {header && (
            <Box className={clsx(classes.buttonMenu_header, className)} {...others}>
              {header}
            </Box>
          )}
          {children}
        </Box>
      </Popper>
    );
  }
);
ButtonMenu.displayName = "ButtonMenu";

export type ButtonMenuItemProps = { children?: React.ReactNode } & ButtonProps;

export const ButtonMenuItem = forwardRef<HTMLButtonElement, ButtonMenuItemProps>(
  ({ children, className, ...others }, ref) => {
    return (
      children && (
        <Button className={clsx(classes.buttonMenu_item, className)} variant={ButtonVariant.Text} {...others} ref={ref}>
          {children}
        </Button>
      )
    );
  }
);
ButtonMenuItem.displayName = "ButtonMenuItem";
