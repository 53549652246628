import { Slider as BaseSlider, SliderProps as BaseSliderProps } from "@mui/base/Slider";
import { clsx } from "clsx";
import { forwardRef, useEffect, useState } from "react";

import { FormFieldProps } from "@hkexpressairwayslimited/ui/src";

import classes from "./Slider.module.scss";

export enum SliderVariant {
  Primary = "Primary",
}

export type SliderProps = {
  variant?: SliderVariant;
  uncoloredTrack?: boolean;
} & Partial<Omit<FormFieldProps, "value">> &
  Omit<BaseSliderProps, "onChange">;

export const Slider = forwardRef<HTMLInputElement, SliderProps>(
  (
    {
      value,
      className,
      variant = SliderVariant.Primary,
      uncoloredTrack,
      onChange,
      error,
      isValidating,
      isDirty,
      invalid,
      isTouched,
      groupSubFieldHandle,
      ...others
    },
    ref
  ) => {
    const [formValue, setFormValue] = useState(value);

    const handleValueChange = (value: number | readonly number[] | undefined) => {
      setFormValue(value);
    };

    const handleChange = (event: Event, value: number | number[]) => {
      handleValueChange(value);
      if (onChange) {
        onChange(value);
      }
    };

    useEffect(() => {
      handleValueChange(value);
    }, [value]);

    return (
      <BaseSlider
        value={formValue}
        slotProps={{
          root: {
            className: clsx(classes.slider, className, {
              [classes.slider__uncoloredTrack]: uncoloredTrack,
            }),
          },
          rail: { className: classes.slider_rail },
          track: { className: classes.slider_track },
          thumb: { className: classes.slider_thumb },
        }}
        onChange={handleChange}
        {...others}
      />
    );
  }
);
Slider.displayName = "Slider";
