import dayjs from "dayjs";
import Cookies from "js-cookie";
import { ETripType, SearchingDetail } from "../components/Organisms/FlightBooking/definition";
import { getCookieDomain } from "./router";

export const convertSearchParams = (data: SearchingDetail & { currency?: string }) => {
  const searchParams = new URLSearchParams({
    SearchType: data.tripType,
  });

  if (data.promoCode) {
    searchParams.set("PromotionCode", data.promoCode);
  }
  if (data.campaignCode) {
    searchParams.set("CampaignCode", data.campaignCode);
  }
  if (data.passenger_count.Adult) {
    searchParams.set("Adults", data.passenger_count.Adult.toString());
  }
  if (data.passenger_count.Child) {
    searchParams.set("Children", data.passenger_count.Child.toString());
  }
  if (data.passenger_count.Infant) {
    searchParams.set("Infants", data.passenger_count.Infant.toString());
  }
  if (data.currency) {
    searchParams.set("currency", data.currency);
  }
  if (data.tripType === ETripType.multiCity) {
    const origin = data.trips[0];
    const destination = data.trips[1];

    searchParams.set("DepartureDate", dayjs(origin.date).format("DD/MM/YYYY"));
    searchParams.set("ReturnDate", dayjs(destination.date).format("DD/MM/YYYY"));
    searchParams.set(`multioriginstation1`, origin.from);
    searchParams.set(`multidestinationstation1`, origin.to);
    searchParams.set(`multioriginstation2`, destination.from);
    searchParams.set(`multidestinationstation2`, destination.to);
  } else if (data.tripType === ETripType.roundTrip) {
    const origin = data.trips[0];
    const destination = data.trips[1];

    searchParams.set("OriginStation", origin.from);
    searchParams.set("DestinationStation", destination.from);
    searchParams.set("DepartureDate", dayjs(origin.date).format("DD/MM/YYYY"));
    searchParams.set("ReturnDate", dayjs(destination.date).format("DD/MM/YYYY"));
  } else {
    const origin = data.trips[0];

    searchParams.set("OriginStation", origin.from);
    searchParams.set("DestinationStation", origin.to);
    searchParams.set("DepartureDate", dayjs(origin.date).format("DD/MM/YYYY"));
  }

  return searchParams;
};

export const parseSearchDetail = (searchParams: URLSearchParams): SearchingDetail & { currency?: string } => {
  let tripType = searchParams.get("SearchType") as ETripType;
  const trips = [];

  const departureDate = searchParams.get("DepartureDate");
  const formattedDepartureDate =
    departureDate !== null
      ? dayjs(departureDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3$2$1"), "YYYY-MM-DD").toISOString()
      : "";
  const returnDate = searchParams.get("ReturnDate");
  const formattedReturnDate =
    returnDate !== null
      ? dayjs(returnDate.replace(/(\d\d)\/(\d\d)\/(\d{4})/, "$3$2$1"), "YYYY-MM-DD").toISOString()
      : "";

  if (tripType === ETripType.multiCity || tripType === ETripType.multiCity.toUpperCase()) {
    tripType = ETripType.multiCity;
    trips.push({
      from: searchParams.get("multioriginstation1") || "",
      to: searchParams.get("multidestinationstation1") || "",
      date: formattedDepartureDate || "",
    });
    trips.push({
      from: searchParams.get("multioriginstation2") || "",
      to: searchParams.get("multidestinationstation2") || "",
      date: formattedReturnDate,
    });
  } else if (tripType === ETripType.roundTrip || tripType === ETripType.return) {
    tripType = ETripType.roundTrip;
    trips.push({
      from: searchParams.get("OriginStation") || "",
      to: searchParams.get("DestinationStation") || "",
      date: formattedDepartureDate || "",
    });
    trips.push({
      from: searchParams.get("DestinationStation") || "",
      to: searchParams.get("OriginStation") || "",
      date: formattedReturnDate || "",
    });
  } else {
    tripType = ETripType.oneWay;
    trips.push({
      from: searchParams.get("OriginStation") || "",
      to: searchParams.get("DestinationStation") || "",
      date: formattedDepartureDate || "",
    });
  }
  const promoCode = searchParams.get("PromotionCode") ?? undefined;
  const campaignCode = searchParams.get("CampaignCode") ?? undefined;
  const currency = searchParams.get("currency") ?? undefined;

  return {
    tripType,
    promoCode,
    campaignCode,
    passenger_count: {
      Adult: parseInt(searchParams.get("Adults") || "0"),
      Child: parseInt(searchParams.get("Children") || "0"),
      Infant: parseInt(searchParams.get("Infants") || "0"),
    },
    currency,
    trips,
  };
};

export type TokenProfile = {
  email_verified: boolean;
  birthdate: string;
  given_name: string;
  family_name: string;
  phone_number_verified: boolean;
  phone_number: string;
  email: string;
  title: string;
  travel_document: string;
  receive_e_news: string;
  receive_marketing: string;
  update_required: string;
};

export enum AuthProvider {
  CathayMember = "Cathay-Member",
}

export type IdTokenPayload = {
  sub: string;
  email_verified: boolean;
  birthdate: string;
  given_name: string;
  family_name: string;
  phone_number_verified: boolean;
  phone_number: string;
  email: string;
  iss: string;
  aud: string;
  auth_time: number;
  exp: number;
  iat: number;
  jti: string;
  origin_jti: string;
  event_id: string;
  token_use: string;
  cognito_username: string;
  "custom:title": string;
  "custom:travel_document": string;
  "custom:receive_e_news": string;
  "custom:receive_marketing": string;
  "custom:update_required": string;
  identities?: {
    dateCreated: string;
    userId: string;
    providerName: AuthProvider;
    providerType: string;
    issuer: string;
    primary: string;
  }[];
};

export type MlcAccessTokenPayload = {
  "custom:title": string;
  at_hash: string;
  sub: string;
  email_verified: boolean;
  birthdate: string;
  iss: string;
  "custom:receive_marketing": string;
  "custom:update_required": string;
  "cognito:username": string;
  given_name: string;
  nonce: string;
  origin_jti: string;
  aud: string;
  identities?: {
    dateCreated: string;
    userId: string;
    providerName: AuthProvider;
    providerType: string;
    issuer: string;
    primary: string;
  }[];
  token_use: string;
  auth_time: number;
  exp: number;
  iat: number;
  family_name: string;
  jti: string;
  email: string;
};

export type MlcTokenProfile = {
  membership_number?: string;
  title: string;
  email_verified: boolean;
  birthdate: string;
  receive_marketing: string;
  update_required: string;
  cognito_username: string;
  given_name: string;
  family_name: string;
  email: string;
};

export function getIdToken(): string {
  return Cookies.get("id_token") || "";
}

export function clearAuthTokens() {
  Cookies.remove("access_token", { path: "/", domain: getCookieDomain() });
  Cookies.remove("refresh_token", { path: "/", domain: getCookieDomain() });
  Cookies.remove("id_token", { path: "/", domain: getCookieDomain() });
  Cookies.remove("mlc_access_token", { path: "/", domain: getCookieDomain() });
}

export const decodeTokenProfile = (tokenPayload: IdTokenPayload): TokenProfile => {
  return {
    email_verified: tokenPayload.email_verified,
    birthdate: tokenPayload.birthdate,
    given_name: tokenPayload.given_name,
    family_name: tokenPayload.family_name,
    phone_number_verified: tokenPayload.phone_number_verified,
    phone_number: tokenPayload.phone_number,
    email: tokenPayload.email,
    title: tokenPayload["custom:title"],
    travel_document: tokenPayload["custom:travel_document"],
    receive_e_news: tokenPayload["custom:receive_e_news"],
    receive_marketing: tokenPayload["custom:receive_marketing"],
    update_required: tokenPayload["custom:update_required"],
  };
};

// export const decodeMlcTokenProfile = (tokenPayload: MlcAccessTokenPayload): MlcTokenProfile => {
//   const membership_number = tokenPayload.identities?.find(
//     (identity) => identity.providerName === AuthProvider.CathayMember
//   )?.userId;

//   return {
//     membership_number: membership_number,
//     title: tokenPayload["custom:title"],
//     email_verified: tokenPayload.email_verified,
//     birthdate: tokenPayload.birthdate,
//     receive_marketing: tokenPayload["custom:receive_marketing"],
//     update_required: tokenPayload["custom:update_required"],
//     cognito_username: tokenPayload["cognito:username"],
//     given_name: tokenPayload.given_name,
//     family_name: tokenPayload.family_name,
//     email: tokenPayload.email,
//   };
// };
