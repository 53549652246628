import { Box, MainContainer, Tabs } from "@hkexpressairwayslimited/ui/src";
import { Tab } from "../../Molecules/Tabs/Tabs";
import classes from "./PaymentRequestFormTemplate.module.scss";
export interface PaymentRequestFormTemplateProps {
  tabs: Tab[];
}
const PaymentRequestFormTemplate = ({ tabs }: PaymentRequestFormTemplateProps) => {
  return (
    <Box>
      <MainContainer sx={{ backgroundColor: "neutral.white" }}>
        <h5 className={classes.paymentRequestForm_title}>Payment Request Form</h5>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs id='payment-request-form' tabs={tabs} />
        </Box>
      </MainContainer>
    </Box>
  );
};

export { PaymentRequestFormTemplate };
