import { clsx } from "clsx";
import { get } from "lodash";
import { FieldValues } from "react-hook-form";

import { Box, ErrorLabel, useFormState } from "@hkexpressairwayslimited/ui/src";
import { BaseFieldInput, BaseFieldInputProps, flattenAllErrors } from "./BaseFieldInput";

import classes from "./FieldInput.module.scss";

export enum FieldInputVariant {
  Compact = "Compact",
}

export type FieldInputProps<TFieldValues extends FieldValues, Checkbox extends boolean> = {
  className?: string;
  variant?: FieldInputVariant;
  fullWidth?: boolean;
} & BaseFieldInputProps<TFieldValues, Checkbox>;

export const FieldInput = <TFieldValues extends FieldValues, Checkbox extends boolean>({
  className,
  variant = FieldInputVariant.Compact,
  children,
  fullWidth,
  ...others
}: FieldInputProps<TFieldValues, Checkbox>) => {
  const { errors } = useFormState<TFieldValues>({ name: others.name });
  const fieldError = get(errors, others.name);
  const errorList = flattenAllErrors<TFieldValues>(fieldError);

  return (
    <Box
      className={clsx(classes.fieldInput, className, {
        [classes.fieldInput__error]: fieldError,
        [classes.fieldInput__fullWidth]: fullWidth,
      })}
    >
      <BaseFieldInput {...others}>{children}</BaseFieldInput>
      {errorList &&
        errorList.map((error, index) => (
          <ErrorLabel key={index} className={classes.fieldInput_errorLabel}>
            {error?.message}
          </ErrorLabel>
        ))}
    </Box>
  );
};
