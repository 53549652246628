import { AsiaMilesIcon, Box, SelectedItem, SvgIcon, Text } from "@hkexpressairwayslimited/ui/src";
import { useContext } from "react";

import { MilesPlusCashPaymentContext, MilesPlusCashPaymentOptionsSteps } from "../../MilesPlusCashPaymentContext";

import classes from "./MilesPlusCashView.module.scss";

export const MilesPlusCashView = () => {
  const { setMilesUsed, setMilesUsedInCash, setStep, milesUsed, milesUsedInCash, viewStep, order, onRemove } =
    useContext(MilesPlusCashPaymentContext);

  const handleResetAmount = () => {
    setMilesUsed(0);
    setMilesUsedInCash(0);
    onRemove && onRemove();
    setStep(MilesPlusCashPaymentOptionsSteps.Form);
  };

  return (
    <Box className={classes.milesPlusCashView}>
      <SelectedItem
        name={viewStep.appliedItemName}
        removeBtnLabel={viewStep.removeBtnLabel}
        onRemove={handleResetAmount}
      >
        <Text bold>
          {`${order.currency} ${order.format ? order.format(milesUsedInCash) : milesUsedInCash} `}
          <SvgIcon>
            <AsiaMilesIcon />
          </SvgIcon>
          {milesUsed}
        </Text>
      </SelectedItem>
    </Box>
  );
};
