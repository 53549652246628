import {
  AsiaMilesIcon,
  Box,
  Button,
  ButtonVariant,
  EFont,
  EPixel,
  FieldInputGroup,
  Font,
  FormFieldProps,
  NumberInput,
  Slider,
  SvgIcon,
  Text,
  TextSize,
  colors,
  useMediaQueries,
} from "@hkexpressairwayslimited/ui/src";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { Stack } from "@mui/material";
import clsx from "clsx";
import { MilesPlusCashPaymentContext, MilesPlusCashPaymentOptionsSteps } from "../../MilesPlusCashPaymentContext";
import classes from "./MilesPlusCashForm.module.scss";
const MilesInput = ({ value, onBlur, error, ...props }: FormFieldProps & { max?: number }) => {
  return (
    <div className={classes.flightAndTravelExtrasField_milesFieldText}>
      <SvgIcon sx={{ marginTop: "-4px" }}>
        <AsiaMilesIcon />
      </SvgIcon>
      <NumberInput custom value={value} onBlur={onBlur} {...props} sx={{ "& .MuiInput-input": { minWidth: "72px" } }} />
    </div>
  );
};

export const MilesPlusCashForm = () => {
  const {
    setStep,
    milesUsed,
    setMilesUsed,
    setMilesUsedInCash,
    totalMilesAvailable,
    formStep,
    order,
    flightAndTravelExtraField,
    profile,
    onConfirm,
    readyOnly,
    confirmRequiredError,
  } = useContext(MilesPlusCashPaymentContext);
  const [currMilesUsed, setCurrMilesUsed] = useState(0);
  const { isMobile } = useMediaQueries();
  const calculateMilesToCash = useCallback(
    (miles: number) => {
      const sortedBands = [...(order.milesBands ?? [])].sort((a, b) => b.from - a.from);
      const largestBand = sortedBands.find((band) => band.from <= miles);
      const amount = (miles * (largestBand?.rate || 1)) / (order?.currencyConversionRate ?? 1);
      return order.toFixedFactor ? order.toFixedFactor(amount) : amount;
    },
    [order.milesBands, order.currencyConversionRate]
  );

  const { cashAmount, actualMaxSpending, milesStep } = useMemo(() => {
    const milesUsedInCash = calculateMilesToCash(currMilesUsed);
    const cashAmount =
      order.exchangeAll && order.maxSpendingMiles && currMilesUsed >= order.maxSpendingMiles
        ? 0
        : order.cost > milesUsedInCash
          ? order.cost - milesUsedInCash
          : order.cost;
    const actualMaxSpending = Math.min(order.maxSpendingMiles ?? 0, totalMilesAvailable);
    const milesStep = Math.floor((actualMaxSpending - (order.minSpendingMiles ?? 1)) / 100);
    return {
      milesUsedInCash,
      cashAmount: order.toFixedFactor ? order.toFixedFactor(cashAmount) : cashAmount,
      actualMaxSpending,
      milesStep,
    };
  }, [
    currMilesUsed,
    order.cost,
    order.maxSpendingMiles,
    order.minSpendingMiles,
    order.exchangeAll,
    calculateMilesToCash,
    totalMilesAvailable,
  ]);

  const handleMilesChange = (value: number) => {
    // const milesUsedInCash = calculateMilesToCash(value);
    // const cashAmount = order.cost - milesUsedInCash;

    // if (cashAmount >= 0) {
    //   setCurrMilesUsed(Number(value));
    // } else {
    //   setCurrMilesUsed(Number(currMilesUsed));
    // }

    setCurrMilesUsed(Number(value));
  };

  const handleSubmit = useCallback(() => {
    const milesUsed = actualMaxSpending && currMilesUsed > actualMaxSpending ? actualMaxSpending : currMilesUsed;
    const milesUsedInCash = calculateMilesToCash(milesUsed);
    let cashAmount =
      order.exchangeAll && order.maxSpendingMiles && milesUsed >= order.maxSpendingMiles
        ? 0
        : order.cost > milesUsedInCash
          ? order.cost - milesUsedInCash
          : order.cost;

    cashAmount = order.toFixedFactor ? order.toFixedFactor(cashAmount) : cashAmount;
    setMilesUsed(milesUsed);
    setMilesUsedInCash(cashAmount);
    onConfirm && onConfirm(milesUsed, cashAmount);
    setStep(MilesPlusCashPaymentOptionsSteps.View);
  }, [currMilesUsed, actualMaxSpending, order, calculateMilesToCash]);

  useEffect(() => {
    handleMilesChange(milesUsed || order.minSpendingMiles || 0);
  }, [milesUsed, order.minSpendingMiles]);

  return (
    <Box>
      <Box className={isMobile ? classes.flightAndTravelExtrasField_mobile : classes.flightAndTravelExtrasField}>
        <Box
          className={
            isMobile ? classes.flightAndTravelExtrasField_label_mobile : classes.flightAndTravelExtrasField_label
          }
        >
          <Text size={TextSize.P2Regular}>
            <Text size={TextSize.P1Regular} bold span color={colors.actionPurpleDefault}>
              {flightAndTravelExtraField.title}
            </Text>
            <br />
            {flightAndTravelExtraField.desc}
          </Text>

          <Text className={classes.flightAndTravelExtrasField_note} size={TextSize.P3Regular}>
            <Text span>{flightAndTravelExtraField.note}</Text>
            <Text className={classes.flightAndTravelExtrasField_profile} span>
              <Text bold span color={colors.actionPurpleDefault}>
                {profile.name}
              </Text>
              <Text span>{profile.memberNo}</Text>
            </Text>
          </Text>
        </Box>
        <Box className={classes.flightAndTravelExtrasField_fieldWrapper}>
          <Text className={classes.flightAndTravelExtrasField_fieldLabel}>
            <Text span>
              {order.currency}
              <br />
              {order.format ? order.format(cashAmount) : cashAmount}
            </Text>
            <Text span>+</Text>
            <FieldInputGroup
              className={classes.flightAndTravelExtrasField_milesField}
              helperText={flightAndTravelExtraField.placeholder}
              disconnected
              disabled={readyOnly}
              onBlur={() => {
                // const milesUsedInCash = calculateMilesToCash(currMilesUsed);
                // const cashAmount = order.cost - milesUsedInCash;
                if (currMilesUsed < (order.minSpendingMiles ?? 1)) {
                  setCurrMilesUsed(Number(order.minSpendingMiles));
                } else if (currMilesUsed > (order.maxSpendingMiles ?? 1)) {
                  setCurrMilesUsed(Number(order.maxSpendingMiles));
                }
                // else if (cashAmount >= 0) {
                //   setCurrMilesUsed(Number(currMilesUsed));
                // } else {
                //   setCurrMilesUsed(Number(order.minSpendingMiles));
                // }
              }}
            >
              <MilesInput
                value={currMilesUsed}
                onChange={(event) => {
                  handleMilesChange(event.target.value);
                }}
                max={actualMaxSpending}
              />
            </FieldInputGroup>
            {/* <Text className={classes.flightAndTravelExtrasField_milesField}>
              <SvgIcon>
                <AsiaMilesIcon />
              </SvgIcon>
              <Text span> </Text>
              {formatNumber(currMilesUsed)}
            </Text> */}
          </Text>
          {!readyOnly && (
            <Stack direction='column'>
              <Slider
                min={order.minSpendingMiles}
                max={actualMaxSpending}
                value={currMilesUsed}
                step={milesStep}
                onChange={handleMilesChange}
                uncoloredTrack
              />
              <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={EPixel.px4}>
                  <SvgIcon sx={{ marginTop: "-2px !important" }}>
                    <AsiaMilesIcon />
                  </SvgIcon>
                  <Font variant={EFont.p2}>{order.minSpendingMiles}</Font>
                </Stack>
                <Stack direction='row' alignItems='center' justifyContent='space-between' spacing={EPixel.px4}>
                  <SvgIcon sx={{ marginTop: "-2px !important" }}>
                    <AsiaMilesIcon />
                  </SvgIcon>
                  <Font variant={EFont.p2}>{order.maxSpendingMiles}</Font>
                </Stack>
              </Stack>
            </Stack>
          )}
        </Box>
      </Box>
      <Box
        className={clsx(classes.milesPlusCashForm_buttons, {
          [classes.buttons_error_required]: confirmRequiredError,
        })}
      >
        <Button variant={ButtonVariant.Secondary} onClick={handleSubmit} submit tabIndex={0}>
          {formStep.submitBtnLabel}
        </Button>
      </Box>
    </Box>
  );
};
