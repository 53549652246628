import {
  Box,
  Button,
  COUNTRY_CODE,
  CompositeMobileField,
  DatePicker,
  Dropdown,
  EMAIL_REGEXP,
  EPixel,
  FieldInput,
  FieldInputGroup,
  Form,
  FormProps,
  Grid,
  Text,
  TextInput,
  TextSize,
  useForm,
} from "@hkexpressairwayslimited/ui/src";
import { FormControlLabel } from "@mui/material";
import type { Meta, StoryObj } from "@storybook/react";
import { clsx } from "clsx";
import { addYears, format } from "date-fns";
import libphonenumber from "google-libphonenumber";
import { useEffect } from "react";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { Checkbox } from "../../Atoms/Checkbox/Checkbox";
import classes from "./Membership.module.scss";
export interface CompleteProfileResponseType {
  email: string;
  title: string;
  familyName: string;
  givenName: string;
  dob: string;
  countryCode: string;
  mobileNumber: string;
  email_verified: boolean;
  phone_number_verified: boolean;
  freezedAttributes?: string[]; // ["custom:title", "given_name", "family_name", "birthdate", "phone_number", "email"]
  identities?: any[];
}

export const isPhoneValid = (mobile: { countryCode?: string; mobileNumber?: string }) => {
  if (mobile?.mobileNumber && mobile?.mobileNumber.length >= 7 && mobile?.mobileNumber.length <= 20) {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const mobilePhone = `+${mobile?.countryCode}${mobile?.mobileNumber}`;
    try {
      const phone = phoneUtil.parse(mobilePhone);
      const numberType = phoneUtil.getNumberType(phone);
      return phone
        ? phoneUtil.isValidNumber(phone) &&
            (numberType === libphonenumber.PhoneNumberType.MOBILE ||
              numberType === libphonenumber.PhoneNumberType.FIXED_LINE_OR_MOBILE)
        : false;
    } catch (err) {
      return false;
    }
  } else {
    return false;
  }
};

export function getCountryCodeAndNumber(phoneNumber: string) {
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  try {
    const number = phoneUtil.parse(phoneNumber);
    const countryCode = phoneUtil.getCountryCodeForRegion(`${phoneUtil.getRegionCodeForNumber(number)}`);
    const mobileNumber = number.getNationalNumber();
    return { countryCode: countryCode ? `${countryCode}` : "852", mobileNumber: mobileNumber ? `${mobileNumber}` : "" };
  } catch (e) {
    return { countryCode: "852", mobileNumber: "" };
  }
}

const meta: Meta<FormProps> = {
  component: Form,
};

export default meta;
type Story = StoryObj<FormProps>;

const countryCodeOptions = COUNTRY_CODE;

const adultMaxDate = addYears(new Date(), -18);
const adultMinDate = addYears(new Date(), -125);

const SignUp = (
  args: FormProps & {
    attentionText: string | JSX.Element | JSX.Element[];
    buttonLabel: string | JSX.Element | JSX.Element[];
    i18nContent: any;
    completeProfileResponse?: CompleteProfileResponseType;
    isDmFlow: boolean;
  }
) => {
  enum Fields {
    Title = "title",
    FamilyName = "familyName",
    GivenName = "givenName",
    Dob = "dob",
    Email = "email",
    Mobile = "mobile",
    CountryCode = "countryCode",
    MobileNumber = "mobileNumber",
    Marketing = "marketing",
    // DobConsent = "dobConsent",
    TermsAndConditions = "termsAndConditions",
    PrivacyPolicy = "privacyPolicy",
  }

  const FormSchemas = {
    [Fields.Title]: yup.string().required(args.i18nContent.titleField.required),
    [Fields.FamilyName]: yup
      .string()
      .required(args.i18nContent.familyNameField.required)
      .matches(/^(?!.*\s\s)[a-zA-Z\s]*$/, args.i18nContent.familyNameField.match)
      .max(32, args.i18nContent.familyNameField.length),
    [Fields.GivenName]: yup
      .string()
      .required(args.i18nContent.givenNameField.required)
      .matches(/^(?!.*\s\s)[a-zA-Z\s]*$/, args.i18nContent.givenNameField.match)
      .max(32, args.i18nContent.givenNameField.length),
    [Fields.Dob]: yup
      .string()
      .transform((value) => (value ? format(value, "yyyy-MM-dd") : ""))
      .required(args.i18nContent.dobField.required),
    [Fields.Email]: yup
      .string()
      .matches(EMAIL_REGEXP, args.i18nContent.emailField.required)
      .required(args.i18nContent.emailField.required),
    [Fields.Mobile]: yup
      .object()
      .shape({
        [Fields.CountryCode]: yup.string().required(args.i18nContent.mobilePhoneField.CountryCode.required),
        [Fields.MobileNumber]: yup.string().required(args.i18nContent.mobilePhoneField.MobileNumber.required),
      })
      .test("isPhoneValid", args.i18nContent.mobilePhoneField.MobileNumber.required, (value, context) => {
        return isPhoneValid(value);
      }),
    [Fields.Marketing]: yup.boolean().optional(),
    // [Fields.DobConsent]: yup.boolean().oneOf([true], ""),
    [Fields.TermsAndConditions]: yup.boolean().oneOf([true], ""),
    [Fields.PrivacyPolicy]: yup.boolean().oneOf([true], ""),
  };

  const content = {
    titleField: args.i18nContent.titleField,
    familyNameField: args.i18nContent.familyNameField,
    givenNameField: args.i18nContent.givenNameField,
    dobField: args.i18nContent.dobField,
    emailField: args.i18nContent.emailField,
    mobilePhoneField: args.i18nContent.mobilePhoneField,
  };
  const defaultValues = {
    [Fields.Title]: "",
    [Fields.FamilyName]: "",
    [Fields.GivenName]: "",
    [Fields.Dob]: "",
    [Fields.Email]: "",
    [Fields.Mobile]: {
      [Fields.CountryCode]: "852",
      [Fields.MobileNumber]: "",
    },
    [Fields.Marketing]: false,
    // [Fields.DobConsent]: false,
    [Fields.TermsAndConditions]: false,
    [Fields.PrivacyPolicy]: false,
  };
  type FormValues = {
    title: string;
    familyName: string;
    givenName: string;
    dob: string;
    email: string;
    mobile: {
      countryCode: string;
      mobileNumber: string;
    };
    marketing: boolean | undefined;
    // dobConsent: boolean | undefined;
    termsAndConditions: boolean | undefined;
    privacyPolicy: boolean | undefined;
  };
  const formHook = useForm<FormValues>({
    defaultValues,
    schema: yup.object().shape(FormSchemas),
  });
  const {
    formState: { isValid },
  } = formHook;
  useEffect(() => {
    const { completeProfileResponse } = args;
    if (completeProfileResponse) {
      const { title, familyName, givenName, dob, countryCode, mobileNumber, email } = completeProfileResponse;
      const mobileData =
        countryCode || mobileNumber
          ? {
              [Fields.CountryCode]: (countryCode as string) || "",
              [Fields.MobileNumber]: (mobileNumber as string) || "",
            }
          : {
              [Fields.CountryCode]: "852",
              [Fields.MobileNumber]: "",
            };
      formHook.setValue(Fields.Title, (title as string) || "");
      formHook.setValue(Fields.FamilyName, (familyName as string) || "");
      formHook.setValue(Fields.GivenName, (givenName as string) || "");
      formHook.setValue(Fields.Dob, (dob as string) || "");
      formHook.setValue(Fields.Mobile, mobileData);
      formHook.setValue(Fields.Email, (email as string) || "");
    }
  }, [args.completeProfileResponse]);
  return (
    <Grid container>
      <Box className={clsx(classes.formStory)}>
        <Form<FormValues>
          formHook={formHook}
          onSubmit={(formValues) => {
            return args.onSubmit ? args.onSubmit(formValues) : true;
          }}
        >
          <Grid>
            <Box className={classes.formStory_form_title}>{args.i18nContent.title}</Box>
            <Box className={classes.formStory_form_content}>
              <Text size={TextSize.P1Regular}>{args.attentionText}</Text>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <FieldInputGroup
                    disabled={args?.completeProfileResponse?.freezedAttributes?.includes("custom:title")}
                    names={[Fields.Title]}
                    fullWidth
                    helperText={args.i18nContent.titleField.helperText}
                  >
                    <Dropdown
                      fullWidth
                      placeholder={args.i18nContent.titleField.placeholder}
                      options={args.i18nContent.titleField.options}
                    />
                  </FieldInputGroup>
                </Grid>
                <Grid item xs>
                  <FieldInputGroup
                    helperText={[args.i18nContent.familyNameField.helperText]}
                    names={[Fields.FamilyName]}
                    fullWidth
                    disabled={args?.completeProfileResponse?.freezedAttributes?.includes("family_name")}
                  >
                    <TextInput placeholder={args.i18nContent.familyNameField.placeholder} fullWidth />
                  </FieldInputGroup>
                </Grid>
              </Grid>
              <FieldInputGroup
                helperText={[args.i18nContent.givenNameField.helperText]}
                names={[Fields.GivenName]}
                disabled={args?.completeProfileResponse?.freezedAttributes?.includes("given_name")}
                fullWidth
              >
                <TextInput placeholder={args.i18nContent.givenNameField.placeholder} fullWidth />
              </FieldInputGroup>

              <FieldInput name={Fields.Dob} fullWidth>
                <Controller
                  name={Fields.Dob}
                  control={formHook.control}
                  rules={{ required: true }}
                  render={({ field: { value, ...rest } }: any) => {
                    return (
                      <DatePicker
                        minDate={adultMinDate}
                        maxDate={adultMaxDate}
                        value={value ? new Date(value) : null}
                        label={args.i18nContent.dobField.placeholder}
                        {...rest}
                        fullWidth
                        disabled={args?.completeProfileResponse?.freezedAttributes?.includes("birthdate")}
                      />
                    );
                  }}
                />
              </FieldInput>

              <FieldInputGroup
                disabled={
                  !!args?.completeProfileResponse?.email_verified ||
                  args?.completeProfileResponse?.freezedAttributes?.includes("email")
                }
                helperText={[args.i18nContent.emailField.helperText]}
                names={[Fields.Email]}
                fullWidth
              >
                <TextInput placeholder={args.i18nContent.emailField.placeholder} fullWidth type='email' />
              </FieldInputGroup>

              <FieldInputGroup
                disabled={
                  !!args?.completeProfileResponse?.phone_number_verified ||
                  args?.completeProfileResponse?.freezedAttributes?.includes("phone_number")
                }
                helperText={args.i18nContent.mobilePhoneField.helperText}
                names={[Fields.Mobile]}
                fullWidth
              >
                <CompositeMobileField
                  mobileProps={{ placeholder: args.i18nContent.mobilePhoneField.helperText }}
                  countryCodeProps={{ options: args.i18nContent.mobilePhoneField.CountryCode.options }}
                />
              </FieldInputGroup>

              <FieldInput name={Fields.TermsAndConditions}>
                <FormControlLabel
                  control={<Checkbox name={Fields.TermsAndConditions} />}
                  label={args.i18nContent.termsAndConditionsLabel}
                />
              </FieldInput>
              {/* <FieldInput name={Fields.DobConsent}>
                <FormControlLabel
                  control={<Checkbox name={Fields.DobConsent} />}
                  label={args.i18nContent.dobConsentLabel}
                />
              </FieldInput> */}
              <FieldInput name={Fields.PrivacyPolicy}>
                <FormControlLabel
                  control={<Checkbox name={Fields.PrivacyPolicy} />}
                  label={args.i18nContent.privacyPolicyLabel}
                />
              </FieldInput>
              {!args.isDmFlow && (
                <FieldInput name={Fields.Marketing}>
                  <FormControlLabel control={<Checkbox />} label={args.i18nContent.marketingLabel} />
                </FieldInput>
              )}
              <Box>
                {args.i18nContent.mandatoryField}
                {!args.isDmFlow && <Box sx={{ mt: EPixel.px4 }}>{args.i18nContent.byCheckingMarketing}</Box>}
              </Box>
              <Button submit disabled={!formHook.formState.isValid}>
                {args.buttonLabel}
              </Button>
            </Box>
          </Grid>
        </Form>
      </Box>
    </Grid>
  );
};

export { SignUp };
