import { SvgIconProps } from "@mui/material/SvgIcon";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
export type ExpandMore = {
  overturn?: boolean;
} & SvgIconProps;
export function ExpandMoreIcon({ overturn, ...rest }: ExpandMore) {
  return (
    <ExpandLessIcon
      {...rest}
      sx={{
        transform: overturn ? "scaleY(-1)" : "unset",
        transition: "transform .18s ease",
      }}
    />
  );
}
