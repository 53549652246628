import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import WarningSvg from "../../assets/icons/warning.svg";

export function WarningIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      {/* credit: plus icon from https://heroicons.com/ */}
      <WarningSvg />
    </SvgIcon>
  );
}
