/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import { DatePicker } from "../DatePicker/DatePicker";
import FormHelperText from "@mui/material/FormHelperText";
import dayjs, { Dayjs } from "dayjs";

export interface DatePickerFieldProps {
  id: string;
  label: string;
  defaultValue?: string;
  onChange?: Function;
  isError?: boolean;
  helperText?: string;
}
const DatePickerField = (props: DatePickerFieldProps) => {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs(props.defaultValue));

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
    props.onChange && props.onChange(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <DatePicker
				label={props.label as any}
				inputFormat="DD/MM/YYYY"
				value={value}
				onChange={(e: any) => handleChange(e)}
				renderInput={(params) => (
					<TextField
						fullWidth
						{...params}
						error={props.isError}
						helperText={props.helperText}
					/>
				)}
			/> */}
    </LocalizationProvider>
  );
};

export { DatePickerField };
