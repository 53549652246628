import {
  Box,
  Button,
  COUNTRY_CODE,
  CompositeMobileField,
  EMAIL_REGEXP,
  FieldInput,
  FieldInputGroup,
  Form,
  FormProps,
  Grid,
  Radio,
  RadioGroup,
  Text,
  TextInput,
  TextSize,
  useForm,
} from "@hkexpressairwayslimited/ui/src";
import FormControlLabel from "@mui/material/FormControlLabel";
import type { Meta } from "@storybook/react";
import * as yup from "yup";

import classes from "./Membership.module.scss";

const meta: Meta<FormProps> = {
  component: Form,
};

export default meta;

const countryCodeOptions = COUNTRY_CODE;

export type ForgetPasswordBoxProps = {
  onForgetPassword?: (formValues: any) => void;
  errorMsg?: string | JSX.Element | JSX.Element[];
  onClickSignIn: () => void;
  onClickAppleSignIn: () => void;
  i18nContentPassword: {
    title: string;
    text: string;
    method: {
      options: { value: string; label: string }[];
    };
    emailField: {
      placeholder: string;
      helperText: string;
      required: string;
    };
    mobilePhoneField: {
      helperText: string;
      countryCode: {
        required: string;
      };
      mobileNumber: {
        required: string;
      };
      options: { value: string; label: string }[];
    };
    submit: string;
    backToSignInText: string;
  };
};

const ForgetPasswordBox = (props: ForgetPasswordBoxProps) => {
  enum Fields {
    Method = "method",
    Email = "email",
    Mobile = "mobile",
    CountryCode = "countryCode",
    MobileNumber = "mobileNumber",
  }

  const content = {
    methodRadio: props.i18nContentPassword.method,
    emailField: props.i18nContentPassword.emailField,
    mobilePhoneField: props.i18nContentPassword.mobilePhoneField,
  };
  const defaultValues2 = {
    [Fields.Method]: "email",
    [Fields.Email]: "",
    [Fields.Mobile]: {
      [Fields.CountryCode]: "852",
      [Fields.MobileNumber]: "",
    },
  };
  type FormValues2 = {
    [Fields.Method]: string;
    [Fields.Email]?: string;
    [Fields.Mobile]: {
      [Fields.CountryCode]?: string;
      [Fields.MobileNumber]?: string;
    };
  };
  const formHook2 = useForm<FormValues2>({
    defaultValues: defaultValues2,
    schema: yup.object().shape({
      [Fields.Method]: yup.string().required(),
      [Fields.Email]: yup.string().when([Fields.Method], {
        is: (v: string) => v === "email",
        then: (schema) =>
          schema
            .matches(EMAIL_REGEXP, props.i18nContentPassword.emailField.required)
            .required(props.i18nContentPassword.emailField.required),
        otherwise: (schema) => schema.notRequired(),
      }),
      [Fields.Mobile]: yup.object().when([Fields.Method], {
        is: (v: string) => v === "phone",
        then: (schema) =>
          yup.object().shape({
            [Fields.CountryCode]: yup
              .string()
              .required(props.i18nContentPassword.mobilePhoneField.countryCode.required),
            [Fields.MobileNumber]: yup
              .string()
              .required(props.i18nContentPassword.mobilePhoneField.mobileNumber.required),
          }),
        otherwise: (schema) =>
          yup.object().shape({
            [Fields.CountryCode]: yup.string().notRequired(),
            [Fields.MobileNumber]: yup.string().notRequired(),
          }),
      }),
    }),
  });

  const method2 = formHook2.watch(Fields.Method);
  return (
    <Box id='forget-pwd-box' className={classes.formStory}>
      <Form<FormValues2>
        formHook={formHook2}
        onSubmit={(formValues) => {
          return props.onForgetPassword ? props.onForgetPassword(formValues) : true;
        }}
      >
        <Grid>
          <Box className={classes.formStory_form_title}>{props.i18nContentPassword.title}</Box>
          <Box className={classes.formStory_form_content}>
            <Text size={TextSize.P1Regular}>{props.i18nContentPassword.text}</Text>
            <FieldInput name={Fields.Method} fullWidth>
              <RadioGroup row>
                {content.methodRadio.options.map((option: any) => (
                  <FormControlLabel key={option.value} value={option.value} control={<Radio />} label={option.label} />
                ))}
              </RadioGroup>
            </FieldInput>

            {method2 == "email" ? (
              <FieldInputGroup helperText={[content.emailField.helperText]} names={[Fields.Email]} fullWidth>
                <TextInput placeholder={content.emailField.placeholder} fullWidth type='email' />
              </FieldInputGroup>
            ) : (
              <FieldInputGroup helperText={content.mobilePhoneField.helperText} name={Fields.Mobile} fullWidth>
                <CompositeMobileField countryCodeProps={{ options: content.mobilePhoneField.options }} />
              </FieldInputGroup>
            )}

            {props.errorMsg ? <Text>{props.errorMsg}</Text> : null}

            <Button submit fullWidth>
              {props.i18nContentPassword.submit}
            </Button>
            <Button fullWidth onClick={() => props.onClickSignIn()}>
              {props.i18nContentPassword.backToSignInText}
            </Button>
          </Box>
        </Grid>
      </Form>
    </Box>
  );
};

export { ForgetPasswordBox };
