import { forwardRef } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  DateCalendar as MuiDateCalendar,
  DateCalendarProps as MuiDateCalendarProps,
} from "@mui/x-date-pickers/DateCalendar";
import { Dayjs } from "dayjs";

export type DateCalendarProps = MuiDateCalendarProps<Dayjs>;

export const DateCalendar = forwardRef<HTMLDivElement, DateCalendarProps>((props, ref) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDateCalendar ref={ref} {...props} />
    </LocalizationProvider>
  );
});
DateCalendar.displayName = "DateCalendar";
