import { Box, Button, ButtonVariant, MainContainer, Title } from "@hkexpressairwayslimited/ui/src";
import classes from "./PaymentEmailSendingTemplate.module.scss";
// import emailIcon from "../../../assets/icons/email.svg";
import { Grid } from "@mui/material";
import { inputProductData } from "../../Organisms/PaymentRequestForm/PaymentForm";
import { PaymentFormSummary } from "../../Organisms/PaymentRequestForm/PaymentFormSummary/PaymentFormSummary";
import { PaymentDataTypes } from "../../Organisms/PaymentRequestForm/paymentTypes";

interface PaymentLinkTypes {
  emailTo: string;
  subject: string;
  emailBody: string;
  paymentLink: string;
  summary: inputProductData[];
}

interface SendEmailDateType {
  sales_reference: string;
  first_name: string;
  last_name: string;
  receiver: {
    language: string;
    email_address: string;
  };
  note: string;
}

export interface PaymentEmailSendingTemplateProps {
  data: PaymentDataTypes;
  payment: PaymentLinkTypes;
  sendEmail: () => Promise<void>;
}

const PaymentEmailSendingTemplate = ({ data, payment, sendEmail }: PaymentEmailSendingTemplateProps) => {
  const Content = {
    title: "Initiate payment request to the customer",
    preferMethodsText: "Select a customer's preferred methods for receiving the payment request:",
    emailContent: {
      emailTo: "Email to: ",
      subject: "Subject: ",
    },
    btn_sendEmail: "Send Email",
  };

  return (
    <MainContainer sx={{ backgroundColor: "neutral.white" }}>
      <Box padding={2}>
        <Title>{Content.title}</Title>
        <Box className={classes.text}>{Content.preferMethodsText}</Box>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Box border={"1px solid #bfbfbf"} padding={2}>
              <Box className={classes.underline}>
                {Content.emailContent.emailTo}
                {payment.emailTo}
              </Box>
              <Box className={classes.underline}>
                {Content.emailContent.subject}
                {payment.subject}
              </Box>
              <Box>
                <Box dangerouslySetInnerHTML={{ __html: payment.emailBody }}></Box>
              </Box>
            </Box>
            <Box className={classes.buttons}>
              <Button variant={ButtonVariant.Secondary} className={classes.buttons_sendEmail} onClick={sendEmail}>
                {Content.btn_sendEmail}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <PaymentFormSummary summaryType='email' data={data} summaryData={payment.summary} />
          </Grid>
        </Grid>
      </Box>
    </MainContainer>
  );
};

export { PaymentEmailSendingTemplate };
