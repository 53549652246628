import { ComponentsOverrides, ComponentsProps, ComponentsVariants, Theme } from "@mui/material";

export const MuiContainer: {
  defaultProps?: ComponentsProps["MuiContainer"];
  styleOverrides?: ComponentsOverrides<Theme>["MuiContainer"];
  variants?: ComponentsVariants<Theme>["MuiContainer"];
} = {
  styleOverrides: {
    root: ({ ownerState, theme }) => {
      return {
        [theme.breakpoints.up("lg")]: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      };
    },
  },
};
