/* eslint-disable @typescript-eslint/ban-types */
import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiTextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormHelperText from "@mui/material/FormHelperText";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormField } from "../../Atoms/FormField/FormField";
import InputLabel from "@mui/material/InputLabel";

export interface MuiTextFieldProps {}
const StyledTextField = styled(MuiTextField)<MuiTextFieldProps>(({}) => ({}));

interface State {
  password: string;
  showPassword: boolean;
}

export interface PasswordFieldProps {
  id: string;
  label: string | JSX.Element | JSX.Element[];
  isError?: boolean;
  helperText?: string | JSX.Element | JSX.Element[];
  onChange?: Function;
}
const PasswordField = (props: PasswordFieldProps) => {
  const [values, setValues] = React.useState<State>({
    password: "",
    showPassword: false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      password: event.target.value,
    });
    props.onChange && props.onChange(event);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormField>
      <InputLabel id={props.id} error={props.isError} variant='standard'>
        {props.label}
      </InputLabel>
      <Input
        id={props.id}
        type={values.showPassword ? "text" : "password"}
        value={values.password}
        onChange={(e: any) => handleChange(e)}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />

      {props.helperText && (
        <FormHelperText error={props.isError} variant='standard'>
          {props.helperText}
        </FormHelperText>
      )}
    </FormField>
  );
};

export { PasswordField };
