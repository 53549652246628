import { EFont } from "@hkexpressairwayslimited/ui/src/themes/typography";
import { Box, Grid } from "@mui/material";
import * as React from "react";
import { Font } from "../../Atoms/Font/Font";
import { Text } from "../../Atoms/Text";
export interface OurOfferProps {
  icon: React.ReactNode;
  title: string;
  text: string;
}
const OurOffer = (props: OurOfferProps) => (
  <Grid item xs={12} sm={4}>
    <Box
      border={"1px solid #D6D6D8"}
      borderRadius={4}
      padding={2}
      minHeight={152}
      boxSizing={"border-box"}
      display={"flex"}
      height={"100%"}
    >
      <Box
        width={64}
        height={64}
        borderRadius={25}
        bgcolor={"#F6F4F9"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        color='purple.default'
      >
        {/* <SimCardIcon width={40} height={40} /> */}
        {props.icon}
      </Box>
      <Box width={{ flex: 1 }} marginLeft={2}>
        <Font variant={EFont.h2}>{props.title}</Font>
        <Box pt={2}>
          <Text>{props.text}</Text>
        </Box>
      </Box>
    </Box>
  </Grid>
);

export { OurOffer };
