import * as React from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { CalendarSvg } from "../..";

export function CalendarIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <CalendarSvg />
    </SvgIcon>
  );
}
