import { ReactNode, useEffect } from "react";

export const PageGuard = ({
  disabled = false,
  forceShowChildren = false, // For optimize pages that does't rely on required data to display (e.g. login page)
  children,
  precondition,
  fallback,
}: {
  disabled?: boolean;
  forceShowChildren?: boolean;
  children: ReactNode;
  precondition: boolean;
  fallback: (() => void) | ReactNode;
}) => {
  useEffect(() => {
    if (typeof fallback === "function" && !precondition && !disabled) {
      fallback();
    }
  }, [precondition, disabled, fallback]);

  if (typeof window === "undefined") {
    return children;
  } else if (disabled || precondition) {
    return children;
  } else {
    return typeof fallback !== "function" ? fallback : forceShowChildren ? children : undefined;
  }
};
