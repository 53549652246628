import { useFormState } from "@hkexpressairwayslimited/ui/src";
import { EPixel } from "@hkexpressairwayslimited/ui/src/themes";
import MuiCheckbox, { CheckboxProps } from "@mui/material/Checkbox";
import { styled, Theme } from "@mui/material/styles";
import { get } from "lodash";
import { FieldValues } from "react-hook-form";
import MultipleIcon from "./MultipleIcon";
import { NonTickedIcon } from "./NonTickedIcon";
import { CheckedIcon } from "./TickedIcon";
const StyledCheckbox = styled(MuiCheckbox)(({ theme, isError }: { theme: Theme } & { isError: boolean }) => ({
  marginRight: EPixel.px8,
  '& > input[type="checkbox"] + div': {
    border: isError ? "1px solid #e60065" : "1px solid #4D4D4D",
  },
}));

export function Checkbox(props: CheckboxProps) {
  const { errors } = useFormState<FieldValues>({ name: props.name });
  const fieldError = props.name ? get(errors, props.name) : null;
  return (
    <StyledCheckbox
      checkedIcon={<CheckedIcon />}
      icon={<NonTickedIcon />}
      indeterminateIcon={<MultipleIcon />}
      {...props}
      isError={!!fieldError}
    />
  );
}
