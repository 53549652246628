import { Image } from "@hkexpressairwayslimited/ui/src";
import classes from "./BigImageBox.module.scss";

export type BigImageBoxProps = {
  Image: BigImage;
  disableHeight?: boolean;
};
export type BigImage = {
  url: string;
  width?: number;
  height?: number;
  alt?: string;
};

export const BigImageBox = (props: BigImageBoxProps) => {
  return (
    <div className={classes.imageContainer}>
      <Image
        src={props.Image.url}
        width={props.Image.width ?? 1440}
        height={props.Image.height ?? 1080}
        alt={props.Image.url}
        className={!props.disableHeight ? classes.img : ""}
        priority={true}
      />
    </div>
  );
};
