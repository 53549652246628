export enum ESpacingFactor {
  px0 = 0,
  px4 = 0.5, // 4px
  px8 = 1, //8px
  px12 = 1.5, //12px
  px16 = 2, //16px
  px20 = 2.5, //20px
  px22 = 2.75, //20px
  px24 = 3, //24px
  px28 = 3.5, //28px
  px32 = 4, //32px
  px40 = 5, //40px
  px48 = 6, //48px
  px64 = 8, //64px
  px80 = 10, // 80px
}

export enum EPixel {
  xs = "0",
  px4 = "4px", // 4px
  px8 = "8px", //8px
  px12 = "12px", //12px
  px14 = "14px", //14px
  px16 = "16px", //16px
  px20 = "20px", //20px
  px22 = "22px", //20px
  px24 = "24px", //24px
  px28 = "28px", //28px
  px32 = "32px", //32px
  px40 = "40px", //40px
  px48 = "48px", //48px
  px64 = "64px", //64px
  px80 = "80px", // 80px
}

export enum ESpacing {
  _3xs = "4px",
  _2xs = "8px",
  _xs = "12px",
  _s = "16px",
  _sm = "24px",
  _md = "32px",
  _lg = "40px",
  _xl = "48px",
  _2xl = "56px",
  _3xl = "64px",
  _4xl = "72px",
  _5xl = "80px",
  _6xl = "88px",
  _7xl = "96px",
  _8xl = "104px",
  _9xl = "112px",
}

export enum ERem {
  rem1 = "1rem",
  rem2 = "2rem",
  rem3 = "3rem",
  rem4 = "4rem",
  rem5 = "5rem",
}
