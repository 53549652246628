import { BreakpointsOptions } from "@mui/material";

export const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 768,
    md: 993,
    lg: 1248,
    xl: 1536,
  },
};
