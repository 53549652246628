import Script from "next/script";
import { envUiConfig } from "../env";
import { getCookieDomain } from "./router";

export const Personalize = () => {
  if (!envUiConfig.personalizeScript) {
    return null;
  }

  return (
    <Script
      src={envUiConfig.personalizeScript}
      strategy={"lazyOnload"}
      defer
      onLoad={async () => {
        const settings = {
          clientKey: envUiConfig.personalizeClientKey,
          targetURL: "https://api-engage-ap.sitecorecloud.io",
          pointOfSale: envUiConfig.personalizePOS,
          cookieDomain: getCookieDomain(),
          cookiePath: "/",
          cookieExpiryDays: 365,
          forceServerCookieMode: false,
          includeUTMParameters: true,
          webPersonalization: {
            asyncScriptLoading: false,
            deferScriptLoading: true,
          } /* boolean or object. See Settings object for all options. Default: false */,
        };

        const engage = await (window as any).Engage.init(settings);

        // Expose Engage functions to the window object:
        (window as any).Engage.exposedFunctions = engage;
      }}
    />
  );
};
