import { forwardRef, useEffect, useState, useMemo, useCallback, ForwardedRef } from "react";

import {
  isValid,
  getYear,
  getMonth,
  isAfter,
  isBefore,
  differenceInCalendarYears,
  getDaysInMonth,
  getDate,
  addYears,
} from "date-fns";

import { Controller } from "react-hook-form";

import { Dropdown, DropdownOption } from "../../Atoms/Dropdown";
import { FieldInputGroup } from "../../Atoms/Form";

const DEFAULT: { [key: string]: any } = {
  placeholders: ["select date", "YY", "MM", "DD"],
  name: "date-selector",
  start: new Date("1920 01 01"),
  today: new Date(),
  end: addYears(new Date(), 50),
};

export interface DateSelectorHandle {}
export interface DateSelectorsProps extends Partial<Omit<HTMLSelectElement, "placeholder" | "value">> {
  tooltip?: string;
  start?: Date;
  end?: Date;
  placeholder?: string | string[];
  value?: Date;
  onChange?: (value: Date | undefined) => void;
  control?: any;
  fullWidth?: boolean;
  ref?: ForwardedRef<DateSelectorHandle | unknown>;
}

export interface DateSelectorOption {
  label: string;
  value: string;
  origin?: string;
}

interface OptionsProps {
  options: DateSelectorOption[];
  placeholder: string;
  value?: string | null;
  onChange?: (...event: any[]) => void;
}
function Options({ options, placeholder = "", value = "", onChange }: Readonly<OptionsProps>) {
  return (
    <Dropdown value={value} onChange={onChange} placeholder={placeholder}>
      {options.map(({ label, value, origin }) => (
        <DropdownOption key={origin} value={value}>
          {label}
        </DropdownOption>
      ))}
    </Dropdown>
  );
}
function getYearOptions(start: Date, end: Date) {
  const yearGap = Math.abs(differenceInCalendarYears(start, end));
  const startYear = getYear(start);
  const years: DateSelectorOption[] = [];
  for (let i = 0; i <= yearGap; i++) {
    const s = (startYear + i).toString();
    years.push({
      label: s,
      value: s,
      origin: s,
    });
  }
  return years;
}
function getMonthOption(start: Date, end: Date, year: string | null) {
  if (!year) return [];
  const endYear = getYear(end);
  const startYear = getYear(start);
  const yearToNum = parseInt(year, 10);
  let startMonth = 1;
  let endMonth = 12;
  if (yearToNum >= endYear) {
    endMonth = getMonth(end) + 1;
  }
  if (yearToNum <= startYear) {
    startMonth = getMonth(start) + 1;
  }
  const Options: DateSelectorOption[] = [];
  for (let i = startMonth; i <= endMonth; i++) {
    const s = i.toString();
    Options.push({
      label: s,
      value: s,
      origin: `${year}-${s}`,
    });
  }
  return Options;
}

function getDaysOption(start: Date, end: Date, year: string | null, month: string | null) {
  if (!year || !month) return [];
  const endYear = getYear(end);
  const endMonth = getMonth(end) + 1;
  const startYear = getYear(start);
  const startMonth = getMonth(start) + 1;
  const yearToNum = parseInt(year, 10);
  const monthNum = parseInt(month, 10);
  let startDay = 1;
  let endDay = getDaysInMonth(new Date(`${year} ${month}`));
  if (yearToNum >= endYear && monthNum >= endMonth) {
    endDay = getDate(end) + 1;
  }
  if (yearToNum <= startYear && monthNum <= startMonth) {
    startDay = getDate(start) + 1;
  }
  const Options: DateSelectorOption[] = [];
  for (let i = startDay; i <= endDay; i++) {
    const s = i.toString();
    Options.push({
      label: s,
      value: s,
      origin: `${year}-${month}-${s}`,
    });
  }
  return Options;
}
function getValidMYD(start: Date, end: Date, value?: Date) {
  if (isValid(value) && value) {
    let validValue = value;
    if (isBefore(value, start)) {
      validValue = start;
    }
    if (isAfter(value, end)) {
      validValue = end;
    }
    return [getYear(validValue).toString(), (getMonth(validValue) + 1).toString(), getDate(validValue).toString()];
  }
  return ["", "", ""];
}
function DateSelector({
  start = DEFAULT.start,
  end = DEFAULT.end,
  tooltip,
  name,
  value,
  placeholder = DEFAULT.placeholders,
  onChange,
  fullWidth,
}: Readonly<DateSelectorsProps>) {
  const placeholders = typeof placeholder === "string" ? [placeholder] : placeholder;
  const [
    mainPlaceholder = DEFAULT.placeholders[0],
    yearPlaceholder = DEFAULT.placeholders[1],
    monthPlaceholder = DEFAULT.placeholders[2],
    dayPlaceholder = DEFAULT.placeholders[3],
  ] = placeholders;
  let innerChangeClean = false;
  const [y, m, d] = useMemo(() => {
    if (!value) {
      return ["", "", ""];
    } else {
      return getValidMYD(start, end, value);
    }
  }, [start, end, value]);
  const [year, setYear] = useState<string>(y);
  const [month, setMonth] = useState<string>(m);
  const [day, setDay] = useState<string>(d);
  useEffect(() => {
    if (start && end && y && m && d) {
      setYear(y);
      setMonthOptions(getMonthOption(start, end, y));
      setMonth(m);
      setDaysOptions(getDaysOption(start, end, y, m));
      setDay(d);
    }
  }, [start, end, y, m, d]);

  useEffect(() => {
    if (innerChangeClean) {
      innerChangeClean = false;
    } else {
      if (value === null || value === undefined) {
        setYear("");
        setMonthOptions([]);
        setMonth("");
        setDaysOptions([]);
        setDay("");
      }
    }
  }, [value]);
  const yearOptions = useMemo(() => getYearOptions(start, end), []);
  const [monthOptions, setMonthOptions] = useState<DateSelectorOption[]>(getMonthOption(start, end, year));
  const [daysOptions, setDaysOptions] = useState<DateSelectorOption[]>(getDaysOption(start, end, year, month));
  const handleYearChange = useCallback(
    (s: string) => {
      if (s) {
        setYear(s);
        setMonth("");
        setDay("");
        setMonthOptions(getMonthOption(start, end, s));
        setDaysOptions([]);
      }
      innerChangeClean = true;
      onChange && onChange(undefined);
    },
    [setYear, setMonth, setDay, setMonthOptions, setDaysOptions]
  );

  const handleMonthChange = useCallback(
    (s: string) => {
      innerChangeClean = true;
      if (s) {
        setMonth(s);
        setDay("");
        setDaysOptions(getDaysOption(start, end, year, s));
      }
      onChange && onChange(undefined);
    },
    [setMonth, setDay, setDaysOptions, getDaysOption, year]
  );

  useEffect(() => {
    if (year && month && day) {
      onChange && onChange(new Date(`${year} ${month} ${day}`));
    }
  }, [day]);

  return (
    <FieldInputGroup
      helperText={mainPlaceholder}
      tooltip={tooltip}
      names={[name ?? DEFAULT.name]}
      fullWidth={fullWidth}
      customFormGroup
    >
      <Options value={year} onChange={handleYearChange} options={yearOptions} placeholder={yearPlaceholder} />
      <Options value={month} onChange={handleMonthChange} options={monthOptions} placeholder={monthPlaceholder} />
      <Options value={day} onChange={setDay} options={daysOptions} placeholder={dayPlaceholder} />
    </FieldInputGroup>
  );
}

export const DateSelectors = forwardRef(({ name, control, ...rest }: DateSelectorsProps, ref) => {
  return control ? (
    <Controller
      name={name ?? "DOB"}
      control={control}
      render={({ field }) => {
        return <DateSelector {...field} {...rest} ref={ref} />;
      }}
    />
  ) : (
    <DateSelector name={name} {...rest} />
  );
});
DateSelectors.displayName = "DateSelectors";
