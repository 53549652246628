import { isValidElement } from "react";
import classes from "./SelectedItem.module.scss";

import {
  Button,
  CloseOutlineIcon,
  SvgIcon,
  SvgIconProps,
  SvgIconSize,
  Text,
  TickCircleIcon,
  colors,
  useMediaQueries,
} from "@hkexpressairwayslimited/ui/src";

export type SelectedItemProps = {
  icon?: SvgIconProps["children"];
  name?: string;
  onRemove?: () => void;
  removeBtnLabel?: string;
  children: React.ReactNode;
};

export const SelectedItem = ({ icon, name, onRemove, children, removeBtnLabel }: SelectedItemProps) => {
  const handleRemove = () => {
    if (onRemove) {
      onRemove();
    }
  };

  const isTable = isValidElement(children) && children?.type === ContentTable;
  const itemName = (
    <Text bold color={colors.actionPurpleDefault}>
      {name}
    </Text>
  );
  const removeBtn = (
    <Button onClick={handleRemove} className={classes.selectedItem_removeBtn} custom>
      <Text bold span color={colors.actionPurpleDefault}>
        {removeBtnLabel}
      </Text>
      <SvgIcon size={SvgIconSize.Desktop}>
        <CloseOutlineIcon />
      </SvgIcon>
    </Button>
  );

  const { isMobile } = useMediaQueries();

  return (
    <div className={!isMobile ? classes.selectedItem : classes.selectedItemMobile}>
      {!isMobile ? (
        <div className={classes.selectedItem_content}>
          <SvgIcon color={colors.actionPurpleDefault} size={SvgIconSize.Desktop}>
            {icon ? icon : <TickCircleIcon />}
          </SvgIcon>
          <div className={classes.selectedItem_itemList}>
            {!isTable && itemName}
            {isTable ? (
              <div className={classes.selectedItemContentTable}>
                <TableRow>
                  {itemName}
                  {removeBtn}
                </TableRow>
                {children}
              </div>
            ) : (
              children
            )}
          </div>
          <div>{!isTable && removeBtn}</div>
        </div>
      ) : (
        <>
          <div className={classes.selectedItem_content}>
            <SvgIcon color={colors.actionPurpleDefault} size={SvgIconSize.Desktop}>
              {icon ? icon : <TickCircleIcon />}
            </SvgIcon>
            {!isTable && itemName}
          </div>
          <div className={isTable ? classes.selectedItem_itemList : classes.selectedItemMobile_item}>
            {isTable ? (
              <div>
                <TableRow>{itemName}</TableRow>
                {removeBtn}
                {children}
              </div>
            ) : (
              children
            )}
          </div>
          <div className={classes.selectedItemMobile_item}>{!isTable && removeBtn}</div>
        </>
      )}
    </div>
  );
};

const ContentTable = ({ children }: { children: React.ReactNode }) => {
  return children;
};

SelectedItem.ContentTable = ContentTable;

export const TableRow = ({ children }: { children: React.ReactNode }) => {
  return <div className={classes.selectedItemContentTable_row}>{children}</div>;
};

SelectedItem.TableRow = TableRow;
