import { useMemo, useState, MouseEvent } from "react";
import Man2Icon from "@mui/icons-material/Man2";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { ClickAwayListener, Popper } from "@mui/material";
import { nanoid } from "nanoid";
import { clsx } from "clsx";

import { TextInput } from "../../../Atoms/TextInput";
import { Box } from "../../../Atoms/Box";
import { Container } from "../../Container";
import { Section } from "../../Section";
import { Title, TitleOrders } from "../../../Atoms/Title";
import { NumberInput } from "../../../Atoms/NumberInput";
import { Button } from "../../../Atoms/Button";

import classes from "./PassengerField.module.scss";

export type PanelContent = {
  title: string;
  fields: {
    adults: string;
    children: string;
    Infants: string;
    submit: string;
  };
};

export type PassengerFieldProps = {
  className?: string;
  passenger: string;
  panel: PanelContent;
  adultLabel: string;
  childrenLabel: string;
  infantLabel: string;
  onSubmit?: (value: { adults?: string; children?: string; infants?: string }) => void;
};

export const PassengerField = ({
  className,
  adultLabel,
  childrenLabel,
  infantLabel,
  passenger,
  panel,
  onSubmit,
}: PassengerFieldProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>();
  const [adults, setAdults] = useState<string>();
  const [children, setChildren] = useState<string>();
  const [infants, setInfants] = useState<string>();
  const anchorId = useMemo(() => nanoid(), []);
  const showPanel = Boolean(anchorEl);
  const fieldValue = [
    adults && `${adults} ${adultLabel}`,
    children && `${children} ${childrenLabel}`,
    infants && `${infants} ${infantLabel}`,
  ]
    .filter(Boolean)
    .join(", ");

  const handleFieldClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePanel = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit({ adults, children, infants });
    }
    handleClosePanel();
  };

  return (
    <Box className={clsx(classes.passengerField, className)} onClick={handleFieldClick}>
      <Box className={classes.passengerField_subField} onClick={handleFieldClick}>
        <TextInput
          className={classes.passengerField_input}
          icon={<Man2Icon />}
          value={fieldValue}
          placeholder={passenger}
          readOnly
        />
        <Box className={classes.passengerField_iconWrapper}>
          <ExpandMoreOutlinedIcon />
        </Box>
      </Box>

      <Popper
        id={anchorId}
        open={showPanel}
        anchorEl={anchorEl}
        className={classes.passengerField_panelWrapper}
        placement='bottom'
        disablePortal={true}
        modifiers={[
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "viewport",
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: "viewport",
            },
          },
        ]}
      >
        <Container>
          <ClickAwayListener onClickAway={handleClosePanel}>
            <Box className={classes.passengerField_panel}>
              <Title className={classes.passengerField_panelTitle} order={TitleOrders.HeadingSmall}>
                {panel.title}
              </Title>
              <Box>
                <Box className={classes.passengerField_panelFormRow}>
                  <Box dangerouslySetInnerHTML={{ __html: panel.fields.adults }} />
                  <NumberInput value={adults} onChange={(event) => setAdults(event.target.value)} max={9} />
                </Box>

                <Box className={classes.passengerField_panelFormRow}>
                  <Box dangerouslySetInnerHTML={{ __html: panel.fields.children }} />
                  <NumberInput value={children} onChange={(event) => setChildren(event.target.value)} max={9} />
                </Box>

                <Box className={classes.passengerField_panelFormRow}>
                  <Box dangerouslySetInnerHTML={{ __html: panel.fields.Infants }} />
                  <NumberInput value={infants} onChange={(event) => setInfants(event.target.value)} max={9} />
                </Box>
              </Box>
              <Button className={classes.passengerField_panelSubmit} outlined onClick={handleSubmit}>
                {panel.fields.submit}
              </Button>
            </Box>
          </ClickAwayListener>
        </Container>
      </Popper>
    </Box>
  );
};
