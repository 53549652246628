import * as React from "react";
import MuiRadio, { RadioProps } from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import ChoosedIcon from "./ChoosedIcon";
import NonChoosedIcon from "./NonChoosedIcon";

const StyledRadio = styled(MuiRadio)(({ theme }) => ({}));

export function Radio(props: RadioProps) {
  return <StyledRadio checkedIcon={<ChoosedIcon />} icon={<NonChoosedIcon />} {...props} />;
}
