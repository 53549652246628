import React from "react";
import { Button, ButtonVariant, useFormContext, useWatch } from "@hkexpressairwayslimited/ui/src";
import { Typography } from "@mui/material";
import { calTotal, inputProductData } from "../PaymentForm";
import { PaymentDataTypes, SummaryType } from "../paymentTypes";
import classes from "./PaymentFormSummary.module.scss";

function formatNumber(num: number) {
  return new Intl.NumberFormat("en-US", { style: "decimal", minimumFractionDigits: 2 }).format(num);
}

export interface PaymentFormSummaryProps {
  data: PaymentDataTypes;
  summaryType: SummaryType;
  summaryData?: inputProductData[];
  handlePayment?: (selectedMethod: string) => void;
  paymentMethod?: string;
}

export interface NoWatchProps {
  mockData: inputProductData[];
}
const PaymentFormSummary = ({
  data,
  summaryType,
  summaryData,
  handlePayment,
  paymentMethod,
}: PaymentFormSummaryProps) => {
  // console.log("Summary Data", summaryData);
  // const demoData = [
  //   {
  //     amount: 100,
  //     currency: "HKD",
  //     description: "test",
  //     label: "Asia Miles Gift Miles",
  //   },
  //   {
  //     amount: 390,
  //     currency: "HKD",
  //     description: "test2",
  //     label: "Asia Miles physical membership card replacement",
  //   },
  //   {
  //     amount: 123.99,
  //     currency: "HKD",
  //     description: "test3",
  //     label: "Asia Miles Top-Up",
  //   },
  // ];

  const Total = () => {
    const { control } = useFormContext();
    const results = useWatch({ control, name: "sales_Item" });

    const output = calTotal(results);
    return (
      <Typography fontSize={"1.2rem"} marginLeft={"16px"} align='right' color={"white"}>
        Total HKD {formatNumber(output)}
      </Typography>
    );
  };

  const NoWatchTotal = ({ mockData }: NoWatchProps) => {
    const output = calTotal(mockData);
    return (
      <Typography fontSize={"1.2rem"} marginLeft={"16px"} align='right' color={"white"}>
        Total HKD {formatNumber(output)}
      </Typography>
    );
  };

  const NoWatchSubTotal = ({ mockData }: NoWatchProps) => {
    const output = calTotal(mockData);
    const types: { [key: string]: inputProductData[] } = {};

    mockData.map((result: inputProductData) => {
      const found = Object?.values(data.product).find((item) => item.value === result.label);

      if (found !== undefined && !types[found.type]) {
        types[found.type] = [];
        types[found.type].push(result);
      } else if (found !== undefined) {
        types[found.type].push(result);
      }
    });
    return (
      <>
        {Object.keys(types).map((type, index) => {
          const total = calTotal(types[type]);
          // console.log("Finding inside", types, types[type]);
          return (
            <div key={index} className={classes.summary_subTotal_container}>
              <div className={classes.summary_subTotal_container_type}>
                <Typography fontSize={"0.9rem"} color={"black"}>
                  {type}
                </Typography>
                <Typography fontSize={"0.9rem"} color={"black"}>
                  HKD {formatNumber(total)}
                </Typography>
              </div>
              {types[type].map((item: inputProductData, index) => (
                <div key={index} className={classes.summary_subTotal_container_item}>
                  <Typography marginLeft={"8px"} fontSize={"0.9rem"} color={"grey"}>
                    {item.label}
                  </Typography>
                  <Typography fontSize={"0.9rem"} color={"grey"}>
                    HKD {formatNumber(item.amount)}
                  </Typography>
                </div>
              ))}
            </div>
          );
        })}

        {Object.keys(types).length > 0 ? (
          <div className={classes.summary_subTotal_grandTotal_price}>
            <Typography fontSize={"0.9rem"} color={"black"}>
              Grand total (including taxes)
            </Typography>
            <Typography fontSize={"0.9rem"} color={"black"}>
              HKD {formatNumber(output)}
            </Typography>
          </div>
        ) : null}
      </>
    );
  };

  const SubTotal = () => {
    const { control } = useFormContext();
    const results: inputProductData[] = useWatch({ control, name: "sales_Item" });
    const output = calTotal(results);
    const types: { [key: string]: inputProductData[] } = {};

    const temp = results.map((result: inputProductData) => {
      const found = Object?.values(data.product).find((item) => item.value === result.product);

      if (found !== undefined && !types[found.type]) {
        types[found.type] = [];
        types[found.type].push(result);
      } else if (found !== undefined) {
        types[found.type].push(result);
      }
    });

    return (
      <>
        {Object.keys(types).map((type, index) => {
          const total = calTotal(types[type]);
          return (
            <div key={index} className={classes.summary_subTotal_container}>
              <div className={classes.summary_subTotal_container_type}>
                <Typography fontSize={"0.9rem"} color={"black"}>
                  {type}
                </Typography>
                <Typography fontSize={"0.9rem"} color={"black"}>
                  HKD {formatNumber(total)}
                </Typography>
              </div>
              {types[type].map((item: inputProductData, index) => (
                <div key={index} className={classes.summary_subTotal_container_item}>
                  <Typography marginLeft={"8px"} fontSize={"0.9rem"} color={"grey"}>
                    {item.product}
                  </Typography>
                  <Typography fontSize={"0.9rem"} color={"grey"}>
                    HKD {formatNumber(item.amount)}
                  </Typography>
                </div>
              ))}
            </div>
          );
        })}

        {Object.keys(types).length > 0 ? (
          <div className={classes.summary_subTotal_grandTotal_price}>
            <Typography fontSize={"0.9rem"} color={"black"}>
              Grand total (including taxes)
            </Typography>
            <Typography fontSize={"0.9rem"} color={"black"}>
              HKD {formatNumber(output)}
            </Typography>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className={classes.summary}>
      <div className={classes.summary_viewDetails}>
        <Typography fontSize={"1.2rem"} marginLeft={"12px"} color={"#702B91"}>
          Summary
        </Typography>

        <Typography
          fontSize={"0.9rem"}
          marginRight={"20px"}
          marginTop={"4px"}
          color={"#702B91"}
          sx={{ ":hover": { textDecoration: "underline", cursor: "pointer" } }}
        >
          {/* View details */}
        </Typography>
      </div>

      {summaryType === "generate" ? (
        <div className={classes.summary_subTotal}>
          <SubTotal />
        </div>
      ) : (
        summaryData !== undefined && <NoWatchSubTotal mockData={summaryData} />
      )}

      <div className={classes.summary_generate}>
        <div className={classes.summary_generate_price}>
          {summaryType === "generate" ? (
            <Total />
          ) : summaryData !== undefined ? (
            <NoWatchTotal mockData={summaryData} />
          ) : null}

          {summaryType === "generate" ? (
            <Button variant={ButtonVariant.Secondary} type='submit'>
              Generate
            </Button>
          ) : summaryType === "payment" && paymentMethod !== undefined && handlePayment !== undefined ? (
            <Button variant={ButtonVariant.Secondary} type='submit' onClick={() => handlePayment(paymentMethod)}>
              Pay Now
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { PaymentFormSummary };
