"use client";
import { Box, EFont, Font, useMobileQuery } from "@hkexpressairwayslimited/ui/src";
import { SxProps } from "@mui/material";
import clsx from "clsx";
import classes from "./Tag.module.scss";
export interface TagProps {
  value: string | JSX.Element | JSX.Element[];
  variant: "promo_message" | "promo_tag" | "success" | "red" | "blue";
  sx?: SxProps;
}

export const Tag = ({ value, variant, sx }: TagProps) => {
  const isMobile = useMobileQuery();
  return (
    <Box
      className={clsx({
        [classes.tag_promo_message]: variant === "promo_message",
        [classes.tag_promo_tag]: variant === "promo_tag",
        [classes.tag_success]: variant === "success",
        [classes.tag_red]: variant === "red",
        [classes.tag_blue]: variant === "blue",
      })}
      sx={sx}
    >
      <Font variant={!isMobile && variant === "promo_tag" ? EFont.p1 : EFont.p3} fontWeight={"700"}>
        {value}
      </Font>
    </Box>
  );
};
