import { useDesktopQuery } from "@hkexpressairwayslimited/ui/src";
import { useCallback, useState } from "react";
import { Box, Tabs } from "../..";
import { FindBooking } from "./FindBooking";
import { ForgetPasswordBox, ForgetPasswordBoxProps } from "./ForgetPasswordBox";
import classes from "./Membership.module.scss";
import { SignInBox, SignInBoxProps } from "./SignInBox";
type SignInProps = {
  signInUrl: string;
  onSignIn?: SignInBoxProps["onSignInBox"];
  onForgetPassword?: ForgetPasswordBoxProps["onForgetPassword"];
  signInErrorMsg?: string | JSX.Element | JSX.Element[];
  forgetPwdErrorPwdMsgMsg?: string | JSX.Element | JSX.Element[];
  isDisplayClose?: boolean;
  onCloseLoginNow?: () => void;
  handleCxMemberClick: () => void;
  handleAppleMemberClick: () => void;
  i18nContent: SignInBoxProps["i18nContent"];
  i18nContentPassword: ForgetPasswordBoxProps["i18nContentPassword"];
  isShowTabs?: boolean;
  handleFindBookingSubmit?: (data: any) => void;
};

const SignInAndForgetPwd = (props: SignInProps) => {
  const [isLogin, setIsLogin] = useState<string>("signIn");
  const [curTab, setCurTab] = useState(0);
  const onClickForgetPassword = useCallback(() => {
    setIsLogin("forgetPwd");
  }, [setIsLogin]);
  const onClickSignIn = useCallback(() => {
    setIsLogin("signIn");
  }, [setIsLogin]);
  const onClickAppleSignIn = useCallback(() => {
    setIsLogin("signIn");
  }, [setIsLogin]);

  const SignInBoxComponent = (
    <SignInBox
      signInUrl={props.signInUrl}
      errorMsg={props.signInErrorMsg}
      onSignInBox={props.onSignIn}
      onClickForgetPassword={onClickForgetPassword}
      isDisplayClose={props.isDisplayClose}
      onCloseLoginNow={props.onCloseLoginNow}
      i18nContent={props.i18nContent}
      handleCxMemberClick={props.handleCxMemberClick}
      handleAppleMemberClick={props.handleAppleMemberClick}
      hideTitle={props.isShowTabs}
    />
  );
  const FindBookingComponent = (
    <FindBooking
      i18nContent={props.i18nContent}
      handleFindBookingSubmit={props.handleFindBookingSubmit || (() => {})}
    />
  );
  const isDesktop = useDesktopQuery();
  return (
    <>
      <Box sx={isLogin === "signIn" ? {} : { display: "none" }}>
        {props.isShowTabs ? (
          <Box
            className={classes.formStory}
            sx={{
              ".MuiTabs-root": {
                "& .MuiButtonBase-root.MuiTab-root": {
                  padding: isDesktop ? "20px 16px" : "16px",
                  color: "#000",
                  fontSize: isDesktop ? "22px" : "18px",
                },
                "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                  color: "#702B91",
                },
              },
            }}
          >
            <Tabs
              onChange={(value: number) => {
                setCurTab(value);
              }}
              tabs={[
                {
                  label: (props.i18nContent.title as string) || "",
                  children: SignInBoxComponent,
                },
                {
                  label: (props.i18nContent.findBooking.title as string) || "",
                  children: FindBookingComponent,
                },
              ]}
              id='sign in'
              scrollable={true}
              noPadding
              responsive
            />
          </Box>
        ) : (
          SignInBoxComponent
        )}
      </Box>
      <Box sx={isLogin === "forgetPwd" ? {} : { display: "none" }}>
        <ForgetPasswordBox
          i18nContentPassword={props.i18nContentPassword}
          errorMsg={props.forgetPwdErrorPwdMsgMsg}
          onForgetPassword={props.onForgetPassword}
          onClickSignIn={onClickSignIn}
          onClickAppleSignIn={onClickAppleSignIn}
        />
      </Box>
    </>
  );
};

export { SignInAndForgetPwd };
