import * as React from "react";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import AccordionDetails, { AccordionDetailsProps } from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";

interface ButtonProps {}

const StyledAccordion = styled(Accordion)<AccordionProps>(({ theme }) => ({}));
const StyledAccordionSummary = styled(AccordionSummary)<AccordionSummaryProps>(({ theme }) => ({}));
const StyledAccordionDetails = styled(AccordionDetails)<AccordionDetailsProps>(({ theme }) => ({}));

export {
  StyledAccordion as Accordion,
  StyledAccordionSummary as AccordionSummary,
  StyledAccordionDetails as AccordionDetails,
};
