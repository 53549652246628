export class TabsAutomatic {
  tablistNode: HTMLDivElement;
  tabs: HTMLDivElement[];
  tabpanels: HTMLDivElement[];
  firstTab?: HTMLDivElement;
  lastTab?: HTMLDivElement;
  defaultIndex?: number;
  onTabClicked?: (index: number) => void;
  constructor(groupNode: HTMLDivElement, onTabClicked?: (index: number) => void, defaultIndex: number = 0) {
    this.tablistNode = groupNode;
    this.onTabClicked = onTabClicked;
    this.defaultIndex = defaultIndex;

    this.tabs = Array.from(this.tablistNode.querySelectorAll("[role=tab]"));
    this.tabpanels = [];

    for (let i = 0; i < this.tabs.length; i += 1) {
      const tab = this.tabs[i];
      const controledId = tab.getAttribute("aria-controls");
      if (controledId) {
        const tabpanel = document.querySelector<HTMLDivElement>(`#${controledId}`);
        tab.tabIndex = -1;
        tab.setAttribute("aria-selected", "false");
        tabpanel && this.tabpanels.push(tabpanel);
      }
      tab.addEventListener("keydown", this.onKeydown.bind(this));
      tab.addEventListener("click", this.onClick.bind(this));

      if (!this.firstTab) {
        this.firstTab = tab;
      }
      this.lastTab = tab;
      if (this.defaultIndex === i + 1) {
        this.setSelectedTab(tab, false);
      }
    }
  }

  setSelectedTab(currentTab: HTMLDivElement, setFocus?: boolean) {
    if (typeof setFocus !== "boolean") {
      setFocus = true;
    }
    for (let i = 0; i < this.tabs.length; i += 1) {
      const tab = this.tabs[i];
      if (currentTab === tab) {
        tab.setAttribute("aria-selected", "true");
        // tab.removeAttribute('tabindex');
        // this.tabpanels[i]?.classList.remove('is-hidden');
        this.onTabClicked && this.onTabClicked(i + 1);
        if (setFocus) {
          tab.focus();
        }
      } else {
        tab.setAttribute("aria-selected", "false");
        tab.tabIndex = -1;
        // this.tabpanels[i]?.classList.add('is-hidden');
      }
    }
  }

  setSelectedToPreviousTab(currentTab: HTMLDivElement) {
    let index;

    if (currentTab === this.firstTab) {
      this.lastTab && this.setSelectedTab(this.lastTab);
    } else {
      index = this.tabs.indexOf(currentTab);
      this.setSelectedTab(this.tabs[index - 1]);
    }
  }

  setSelectedToNextTab(currentTab: HTMLDivElement) {
    let index;

    if (currentTab === this.lastTab) {
      this.firstTab && this.setSelectedTab(this.firstTab);
    } else {
      index = this.tabs.indexOf(currentTab);
      this.setSelectedTab(this.tabs[index + 1]);
    }
  }

  /* EVENT HANDLERS */

  onKeydown(event: KeyboardEvent) {
    const tgt = event.currentTarget as HTMLDivElement;
    let flag = false;

    switch (event.key) {
      case "ArrowLeft":
        this.setSelectedToPreviousTab(tgt);
        flag = true;
        break;

      case "ArrowRight":
        this.setSelectedToNextTab(tgt);
        flag = true;
        break;

      case "Home":
        this.firstTab && this.setSelectedTab(this.firstTab);
        flag = true;
        break;

      case "End":
        this.lastTab && this.setSelectedTab(this.lastTab);
        flag = true;
        break;

      default:
        break;
    }

    if (flag) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  onClick(event: MouseEvent) {
    this.setSelectedTab(event.currentTarget as HTMLDivElement);
  }
}
