import { RouteMapBar } from "@hkexpressairwayslimited/ui/src";
import { DestinationDetailed } from "../../Molecules/DestinationCard/destinationtypes";
import { EqualHeightGridCard } from "../../Organisms/EqualHeightGridCard/EqualHeightGridCard";
import { GridCard } from "../../Organisms/GridCard/GridCard";
import classes from "./HeroPageTemplate.module.scss";

export type HeroPageTemplateProps = {
  routePath: {
    page: string;
    path: string[];
  };
  destinations: DestinationDetailed[];
  lang?: string;
};
const HeroPageTemplate = ({ routePath, destinations, lang }: HeroPageTemplateProps) => {
  const groups = [
    destinations.slice(0, 3),
    destinations.slice(3, 9),
    destinations.slice(9, 12),
    destinations.slice(12, destinations.length - 1),
  ];

  return (
    <div className={classes.heroPage}>
      <div className={classes.heroPage_routeFlex}>
        <RouteMapBar routePath={routePath} />
        <div className={classes.heroPage_hkeText}>精彩航點</div>
      </div>
      <div className={classes.heroPage_flex}>
        <GridCard destinations={groups[0]} position='left' lang={lang} />
        <EqualHeightGridCard destinations={groups[1]} lang={lang} />
        <GridCard destinations={groups[2]} position='right' lang={lang} />
        {groups[3].length > 0 && <EqualHeightGridCard destinations={groups[3]} lang={lang} />}
      </div>
    </div>
  );
};

export { HeroPageTemplate };
