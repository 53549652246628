import { clsx } from "clsx";
import { isNumber } from "lodash";
import { forwardRef, ReactElement, SVGProps, useMemo } from "react";

import { Button as BaseButton, ButtonProps as BaseButtonProps } from "@mui/base/Button";

import { getInnerText } from "@hkexpressairwayslimited/ui/src";
import { SvgIcon, SvgIconSize } from "../SvgIcon";
import { Text, TextProps, TextSize } from "../Text";
import classes from "./Button.module.scss";

export enum ButtonVariant {
  // Obsolete
  Primary = "CTA",
  // Submit = "Submit",
  // SubmitOutlined = "SubmitOutlined",

  // New Design: https://www.figma.com/file/m0ZGcb6bl29nGbJWJYXvkl/01---HKE---Design-system?type=design&node-id=6-94&mode=design&t=nWLWH9m1M9iLAfPn-0
  CTA = "CTA",
  Secondary = "Secondary",
  Icon = "Icon",
  Text = "Text",
  Navigation = "Navigation",
  FormButton = "FormButton",
}

export type ButtonProps = {
  label?: string;
  clicked?: boolean;
  variant?: ButtonVariant;
  full?: boolean;
  outlined?: boolean;
  submit?: boolean;
  custom?: boolean;
  fullWidth?: boolean;
  borderRadius?: number | string;
  disabled?: boolean;
  leadingIcon?: ReactElement<SVGProps<SVGSVGElement>>;
  trailingIcon?: ReactElement<SVGProps<SVGSVGElement>>;
  noBorder?: boolean;
  alignAllColors?: boolean;
  size?: TextSize;
  iconSize?: SvgIconSize;
  color?: TextProps["color"];
  white?: boolean;
} & Omit<BaseButtonProps, "leadingIcon" | "trailingIcon">;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      label,
      className,
      clicked,
      custom,
      variant = !custom ? ButtonVariant.Primary : null,
      full,
      outlined,
      submit,
      fullWidth,
      borderRadius,
      disabled,
      leadingIcon,
      trailingIcon,
      noBorder,
      alignAllColors,
      size,
      color,
      white,
      iconSize,
      // Accessibility: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/title
      title,
      ...others
    },
    ref
  ) => {
    const finalTitle = useMemo(() => title || label || getInnerText(children), [title, label, children]);
    const ariaLabel = typeof children === "string" ? children : label;
    const radius = isNumber(borderRadius) ? `${borderRadius}px` : borderRadius;
    const isIconBtn = variant === ButtonVariant.Icon || variant === ButtonVariant.Navigation;
    return (
      <BaseButton
        className={clsx(classes.button, className, {
          [classes.button__cta]: variant === ButtonVariant.CTA,
          // [classes.button__secondary]: variant === ButtonVariant.Secondary,
          [classes.button__secondaryBtn]: variant === ButtonVariant.Secondary,
          [classes.button__icon]: variant === ButtonVariant.Icon,
          [classes.button__navigation]: variant === ButtonVariant.Navigation,
          [classes.button__text]: variant === ButtonVariant.Text,
          [classes.button__formButton]: variant === ButtonVariant.FormButton,
          [classes.button__clicked]: clicked,
          [classes.button__full]: full,
          [classes.button__outlined]: outlined,
          [classes.button__custom]: custom,
          [classes.button__fullWidth]: fullWidth,
          [classes.button__disabled]: disabled,
          [classes.button__noBorder]: noBorder,
          [classes.button__alignAllColors]: alignAllColors,
          [classes.button__white]: white,
        })}
        style={{ borderRadius: radius }}
        type={submit ? "submit" : "button"}
        aria-label={ariaLabel}
        title={finalTitle}
        disabled={disabled}
        {...others}
        ref={ref}
      >
        {leadingIcon && (
          <SvgIcon color={color} size={iconSize || SvgIconSize.Desktop}>
            {leadingIcon}
          </SvgIcon>
        )}
        {!custom ? (
          isIconBtn ? (
            <SvgIcon className={classes.button_contentText} size={iconSize || SvgIconSize.DenseLayout} color={color}>
              {children}
            </SvgIcon>
          ) : (
            <Text className={classes.button_contentText} size={size} color={color} span>
              {children}
            </Text>
          )
        ) : (
          children
        )}
        {trailingIcon && (
          <SvgIcon color={color} size={iconSize || SvgIconSize.Desktop}>
            {trailingIcon}
          </SvgIcon>
        )}
      </BaseButton>
    );
  }
);
Button.displayName = "Button";
