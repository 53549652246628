import { type FC, forwardRef } from "react";

import { useController } from "./useController";

type RequiredFormField = Pick<Parameters<typeof useController>[0], "name">;

export const createFormField = <REF, PROPS>(OriginalComponent: FC<PROPS>) => {
  type InjectingFormField = ReturnType<typeof useController>["field"] &
    ReturnType<typeof useController>["fieldState"] &
    PROPS &
    RequiredFormField;

  const component = forwardRef<REF, PROPS & RequiredFormField>((props, ref) => {
    const { field, fieldState } = useController({ name: props.name });

    return <OriginalComponent {...field} {...fieldState} {...(props as InjectingFormField)} ref={ref} />;
  });

  component.displayName = `FormField:${OriginalComponent.displayName ?? OriginalComponent.name}`;

  return component;
};
