import { yupResolver } from "@hookform/resolvers/yup";
import { FieldValues, Resolver, UseFormProps, useForm as useHookForm } from "react-hook-form";

type YupResolverParams<TFieldValues extends FieldValues = FieldValues> = Parameters<
  typeof yupResolver<TFieldValues>
>[0];

export type FormHookParams<FormValues extends FieldValues = FieldValues> = {
  schema?: YupResolverParams<FormValues>;
} & UseFormProps<FormValues>;

export const useForm = <TFieldValues extends FieldValues = FieldValues>({
  schema,
  resolver,
  ...others
}: FormHookParams<TFieldValues>) => {
  const formResolver = resolver || (schema && yupResolver<TFieldValues>(schema));

  return useHookForm<TFieldValues>({
    mode: "onBlur",
    shouldFocusError: false,
    ...others,
    resolver: formResolver as Resolver<TFieldValues>,
  });
};
