import Link from "next/link";
import classes from "./DestinationCard.module.scss";
import { Image } from "@hkexpressairwayslimited/ui/src";
import { DestinationDetailed } from "./destinationtypes";
import React from "react";
export interface DestinationCardProps {
  destination: DestinationDetailed;
  lang?: string;
}

const DestinationCard = (props: DestinationCardProps) => {
  return (
    <div className={classes.imageContainer}>
      <Link href={`/${props.lang}/explore/destination-guides/${props.destination.path}`} className={classes.link}>
        <Image
          src={props.destination.url}
          width={1440}
          height={1080}
          alt={props.destination.place}
          className={classes.destinationCard}
          priority={true}
        />
      </Link>
      <div className={classes.city}>{props.destination.place}</div>
    </div>
  );
};

export { DestinationCard };
