export enum Typography {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  SUBTITLE1 = "subtitle1",
  SUBTITLE2 = "subtitle2",
  PARAGRAPH = "paragraph",
  REMARK = "remark",
  WARNING = "warning",
}
