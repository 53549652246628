import { SelectRootSlotProps } from "@mui/base/Select";
import { clsx } from "clsx";
import { ReactElement, SVGProps, forwardRef, useCallback, useContext, useEffect, useMemo, useState } from "react";

import {
  AppContext,
  Box,
  Button,
  CaretDown2Icon,
  SearchIcon,
  SvgIcon,
  SvgIconSize,
  TextInput,
  useMediaQueries,
} from "@hkexpressairwayslimited/ui/src";

import classes from "./DropdownButton.module.scss";

import { SelectGroup, SelectOption } from "./Dropdown";

export const DropdownButton = forwardRef(
  <OptionValue extends string, Multiple extends boolean>(
    props: {
      value: string;
      svgIcon?: ReactElement<SVGProps<SVGSVGElement>, "svg">;
      open: boolean;
      canSearch: boolean;
      isGrouping: boolean;
      disabled: boolean;
      options: SelectOption[] | SelectGroup[];
      onSearchInputChange: (value: string) => void;
      onHandleOpenBoxList: () => void;
      onDisplaySelection: (option?: SelectOption) => string | undefined;
      onHandleCloseBoxList: () => void;
      hideArrow: boolean;
      isMounted: boolean;
    } & SelectRootSlotProps<OptionValue, Multiple>,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) => {
    const {
      ownerState,
      className,
      children,
      open,
      svgIcon,
      canSearch,
      isGrouping,
      value,
      options,
      disabled,
      onSearchInputChange,
      onHandleOpenBoxList,
      onHandleCloseBoxList,
      hideArrow,
      isMounted,
      ...others
    } = props;
    const { isInEditor } = useContext(AppContext);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [isEmpty, setIsEmpty] = useState(false);
    const { isDesktop } = useMediaQueries();
    const handleSearchInputChange = (event: any) => {
      setSearchInputValue(event.target.value);
      onSearchInputChange && onSearchInputChange(event.target.value as string);
    };

    const handleFocus = () => {
      setSearchInputValue("");
      setIsEmpty(true);

      onHandleOpenBoxList();
    };

    const handleBlur = () => {
      setIsEmpty(false);
      setSearchInputValue(value as string);
    };

    useEffect(() => {
      setSearchInputValue(value as string);
    }, [value]);

    const getValueLabel = useCallback(
      (value: string) => {
        if (isGrouping) {
          for (let i = 0; i < options.length; i++) {
            const curItem = (options[i] as SelectGroup)?.options?.find(
              (item) => (item as SelectOption).value === value
            );
            if (curItem) {
              return props.onDisplaySelection(curItem as SelectOption);
            }
          }
          return value === (children as string) || isEmpty ? "" : value || "";
        } else {
          const curItem = options?.find((item) => (item as SelectOption).value === value);
          return curItem
            ? props.onDisplaySelection(curItem as SelectOption)
            : value === (children as string) || isEmpty
              ? ""
              : value || "";
        }
      },
      [isGrouping, isEmpty, options, props.onDisplaySelection]
    );
    const inputValue = useMemo(
      () =>
        canSearch
          ? getValueLabel(isEmpty ? "" : searchInputValue || (children as string))
          : getValueLabel(value || (children as string)),
      [value, searchInputValue, isEmpty, canSearch, getValueLabel]
    );

    return (
      // <FieldInputGroup names={names} fullWidth>
      //   <Button className={clsx(className, classes.selectButton)} custom {...others} ref={ref}>
      //     <TextInput placeholder={ownerState.placeholder as string} readOnly value={children as string} />
      //   </Button>
      // </FieldInputGroup>
      <Box className={classes.selectButtonBox} onClick={isInEditor ? onHandleCloseBoxList : undefined}>
        {canSearch && (
          <TextInput
            fullWidth
            icon={
              open && isGrouping && !isDesktop ? (
                <SvgIcon className={classes.iconSize}>
                  <SearchIcon />
                </SvgIcon>
              ) : (
                svgIcon
              )
            }
            className={
              open && isGrouping && !isDesktop && isMounted
                ? clsx(classes.smSearchInputBox, disabled && classes.disabledInput)
                : clsx(classes.selectButtonInput, disabled && classes.disabledInput)
            }
            placeholder={ownerState.placeholder as string}
            displayText={typeof inputValue !== "string" ? inputValue : undefined}
            value={inputValue}
            onChange={handleSearchInputChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        )}
        <Button
          className={clsx(className, classes.selectButton)}
          title={inputValue}
          custom
          {...others}
          disabled={disabled}
          ref={ref}
        >
          <Box className={classes.selectText}>
            <TextInput
              fullWidth
              className={canSearch ? clsx(classes.hidden) : ""}
              icon={svgIcon}
              placeholder={ownerState.placeholder as string}
              readOnly
              displayText={typeof inputValue !== "string" ? inputValue : undefined}
              value={inputValue}
              disabled={disabled}
            />
          </Box>
          {!hideArrow && (
            <SvgIcon size={SvgIconSize.DenseLayout} className={clsx(open && classes.selectIconTriangleUp)}>
              <CaretDown2Icon />
            </SvgIcon>
          )}
        </Button>
      </Box>
    );
  }
);
DropdownButton.displayName = "DropdownButton";
