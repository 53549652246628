import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import InsuranceSvg from "../../assets/icons/insurance.svg";

export function InsuranceIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      {/* credit: plus icon from https://heroicons.com/ */}
      <InsuranceSvg />
    </SvgIcon>
  );
}
