import {
  ArrowDownIcon,
  Box,
  EBorders,
  EPixel,
  EShadows,
  Grid,
  SvgIcon,
  TabsAutomatic,
} from "@hkexpressairwayslimited/ui/src";
import { styled } from "@mui/material";
import { useLayoutEffect, useRef, useState } from "react";
import { BookTrip } from "./bookTrip/BookTrip";
import { CheckIn } from "./checkIn/CheckIn";
import { FlightStatus } from "./flightStatus/FlightStatus";
import { ManageMyBooking } from "./manageMyBooking/ManageMyBooking";

const BookingWrap = styled(Box)(({ theme }) => ({
  border: EBorders.b1,
  borderRadius: EPixel.px16,
  backgroundColor: theme.palette.neutral.white,
  boxShadow: EShadows.s2,
  "& label": {
    display: "initial",
  },
  [theme.breakpoints.down("md")]: {
    border: "none",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));

const ItemHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundColor: "transparent",
  borderTop: "none",
  borderBottom: EBorders.b1,
  borderLeft: "none",
  borderRight: "none",
  padding: EPixel.px16,
  textAlign: "center",
  fontSize: EPixel.px22,
  fontWeight: "bolder",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    borderTop: EBorders.b1,
    borderBottom: EBorders.b1,
    borderLeft: EBorders.b1,
    borderRight: EBorders.b1,
    backgroundColor: theme.palette.neutral.white,
    fontSize: "18px",
    justifyContent: "space-between",
  },
}));

const ItemContent = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: "transparent",
  padding: `${EPixel.px32} ${EPixel.px24}`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  minHeight: "195px",
  [theme.breakpoints.down("md")]: {
    borderRadius: `0 0 ${EPixel.px16} ${EPixel.px16}`,
    backgroundColor: theme.palette.neutral.white,
    borderBottom: EBorders.b1,
    borderLeft: EBorders.b1,
    borderRight: EBorders.b1,
    padding: EPixel.px16,
  },
}));

const Line = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "100%",
  height: EPixel.px4,
  background: theme.palette.primary.main,
  bottom: 0,
  left: 0,
  display: "block",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

export type DepartureChangeEv = (data: DepartureItem) => void;
export type DepartureItem = {
  code: string;
  market: string;
};
const tabsLabel = ["bookATrip", "manageMyBooking", "checkIn", "flightStatus"];
export interface BookingProps {
  pageType?: "IBE" | "B2B" | "AM";
  flightRouteOptions?: any;
  i18nContent?: any;
  handleBookingTripSubmit?: (data: any) => void;
  handleManageMyBookingSubmit?: (data: any) => void;
  handleCheckInSubmit?: (data: any) => void;
  handleFlightStatusSubmit?: (data: any) => void;
  handleManageMyBookingLink?: () => void;
  campaignCode?: string;
  paxMax?: number;
  hasLogin?: boolean;
  curTab?: number;
  onDepartureChange?: DepartureChangeEv;
}

export function Booking({
  pageType,
  flightRouteOptions,
  i18nContent,
  handleBookingTripSubmit,
  handleManageMyBookingLink,
  handleFlightStatusSubmit,
  handleCheckInSubmit,
  handleManageMyBookingSubmit,
  campaignCode,
  paxMax = 9,
  hasLogin = false,
  onDepartureChange,
}: BookingProps) {
  const [curTab, setCurTab] = useState<number>(0);
  // const [isBookingTripShow, setBookingTripShow] = useState<boolean>(true);
  // const [isManageBookingShow, setManageBookingShow] = useState<boolean>(false);
  // const [isCheckInShow, setCheckInShow] = useState<boolean>(false);
  // const [isFlightStatusShow, setFlightStatusShow] = useState<boolean>(false);
  const changeCurTab = (curTab: number) => {
    setCurTab(curTab);
    // if (curTab === 0) {
    //   setBookingTripShow(!isBookingTripShow);
    // } else if (curTab === 1) {
    //   setManageBookingShow(!isManageBookingShow);
    // } else if (curTab === 2) {
    //   setCheckInShow(!isCheckInShow);
    // } else if (curTab === 3) {
    //   setFlightStatusShow(!isFlightStatusShow);
    // }
  };
  const tabs = [
    i18nContent.booking.bookATrip,
    i18nContent.booking.manageMyBooking,
    i18nContent.booking.checkIn,
    i18nContent.booking.flightStatus,
  ];
  const tabsRef = useRef(null);
  useLayoutEffect(() => {
    tabsRef.current && new TabsAutomatic(tabsRef.current, changeCurTab, 1);
  }, []);
  return (
    <BookingWrap>
      <Grid
        container
        direction='row'
        alignItems='stretch'
        ref={tabsRef}
        role='tablist'
        aria-labelledby='search page tabs'
      >
        {tabs.map((item, index) => {
          // const isShowBox =
          //   (index === 0 && isBookingTripShow) ||
          //   (index === 1 && isManageBookingShow) ||
          //   (index === 2 && isCheckInShow) ||
          //   (index === 3 && isFlightStatusShow);
          return (
            <Grid
              key={index}
              item
              md={3}
              xs={12}
              sx={{
                marginBottom: { md: 0, xs: curTab ? 0 : EPixel.px16 },
                order: { md: index + 1, xs: index * 2 + 1 },
                outlineColor: "#59107D",
              }}
              // onClick={() => changeCurTab(index)}
              tabIndex={curTab === index + 1 ? 0 : -1}
              role='tab'
              aria-selected='true'
              aria-controls={`tabpanel-${tabsLabel[index]}`}
            >
              <ItemHeader
                sx={{ borderRadius: { md: 0, xs: curTab ? `${EPixel.px16} ${EPixel.px16} 0 0` : EPixel.px16 } }}
              >
                {item}
                {curTab === index + 1 && <Line />}
                <SvgIcon
                  sx={{
                    display: { md: "none", xs: "block" },
                    transform: curTab ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                >
                  <ArrowDownIcon />
                </SvgIcon>
              </ItemHeader>
            </Grid>
          );
        })}
        <Grid
          id={`tabpanel-${tabsLabel[0]}`}
          item
          xs={12}
          sx={{
            order: { md: 5, xs: 2 },
            marginBottom: { md: 0, xs: EPixel.px16 },
            display: { md: curTab === 1 ? "flex" : "none", xs: curTab ? "flex" : "none" },
          }}
        >
          <ItemContent>
            <BookTrip
              pageType={pageType}
              i18nContent={i18nContent}
              flightRouteOptions={flightRouteOptions}
              handleBookingTripSubmit={handleBookingTripSubmit}
              campaignCode={campaignCode}
              paxMax={paxMax}
              curTab={curTab}
              onDepartureChange={onDepartureChange}
            />
          </ItemContent>
        </Grid>
        <Grid
          id={`tabpanel-${tabsLabel[1]}`}
          item
          xs={12}
          sx={{
            order: { md: 6, xs: 4 },
            marginBottom: { md: 0, xs: EPixel.px16 },
            display: { md: curTab === 2 ? "flex" : "none", xs: curTab ? "flex" : "none" },
          }}
        >
          <ItemContent>
            <ManageMyBooking
              i18nContent={i18nContent}
              handleBookingTripSubmit={handleBookingTripSubmit}
              handleManageMyBookingLink={handleManageMyBookingLink}
              handleManageMyBookingSubmit={handleManageMyBookingSubmit}
              hasLogin={hasLogin}
              curTab={curTab}
            />
          </ItemContent>
        </Grid>
        <Grid
          id={`tabpanel-${tabsLabel[2]}`}
          item
          xs={12}
          sx={{
            order: { md: 7, xs: 6 },
            marginBottom: { md: 0, xs: EPixel.px16 },
            display: { md: curTab === 3 ? "flex" : "none", xs: curTab ? "flex" : "none" },
          }}
        >
          <ItemContent>
            <CheckIn
              i18nContent={i18nContent}
              handleCheckInSubmit={handleCheckInSubmit}
              hasLogin={hasLogin}
              curTab={curTab}
            />
          </ItemContent>
        </Grid>
        <Grid
          id={`tabpanel-${tabsLabel[3]}`}
          item
          xs={12}
          sx={{
            order: { md: 8, xs: 8 },
            marginBottom: { md: 0, xs: EPixel.px16 },
            display: { md: curTab === 4 ? "flex" : "none", xs: curTab ? "flex" : "none" },
          }}
        >
          <ItemContent>
            <FlightStatus
              flightRouteOptions={flightRouteOptions}
              i18nContent={i18nContent}
              handleFlightStatusSubmit={handleFlightStatusSubmit}
              curTab={curTab}
            />
          </ItemContent>
        </Grid>
      </Grid>
    </BookingWrap>
  );
}
