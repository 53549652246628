import * as React from "react";
import clsx from "clsx";
import { styled } from "@mui/material/styles";
import MuiTextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Typography } from "@hkexpressairwayslimited/ui/src";
import { Button } from "../../Atoms/Button/Button";

export interface MuiDisclaimerProps {}
const StyledDisclaimer = styled(MuiTextField)<MuiDisclaimerProps>(({}) => ({}));

export interface DisclaimerProps {
  isOpen: boolean;
  title: string;
  content: string | React.ReactNode;
  acceptBtnText: string;
  onClose: () => void;
  onSubmit: () => void;
}

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "white",
  // border: '2px solid #000',
  boxShadow: 24,
  borderRadius: 2,
  minHeight: 300,
};

const Disclaimer = (props: DisclaimerProps) => {
  const { title, content, isOpen, acceptBtnText } = props;

  const onClickCloseBtn = () => {
    props.onClose && props.onClose();
  };
  const onClickAcceptBtn = () => {
    props.onSubmit && props.onSubmit();
  };

  return (
    <Modal
      open={isOpen}
      // onClose={handleClose}
      keepMounted={true}
    >
      <Grid container direction={"column"} sx={{ ...style }}>
        <Grid item textAlign={"right"} direction={"column"} padding={1}>
          <Button onClick={onClickCloseBtn}>X</Button>
        </Grid>
        <Grid item>
          <Box
            style={{
              padding: "1rem",
              maxHeight: "80vh",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <h3 className={clsx(Typography.H3)}>{title}</h3>
            <p>{content}</p>
          </Box>
        </Grid>
        <Divider />
        <Grid
          item
          justifyContent='center'
          alignItems='center'
          textAlign={"center"}
          direction={"column"}
          rowSpacing={3}
          padding={3}
        >
          <Button onClick={onClickAcceptBtn}>{acceptBtnText}</Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export { Disclaimer };
