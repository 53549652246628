export const decodeTokenPayLoad = <ReturnObject extends Record<string, unknown> = any>(token: string) => {
  const arrayToken = token.split(".");
  const payloadToken = arrayToken[1];

  if (!payloadToken) {
    return undefined;
  }
  const tokenPayload: ReturnObject = JSON.parse(atob(arrayToken[1]));

  return tokenPayload;
};
