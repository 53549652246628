import { EBorders, ERadius, Title, TitleOrders, useMediaQueries } from "@hkexpressairwayslimited/ui/src";
import { Box, CardTypeMap, Card as MuiCard, CardProps as MuiCardProps } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Theme, styled } from "@mui/material/styles";
import { clsx } from "clsx";
import { ForwardedRef, ReactNode, forwardRef } from "react";
import { EPixel } from "../../../themes/spacing";

import classes from "./Card.module.scss";
import { fsMapping, getSpacing, iconFSMapping, propTrueValue, slotMapping } from "./common";
type BorderVariant = "default" | "active" | "secondary" | "disabled" | "error";
export type CardProps = {
  // borderradiusless?: boolean | string;
  borderless?: boolean | string;
  bodC?: BorderVariant;
  size?: "l" | "m" | "s";
  withBGC?: boolean | string | "primary-light" | "secondary" | "tertiary";
  widthMedia?: boolean | string;
  noPadding?: boolean | string;
  children?: ReactNode;
  ref?: ForwardedRef<HTMLDivElement>;
  overflow?: boolean;
} & MuiCardProps;

const bgcolorMapping: Record<string, (t: Theme) => string | undefined> = {
  "primary-light": (t: Theme) => t.palette.purple.surface,
  secondary: (t: Theme) => t.palette.blue.surface,
  tertiary: (t: Theme) => t.palette.purple.surfaceSubdued,
};

const borderMapping = {
  default: EBorders.b1,
  active: EBorders.b2,
  secondary: EBorders.secondary,
  disabled: EBorders.disabled,
  error: EBorders.error,
};

const StyledCard = styled<OverridableComponent<CardTypeMap<CardProps, "div">>>(MuiCard)(({
  theme,
  borderless,
  // borderradiusless,
  noPadding,
  withBGC,
  size = "m",
  bodC = "default",
  overflow = false,
}) => {
  const { isMobile } = useMediaQueries();
  const border = propTrueValue(borderless) ? "none" : borderMapping[bodC] ?? "none";
  const padding = propTrueValue(noPadding) ? "unset" : getSpacing(size, isMobile);
  let backgroundColor = theme.palette.neutral.white;
  if (typeof withBGC === "string" && withBGC !== "" && bgcolorMapping[withBGC]) {
    backgroundColor = bgcolorMapping[withBGC](theme) ?? theme.palette.neutral.white;
  } else {
    backgroundColor = propTrueValue(withBGC) ? theme.palette.neutral.borderSubdued : theme.palette.neutral.white;
  }
  return {
    border: border,
    borderRadius: ERadius.r300,
    boxShadow: "none",
    padding: 0,
    backgroundColor,
    display: "flex",
    flexDirection: "column",
    overflow: overflow ? "auto" : "unset",
    ["& > .MuiCardHeader-root > .MuiSvgIcon-root"]: {
      fontSize: iconFSMapping[size],
    },
    ["& > .MuiCardHeader-root > .MuiTypography-root"]: {
      ...theme.typography.h4,
      fontSize: fsMapping[size],
      marginTop: EPixel.px4,
    },
    ["& .MuiCardContent-root:last-of-type"]: {
      paddingBottom: 0,
    },
    ["& > div.HeaderWrapper"]: {
      flex: "0 0 auto",
      padding,
      borderBottom: border,
    },
    ["& > div.ContentWrapper"]: {
      padding,
      flex: "1",
    },
    ["& > div.FooterWrapper"]: {
      flex: "0 0 auto",
      padding,
      borderTop: border,
      borderRadius: "0 0 16px 16px",
    },
    ["& div.MediaContent"]: {
      padding: getSpacing(size),
    },
  };
});
export const Card = forwardRef<HTMLElement, CardProps>(({ children, ...rest }, ref) => {
  const slots = slotMapping(children);
  return (
    <StyledCard {...rest} ref={ref as any}>
      {slots.header && slots.header}
      {slots.content && slots.content}
      {slots.footer && slots.footer}
    </StyledCard>
  );
});
Card.displayName = "Card";

export enum CardTypes {
  Shadow1 = "Shadow1",
  Shadow2 = "Shadow2",
}

export type CardPropsV1 = {
  type?: CardTypes;
  full?: boolean;
  title?: ReactNode;
  titleNode?: ReactNode;
  children?: ReactNode;
};

export const CardV1 = forwardRef<HTMLElement, CardPropsV1>(
  ({ type = CardTypes.Shadow1, full, title, titleNode, children, ...others }, ref) => (
    <Box
      component='section'
      className={clsx(classes.card, {
        [classes.card__shadow1]: type === CardTypes.Shadow1,
        [classes.card__shadow2]: type === CardTypes.Shadow2,
        [classes.card__full]: full,
        [classes.card__hasTitle]: title || titleNode,
      })}
      {...others}
      ref={ref}
    >
      {title ? (
        <Title className={classes.card_title} order={TitleOrders.Subtitle}>
          {title}
        </Title>
      ) : (
        titleNode
      )}
      <Box className={classes.card_content}>{children}</Box>
    </Box>
  )
);
CardV1.displayName = "CardV1";
