import {
  useController as useHookController,
  useFormContext,
  FieldValues,
  FieldPath,
  UseControllerProps,
  UseControllerReturn,
} from "react-hook-form";

export const useController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: UseControllerProps<TFieldValues, TName>
): Partial<UseControllerReturn<TFieldValues, TName>> => {
  const formContext = useFormContext<TFieldValues>();

  if (!formContext) return {};

  return useHookController<TFieldValues, TName>(props);
};
