import { CathayIcon } from "@hkexpressairwayslimited/ui/src";
import { EPixel } from "@hkexpressairwayslimited/ui/src/themes";
import { Stack } from "@mui/material";
import { forwardRef, ReactNode } from "react";
import { Button, ButtonVariant, Modal, ModalHandle, ModalProps } from "../..";

const Style = {
  outline: "1px solid #006B6E !important",
  color: "#006B6E !important",
};

interface CustomProps {
  id?: string;
  title: string | JSX.Element | JSX.Element[];
  children: ReactNode;
  ctaText?: string | JSX.Element | JSX.Element[];
  onClickCta?: () => void;
  ctaButtonDisabled?: boolean;
  ctaText2?: string | JSX.Element | JSX.Element[];
  onClickCta2?: () => void;
  onClose?: ModalProps["onClose"];
  isSetMaxHeight?: boolean;
  hasAModal?: boolean;
}

export const MessageBox = forwardRef<ModalHandle, CustomProps>((props: CustomProps, ref) => {
  function close(): void {
    props.onClickCta && props.onClickCta();
  }
  function close2(): void {
    props.onClickCta2 && props.onClickCta2();
  }

  return (
    <Modal isSetMaxHeight={props.isSetMaxHeight} title={props.title} onClose={props.onClose} ref={ref}>
      <Stack>
        {props.children}
        {props.ctaText && (
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            sx={{ mt: props.children ? EPixel.px16 : 0, padding: "1px" }}
          >
            <Button
              disabled={props?.ctaButtonDisabled}
              onClick={() => {
                close();
              }}
              fullWidth
              leadingIcon={props.hasAModal ? <CathayIcon /> : undefined}
              outlined={props.hasAModal}
              style={props.hasAModal ? Style : {}}
            >
              {props.ctaText}
            </Button>

            {/* <Button outlined onClick={() => close()}>
          Cancel
        </Button> */}
          </Stack>
        )}
        {props.ctaText2 && (
          <Stack direction='row' justifyContent='center' alignItems='center' sx={{ mt: EPixel.px12, padding: "2px" }}>
            <Button
              onClick={() => {
                close2();
              }}
              fullWidth
              borderRadius='24px'
              variant={ButtonVariant.Secondary}
            >
              {props.ctaText2}
            </Button>
          </Stack>
        )}
      </Stack>
    </Modal>
  );
});
MessageBox.displayName = "MessageBox";
