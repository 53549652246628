import { StyledEngineProvider, ThemeProvider, createTheme } from "@mui/material/styles";
import mediaQuery from "css-mediaquery";
import { ReactNode, useMemo } from "react";
import parser from "ua-parser-js";

import { constants } from "../styles";
import { lightTheme } from "./light";

export type HKEStyleProviderProps = { children?: ReactNode; userAgent?: string; isMobile?: boolean };

/**
 * Control the Global style injection
 * MUI - https://mui.com/material-ui/integrations/nextjs/
 */
export const HKEStyleProvider = ({ children, userAgent, isMobile }: HKEStyleProviderProps) => {
  const theme = useMemo(() => {
    const userAgentStr = typeof window === "undefined" ? userAgent : window.navigator.userAgent;
    const deviceType = parser(userAgentStr).device.type || "desktop";
    const ssrMatchMedia = (query: string) => {
      return {
        matches: mediaQuery.match(query, {
          width: isMobile
            ? constants.screenMobileMaxValue
            : deviceType === "desktop"
              ? constants.screenDesktopMinValue
              : constants.screenMobileMaxValue,
        }),
      };
    };
    return createTheme({
      ...lightTheme,
      components: {
        ...lightTheme.components,
        MuiUseMediaQuery: {
          defaultProps: {
            noSsr: true,
            ssrMatchMedia,
          },
        },
      },
    });
  }, [userAgent]);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
