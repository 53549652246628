/* eslint-disable @typescript-eslint/ban-types */
import InputLabel from "@mui/material/InputLabel";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { FormField } from "../../Atoms/FormField/FormField";
import { StyledMenuItem, StyledSelect } from "../../Atoms/Select/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { FormLabel } from "@mui/material";

export interface SelectProps {
  id: string;
  label: string;
  itemList?: { [key: string]: string };
  defaultValue?: string;
  onChange?: Function;
  isError?: boolean;
  helperText?: string;
}
const SelectField = (props: SelectProps) => {
  return (
    <FormField>
      <InputLabel error={props.isError} id={props.id}>
        {props.label}
      </InputLabel>
      <StyledSelect
        error={props.isError}
        labelId={props.id}
        id={props.id}
        defaultValue={props.defaultValue}
        label={props.label}
        onChange={(e) => props.onChange && props.onChange(e)}
      >
        {props.itemList &&
          Object.entries(props.itemList).map(([key, value], index) => {
            return (
              <StyledMenuItem value={key} key={index}>
                {value}
              </StyledMenuItem>
            );
          })}
      </StyledSelect>
      {props.helperText && <FormHelperText error={props.isError}>{props.helperText}</FormHelperText>}
    </FormField>
  );
};

export { SelectField };
