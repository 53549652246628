import {
  AccountIcon,
  ArrowDownIcon,
  Button,
  ButtonVariant,
  CalendarSvg,
  Container,
  EFontSize,
  EPaxType,
  ESpacing,
  ETripType,
  Font,
  PassengerCount,
  PaxTypeI18n,
  Trip,
} from "@hkexpressairwayslimited/ui/src";
import { Box, Divider, Grid, Stack, SvgIcon, styled } from "@mui/material";
import { formatDate } from "date-fns";
import { DetailsBarProps } from "./d";
import { enUS, zhHK, zhCN, ja, ko } from "date-fns/locale";
import { useParams } from "next/navigation";
const locales = {
  en: enUS,
  "en-HK": enUS,
  ja: ja,
  "zh-CN": zhCN,
  "zh-HK": zhHK,
  "zh-TW": zhHK,
  ko: ko,
};
type LocaleKey = keyof typeof locales;

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.purple.default,
  color: theme.palette.neutral.white,
  padding: `${ESpacing._xs} 0`,
  ...theme.typography.p1,
}));

function TimeDetail({ trips, locale }: { trips?: Trip[]; locale?: LocaleKey }) {
  const lang = (useParams().lang as LocaleKey) || "en";
  if (trips) {
    return (
      <>
        <SvgIcon sx={{ mr: ESpacing._2xs }}>
          <CalendarSvg />
        </SvgIcon>
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {trips.map((e, idx) => (
            <>
              {/* {idx !== 0 && <Box>-</Box>} */}
              <Box
                sx={{
                  fontSize: {
                    xs: "14px",
                    md: "16px",
                  },
                  minWidth: {
                    xs: "max-content",
                    md: "unset",
                  },
                  display: "flex",
                  fontWeight: "400",
                }}
                key={e.date}
              >
                {formatDate(new Date(e.date), "E, dd MMM yyyy", { locale: locales[lang] || enUS })}
                {trips.length > 1 && idx === 0 && <Box margin={`0 6px`}>-</Box>}
              </Box>
            </>
          ))}
        </Box>
      </>
    );
  }
  return null;
}
function stringifyPassenger(passenger_count?: PassengerCount, paxTypeI18n?: PaxTypeI18n): string | null {
  if (!passenger_count) return null;
  return Object.entries(passenger_count)
    .map((e) => `${e[1]} ${paxTypeI18n ? paxTypeI18n[e[0] as EPaxType] ?? e[0] : e[0]}`)
    .join(", ");
}
export function DetailsBar({ i18n, searchingDetail, disableSearch = false, onStartSearchClick }: DetailsBarProps) {
  return (
    <Wrapper>
      <Container>
        <Grid container alignItems='center' justifyContent='space-between' direction='row' rowSpacing={ESpacing._3xs}>
          <Grid item>
            <Stack direction='row' alignItems='center' spacing={ESpacing._s}>
              <Box fontWeight='fontWeightBold'>
                {searchingDetail?.trips
                  .map((e, index) => {
                    if (index > 0 && searchingDetail.tripType !== ETripType.multiCity) {
                      return null;
                    }
                    const from = e.from === "BK1" ? "BKK" : e.from;
                    const to = e.to === "BK1" ? "BKK" : e.to;
                    return `${from} - ${to}`;
                  })
                  .filter((e) => e !== null)
                  .join(" - ")}
              </Box>
              <Stack display={["none", "none", "flex"]} direction='row' alignItems='center' spacing={ESpacing._s}>
                <Box>
                  <Divider
                    orientation='vertical'
                    flexItem
                    sx={{
                      height: ESpacing._s,
                      borderColor: "neutral.borderNeutral",
                    }}
                  />
                </Box>
                <TimeDetail trips={searchingDetail?.trips} />
                <Box>
                  <Divider
                    orientation='vertical'
                    flexItem
                    sx={{
                      height: EFontSize.p1,
                      borderColor: "neutral.borderNeutral",
                    }}
                  />
                </Box>
                <Stack direction='row' alignItems='center' spacing={ESpacing._s}>
                  <SvgIcon sx={{ color: "neutral.white" }}>
                    <AccountIcon />
                  </SvgIcon>
                  <Font>{stringifyPassenger(searchingDetail?.passenger_count, i18n.paxType)}</Font>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item>
            {!disableSearch && (
              <Button
                style={{ padding: `0 ${ESpacing._s}` }}
                variant={ButtonVariant.Primary}
                onClick={onStartSearchClick}
              >
                <Stack spacing={ESpacing._2xs} direction='row' alignItems='center'>
                  {i18n.newSearch}
                  <SvgIcon sx={{ color: "neutral.white", marginLeft: ESpacing._s + "!important", fontSize: "18px" }}>
                    <ArrowDownIcon />
                  </SvgIcon>
                </Stack>
              </Button>
            )}
          </Grid>
          <Grid display={["block", "block", "none"]} item xs={12}>
            <Stack direction='row' alignItems='center' spacing={ESpacing._s}>
              <TimeDetail trips={searchingDetail?.trips} />
            </Stack>
          </Grid>
          <Grid display={["block", "block", "none"]} item xs={12}>
            <Stack direction='row' alignItems='row' spacing={ESpacing._s}>
              <SvgIcon sx={{ color: "neutral.white" }}>
                <AccountIcon />
              </SvgIcon>
              <Box
                sx={{
                  fontSize: {
                    xs: "14px",
                    md: "16px",
                  },
                }}
              >
                {stringifyPassenger(searchingDetail?.passenger_count, i18n.paxType)}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Wrapper>
  );
}
