import { ReactNode, useState, useMemo, useRef, MouseEvent, cloneElement, useEffect } from "react";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import FlightLandOutlinedIcon from "@mui/icons-material/FlightLandOutlined";
import Popper from "@mui/material/Popper";
import { clsx } from "clsx";
import { nanoid } from "nanoid";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import { TextInput } from "../../../Atoms/TextInput";
import { Box } from "../../../Atoms/Box";
import { Title } from "../../../Atoms/Title";
import { Link } from "../../../Atoms/Link";
import { Section } from "../../Section";

import classes from "./FromToField.module.scss";

export type Option = { value: string; label: string };

export type PanelOptions = { group?: string; options: Option[] }[];

export type PopperPanel = {
  title?: string;
  options: PanelOptions;
};

export type FromToFieldProps = {
  className?: string;
  divider?: ReactNode;
  from: string;
  to: string;

  fromPanel: PopperPanel;
  toPanel: PopperPanel;

  value?: FromToFieldValue;
  onChange?: (value?: FromToFieldValue) => void;
};

export enum Fields {
  From = "From",
  To = "To",
}

export type FromToFieldValue = {
  from?: string;
  to?: string;
};

export const FromToField = ({
  className,
  divider,
  value,
  onChange,
  from,
  to,
  fromPanel,
  toPanel,
}: FromToFieldProps) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>();
  const [fieldValues, setFieldValues] = useState<FromToFieldValue>(value || {});
  const [activeField, setActiveField] = useState<Fields | null>();
  const [arrowEl, setArrowEl] = useState<Element | null>();

  const handleFieldClick = (event: MouseEvent<HTMLDivElement>, field: Fields) => {
    setAnchorEl(event.currentTarget);
    setActiveField(field);
  };

  const handleClosePanel = () => {
    setAnchorEl(null);
    setActiveField(null);
  };

  const handleOptionClick = (event: MouseEvent<HTMLAnchorElement | HTMLSpanElement>, field: Fields, option: Option) => {
    event.preventDefault();
    let newValues = { ...fieldValues };
    if (field === Fields.From) {
      newValues = { ...newValues, from: option.value };
    } else if (field === Fields.To) {
      newValues = { ...newValues, to: option.value };
    }
    setFieldValues(newValues);
    if (onChange) {
      onChange(newValues);
    }
    handleClosePanel();
  };

  useEffect(() => {
    setFieldValues(value || {});
  }, [value]);

  const panelMap = useMemo(
    () => ({
      [Fields.From]: {
        content: fromPanel,
        anchorId: nanoid(),
      },
      [Fields.To]: {
        content: toPanel,
        anchorId: nanoid(),
      },
    }),
    [fromPanel, toPanel]
  );
  const renderPopperPanel = (field: Fields, panel: (typeof panelMap)[keyof typeof panelMap]) => (
    <Popper
      id={panel.anchorId}
      open={field === activeField}
      anchorEl={anchorEl}
      className={classes.fromToField_panelWrapper}
      placement='bottom'
      disablePortal={true}
      modifiers={[
        {
          name: "flip",
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: "viewport",
          },
        },
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: "viewport",
          },
        },
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowEl,
          },
        },
      ]}
    >
      <Box className={classes.arrow} ref={setArrowEl} />
      <Section>
        <ClickAwayListener onClickAway={handleClosePanel}>
          <Box className={classes.fromToField_panel}>
            <Title className={classes.fromToField_panelTitle} order={2}>
              {panel.content.title}
            </Title>
            <Box className={classes.fromToField_panelOptionList}>
              {panel.content.options.map((optionConfig) => (
                <Box className={classes.fromToField_panelOptionGroup} key={optionConfig.group}>
                  <Title order={3}>{optionConfig.group}</Title>
                  <Box>
                    {optionConfig.options.map((option) => (
                      <Link
                        className={classes.fromToField_panelOption}
                        href='#'
                        key={option.value}
                        onClick={(event) => handleOptionClick(event, field, option)}
                      >
                        {option.label.replace("{{code}}", option.value)}
                      </Link>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </ClickAwayListener>
      </Section>
    </Popper>
  );

  return (
    <Box className={clsx(classes.fromToField, className)}>
      <Box className={classes.fromToField_subField} aria-describedby={panelMap[Fields.From].anchorId}>
        <TextInput
          className={classes.fromToField_inputWrapper}
          icon={<FlightTakeoffOutlinedIcon />}
          placeholder={from}
          readOnly
          value={fieldValues.from}
          onClick={(event: MouseEvent<HTMLDivElement>) => handleFieldClick(event, Fields.From)}
        />
        {renderPopperPanel(Fields.From, panelMap[Fields.From])}
      </Box>

      <Box className={classes.fromToField_divider}>{divider}</Box>

      <Box className={classes.fromToField_subField} aria-describedby={panelMap[Fields.To].anchorId}>
        <TextInput
          className={classes.fromToField_inputWrapper}
          icon={<FlightLandOutlinedIcon />}
          placeholder={to}
          readOnly
          value={fieldValues.to}
          onClick={(event: MouseEvent<HTMLDivElement>) => handleFieldClick(event, Fields.To)}
        />
        {renderPopperPanel(Fields.To, panelMap[Fields.To])}
      </Box>
    </Box>
  );
};
