import * as React from "react";
import { forwardRef, ComponentProps, ElementType } from "react";
import { clsx } from "clsx";

import { Box, BoxProps } from "../Box";

import classes from "./Title.module.scss";
import { colors } from "@hkexpressairwayslimited/ui/src";

export type TitleOrder =
  | TitleOrders.Heading
  | TitleOrders.HeadingSmall
  | TitleOrders.Subtitle
  | TitleOrders.SubtitleSmall;
export type TitleSize = React.CSSProperties["fontSize"];
export type TitleColor = React.CSSProperties["color"];

export type TitleProps = {
  /** Determines which tag will be used (h1-h6), controls `font-size` style if `size` prop is not set, `1` by default */
  order?: TitleOrder;

  /** Changes title size, if not set, then size is controlled by `order` prop */
  size?: TitleSize;

  color?: TitleColor;

  center?: boolean;
} & ComponentProps<"h1"> &
  ComponentProps<"h2"> &
  ComponentProps<"h3"> &
  ComponentProps<"h4">;

// New Design: https://www.figma.com/file/m0ZGcb6bl29nGbJWJYXvkl/01---HKE---Design-system?type=design&node-id=1-193&mode=design&t=lfh4L66eylpYJBVE-0
export enum TitleOrders {
  // Obsolete
  Heading = 1,
  HeadingSmall = 2,
  Subtitle = 3,
  SubtitleSmall = 4,

  // New
  H0DisplayLarge = 1,
  H1DisplayMedium = 2,
  H2Headline = 3,
  H3Title = 4,
}

export const Title = forwardRef<HTMLHeadingElement, TitleProps>((props, ref) => {
  const {
    className,
    style = {},
    order = TitleOrders.H0DisplayLarge,
    size,
    color = colors.actionPurpleFocus,
    center,
    ...others
  } = props;

  if (!props.children) {
    return null;
  }
  if (order && !Object.values(TitleOrders).includes(order)) {
    return null;
  }

  const component = `h${order}` as ElementType;

  return (
    <Box
      className={clsx(
        classes.title,
        {
          [classes.title__center]: center,
        },
        className
      )}
      component={component}
      style={{
        fontSize: size,
        color,
        ...style,
      }}
      {...others}
      ref={ref}
    />
  );
});
Title.displayName = "Title";

export default Title;
