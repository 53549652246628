/* Obsolete */

export enum Spacing {
  mt8 = "mt8",
  mt16 = "mt16",
  mt32 = "mt32",
  mt40 = "mt40",
  mt48 = "mt48",
  mt56 = "mt56",
  mt64 = "mt64",
  mt72 = "mt72",
  mt80 = "mt80",
  mb8 = "mb8",
  mb16 = "mb16",
  mb32 = "mb32",
  mb40 = "mb40",
  mb48 = "mb48",
  mb56 = "mb56",
  mb64 = "mb64",
  mb72 = "mb72",
  mb80 = "mb80",
  ml8 = "ml8",
  ml16 = "ml16",
  ml32 = "ml32",
  ml40 = "ml40",
  ml48 = "ml48",
  ml56 = "ml56",
  ml64 = "ml64",
  ml72 = "ml72",
  ml80 = "ml80",
  mr8 = "mr8",
  mr16 = "mr16",
  mr32 = "mr32",
  mr40 = "mr40",
  mr48 = "mr48",
  mr56 = "mr56",
  mr64 = "mr64",
  mr72 = "mr72",
  mr80 = "mr80",
  pt8 = "pt8",
  pt16 = "pt16",
  pt32 = "pt32",
  pt40 = "pt40",
  pt48 = "pt48",
  pt56 = "pt56",
  pt64 = "pt64",
  pt72 = "pt72",
  pt80 = "pt80",
  pb8 = "pb8",
  pb16 = "pb16",
  pb32 = "pb32",
  pb40 = "pb40",
  pb48 = "pb48",
  pb56 = "pb56",
  pb64 = "pb64",
  pb72 = "pb72",
  pb80 = "pb80",
  pl8 = "pl8",
  pl16 = "pl16",
  pl32 = "pl32",
  pl40 = "pl40",
  pl48 = "pl48",
  pl56 = "pl56",
  pl64 = "pl64",
  pl72 = "pl72",
  pl80 = "pl80",
  pr8 = "pr8",
  pr16 = "pr16",
  pr32 = "pr32",
  pr40 = "pr40",
  pr48 = "pr48",
  pr56 = "pr56",
  pr64 = "pr64",
  pr72 = "pr72",
  pr80 = "pr80",
}
