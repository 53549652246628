export interface StepperProps {
  index?: number;
  steppers: StepperItemData[];
  onClick?: (index: number, active: number) => void;
  beforeItemClick?: (currentIdx: number, clickedIdx: number) => boolean;
}
export interface StepperItemData {
  label?: string | number;
  desc?: string | JSX.Element | JSX.Element[];
  onClick?: () => void;
}
export enum EStepperItemStatus {
  checked = "checked",
  disable = "disable",
  active = "active",
}
