import { clsx } from "clsx";
import { DestinationCard } from "../../Molecules/DestinationCard/DestinationCard";
import { DestinationDetailed } from "../../Molecules/DestinationCard/destinationtypes";
import classes from "./GridCard.module.scss";

export type GridCardGroupProps = {
  destinations: DestinationDetailed[];
  position: string;
  lang?: string;
};

const GridCard = ({ destinations, position, lang }: GridCardGroupProps) => {
  return (
    <div
      className={clsx(classes.grid, {
        [classes.grid_left]: position === "left",
        [classes.grid_right]: position === "right",
      })}
    >
      <div className={classes.largeCard}>
        <DestinationCard destination={destinations[0]} lang={lang} />
      </div>
      <div>
        <DestinationCard destination={destinations[1]} lang={lang} />
      </div>
      <div>
        <DestinationCard destination={destinations[2]} lang={lang} />
      </div>
    </div>
  );
};

export { GridCard };
