import { createContext } from "react";

export type AppContextValue = {
  isInEditor: boolean;
  isPreview: boolean;
  editMode?: "metadata" | string;
  breadcrumbs?: { href: string; title: string }[];
  blurImageList?: Record<string, string>;
  searchCurrency?: string;
  setSearchCurrency?: (value: string) => void;
};

export const AppContext = createContext<AppContextValue>({
  editMode: undefined,
  isInEditor: false,
  isPreview: false,
  breadcrumbs: [],
  blurImageList: {},
});
