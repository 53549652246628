import { Box, Grid } from "@hkexpressairwayslimited/ui/src";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, Typography } from "@mui/material";

interface PaymentOptionsSignInProps {
  title: string;
  brand: string;
}

const PaymentOptionsSignIn = ({ title, brand }: PaymentOptionsSignInProps) => {
  return (
    <Grid container sx={{ border: "1px solid lightgrey" }}>
      <Grid item xs={3}>
        <Box>
          <span>{brand}</span>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box padding={8}>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <Typography variant='h6' color={"#702B91"}>
                  {title}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={"flex"} flexDirection={"row"}>
                <Box display={"flex"} flexDirection={"column"}>
                  <AddCircleIcon />
                  <span>new</span>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display={"flex"} flexDirection={"row"}>
                <Box display={"flex"} flexDirection={"column"}>
                  <AddCircleIcon />
                  <span>more details</span>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box>
          <Button
            variant='outlined'
            sx={{
              color: "#702B91",
              borderColor: "#702B91",
              borderRadius: 0,
              marginTop: "110px",
              marginRight: "24px",
            }}
            fullWidth
          >
            Sign in
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export { PaymentOptionsSignIn };
