import * as React from "react";
import arrowDown from "../../../assets/icons/arrow-down.svg";
import arrowUp from "../../../assets/icons/arrow-up.svg";
import { Image } from "../../Atoms/Image";
import classes from "./AccordionHKE.module.scss";

export interface AccordionHKEProps {
  trigger_title: string;
  children?: React.ReactNode;
}

const AccordionHKE = (props: AccordionHKEProps) => {
  const [icon, setIcon] = React.useState(arrowDown);
  const [isExpand, setisExpand] = React.useState(false);
  function handleIsExpand() {
    const temp = !isExpand;
    setisExpand(temp);
    isExpand ? setIcon(arrowDown) : setIcon(arrowUp);
  }

  return (
    <div className={classes.accordion}>
      <div className={classes.accordion_header} onClick={handleIsExpand}>
        <div className={classes.accordion_trigger}>
          <h3 className={classes.accordion_trigger_title}>{props.trigger_title}</h3>
          <Image className={classes.accordion_trigger_ico} src={icon} alt={icon} />
        </div>
      </div>
      {isExpand && <div>{props.children}</div>}
    </div>
  );
};

export { AccordionHKE };
