import {
  AccountOutLined,
  BaggageSvg,
  Box,
  Button,
  ButtonVariant,
  EPixel,
  FieldInputGroup,
  FlightChangeSvg,
  FlightRestriction,
  Form,
  Grid,
  MealIcon,
  SeatSvg,
  SvgIcon,
  Text,
  TextInput,
  useDesktopQuery,
  useForm,
} from "@hkexpressairwayslimited/ui/src";
import { Divider, styled } from "@mui/material";
import * as yup from "yup";

const StyledButton = styled(Button)(({ theme }) => ({
  minHeight: "56px !important",
  minWidth: "initial !important",
  padding: "0 4px !important",
}));

enum Fields {
  BookingRef = "BookingRef",
  LastName = "LastName",
  FirstName = "FirstName",
}

const defaultValues = {
  [Fields.BookingRef]: "",
  [Fields.LastName]: "",
  [Fields.FirstName]: "",
};

type FormValues = typeof defaultValues;

export function FindBooking({
  handleFindBookingSubmit,
  i18nContent,
}: {
  handleFindBookingSubmit: (data: any) => void;
  i18nContent: any;
}) {
  const FormSchemas = {
    [Fields.BookingRef]: yup
      .string()
      .required(i18nContent.findBooking.bookingReferenceRequired)
      .matches(/^[a-zA-Z0-9]{6}$/, i18nContent.findBooking.bookingReferenceInvalid),
    [Fields.LastName]: yup
      .string()
      .required(i18nContent.findBooking.lastNameRequired)
      .matches(/^[a-zA-Z\s]*$/, i18nContent.findBooking.lastNameInvalid),
    [Fields.FirstName]: yup
      .string()
      .required(i18nContent.findBooking.firstNameRequired)
      .matches(/^[a-zA-Z\s]*$/, i18nContent.findBooking.firstNameInvalid),
  };
  const formHook = useForm<FormValues>({
    defaultValues,
    schema: yup.object().shape(FormSchemas),
  });

  const handleSubmit = (formValues: any) => {
    // console.log(formValues, "formValues");
    handleFindBookingSubmit && handleFindBookingSubmit(formValues);
  };
  const isDesktop = useDesktopQuery();
  return (
    <Box sx={{ padding: isDesktop ? `${EPixel.px32} ${EPixel.px24}` : EPixel.px16 }}>
      <Box sx={{ mb: EPixel.px16 }}>
        <Text>{i18nContent.findBooking.findBookingText}</Text>
      </Box>
      <Form formHook={formHook} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FieldInputGroup
                  fullWidth
                  names={[Fields.BookingRef]}
                  helperText={i18nContent.findBooking.bookingReference}
                >
                  <TextInput fullWidth placeholder={i18nContent.findBooking.bookingReference} />
                </FieldInputGroup>
              </Grid>
              <Grid item xs={12}>
                <FieldInputGroup fullWidth names={[Fields.LastName]} helperText={i18nContent.findBooking.lastName}>
                  <TextInput fullWidth placeholder={i18nContent.findBooking.lastName} />
                </FieldInputGroup>
              </Grid>
              <Grid item xs={12}>
                <FieldInputGroup fullWidth names={[Fields.FirstName]} helperText={i18nContent.findBooking.firstName}>
                  <TextInput fullWidth placeholder={i18nContent.findBooking.firstName} />
                </FieldInputGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} display='flex' alignItems='flex-start'>
            <StyledButton
              disabled={!formHook.formState.isValid}
              borderRadius={EPixel.px28}
              submit
              fullWidth
              variant={ButtonVariant.Primary}
            >
              {i18nContent.findBooking.retrieveBooking}
            </StyledButton>
          </Grid>
        </Grid>
      </Form>
      <Divider
        sx={{
          height: "1px",
          backgroundColor: "neutral.borderNeutral",
          margin: `${EPixel.px16} 0`,
        }}
      />
      <Box sx={{ paddingBottom: EPixel.px16 }}>{i18nContent.findBooking.following}</Box>
      <Grid container spacing={2}>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <SvgIcon sx={{ width: EPixel.px24, height: EPixel.px24, color: "#702B91", marginRight: EPixel.px8 }}>
            <BaggageSvg />
          </SvgIcon>
          {i18nContent.findBooking.baggages}
        </Grid>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <SvgIcon sx={{ width: EPixel.px24, height: EPixel.px24, color: "#702B91", marginRight: EPixel.px8 }}>
            <FlightChangeSvg />
          </SvgIcon>
          {i18nContent.findBooking.changeFlight}
        </Grid>

        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <SvgIcon sx={{ width: EPixel.px24, height: EPixel.px24, color: "#702B91", marginRight: EPixel.px8 }}>
            <MealIcon />
          </SvgIcon>
          {i18nContent.findBooking.meals}
        </Grid>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <SvgIcon sx={{ width: EPixel.px24, height: EPixel.px24, color: "#702B91", marginRight: EPixel.px8 }}>
            <FlightRestriction />
          </SvgIcon>
          {i18nContent.findBooking.cancelFlight}
        </Grid>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <SvgIcon sx={{ width: EPixel.px24, height: EPixel.px24, color: "#702B91", marginRight: EPixel.px8 }}>
            <AccountOutLined />
          </SvgIcon>
          {i18nContent.findBooking.customerDetails}
        </Grid>
        <Grid item xs={6} display={"flex"} alignItems={"center"}>
          <SvgIcon sx={{ width: EPixel.px24, height: EPixel.px24, color: "#702B91", marginRight: EPixel.px8 }}>
            <SeatSvg />
          </SvgIcon>
          {i18nContent.findBooking.seatSelection}
        </Grid>
      </Grid>
    </Box>
  );
}
