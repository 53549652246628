import { Box, ClickAwayListener, PopoverProps, Popper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { EPixel } from "../../../themes";
import { Container } from "../Container";

const PopperWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutral.white,
  boxShadow: "0 0 8px 0 rgba(0,0,0,.27);",
  transition: "opacity .14s ease-in-out,top .27s ease-in-out;",
  marginBottom: EPixel.px16,
}));

const StyledPopover = styled(Popper)(({ theme }) => ({
  "&.base-Popper-root": {
    width: "100%",
    backgroundColor: theme.palette.neutral.white,
  },
}));

export function CenterPopover({
  children,
  footer,
  onClickAway = () => {},
  ...others
}: { footer?: JSX.Element; onClickAway?: (event: MouseEvent | TouchEvent) => void } & PopoverProps) {
  return (
    <StyledPopover {...others}>
      <ClickAwayListener onClickAway={onClickAway}>
        <Container>
          <PopperWrapper>
            {children}
            {footer}
          </PopperWrapper>
        </Container>
      </ClickAwayListener>
    </StyledPopover>
  );
}
