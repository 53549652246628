import { Modal as BaseModal, ModalBackdropSlotProps } from "@mui/base/Modal";
import clsx from "clsx";
import { ReactNode, forwardRef, useCallback, useImperativeHandle, useState } from "react";

import { Box } from "@hkexpressairwayslimited/ui/src";

import { BasicModalContent, BasicModalContentProps, BasicModalTemplate } from "./BasicModalTemplate";

import classes from "./Modal.module.scss";

export type ModalProps<Template extends {} = {}> = {
  custom?: boolean;
  children: ReactNode;
  onClose?: () => void;
  setIsOpen?: (isOpen: boolean) => void;
  isSetMaxHeight?: boolean;
  autoWidth?: boolean;
  noPadding?: boolean;
  noCrossIcon?: boolean;
  disabledBackDropClose?: boolean;
} & Template;

export type ModalHandle = {
  open: () => void;
  close: (event?: any, reason?: "backdropClick" | "escapeKeyDown") => void;
  showModal: boolean;
};

export const Modal = forwardRef<ModalHandle, ModalProps<BasicModalContentProps>>(
  (
    {
      custom,
      children,
      setIsOpen,
      onClose,
      isSetMaxHeight,
      autoWidth = false,
      noPadding = false,
      noCrossIcon = false,
      disabledBackDropClose = false,
      ...others
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);
    const handleOpen = useCallback(() => {
      setOpen(true);
      setIsOpen?.(true);
    }, [setOpen, setIsOpen]);
    const handleClose = useCallback(
      (event?: any, reason?: "backdropClick" | "escapeKeyDown") => {
        if (disabledBackDropClose === true && (reason === "backdropClick" || reason === "escapeKeyDown")) {
          return;
        }
        setOpen(false);
        setIsOpen?.(false);
        onClose?.();
      },
      [setOpen, setIsOpen, onClose]
    );

    useImperativeHandle(
      ref,
      () => ({
        open: handleOpen,
        close: handleClose,
        showModal: open,
      }),
      [open]
    );

    const content = custom ? (
      children
    ) : (
      <BasicModalContent {...others} onCrossClick={noCrossIcon ? undefined : handleClose}>
        {children}
      </BasicModalContent>
    );
    return (
      <BaseModal
        className={classes.modal}
        disableScrollLock
        open={open}
        onClose={handleClose}
        slots={{ backdrop: ModalBackdrop }}
      >
        <BasicModalTemplate
          isSetMaxHeight={isSetMaxHeight}
          autoWidth={autoWidth}
          noPadding={noPadding}
          onCrossClick={handleClose}
        >
          {content}
        </BasicModalTemplate>
      </BaseModal>
    );
  }
);
Modal.displayName = "Modal";

type ModalBackdropProps = { className?: string } & ModalBackdropSlotProps;

export const ModalBackdrop = forwardRef<HTMLDivElement, ModalBackdropProps>(({ open, className, ...other }, ref) => {
  return <Box className={clsx(classes.modal_backdrop, className)} {...other} ref={ref} />;
});
ModalBackdrop.displayName = "ModalBackdrop";
