import {
  FieldValues,
  FormProvider,
  Form as HookForm,
  FormProps as HookFormProps,
  UseFormReturn,
} from "react-hook-form";

export type FormProps<FormValues extends FieldValues = FieldValues> = {
  formHook: UseFormReturn<FormValues>;
} & HookFormProps<FormValues>;

export const Form = <FormValues extends FieldValues = FieldValues>({ formHook, ...others }: FormProps<FormValues>) => {
  return (
    <FormProvider<FormValues> {...formHook}>
      <HookForm<FormValues> {...others} />
    </FormProvider>
  );
};
