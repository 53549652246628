import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { CheckboxSingleField } from "../CheckboxSingleField/CheckboxSingleField";
import { Box } from "../../Atoms/Box/Box";
import { Button } from "../../Atoms/Button/Button";

// export interface MuiDeclarationProps {}
// const StyledDeclaration = styled(Box)<MuiDeclarationProps>(({}) => ({}));

export interface DeclarationProps {
  id: string;
  label: string;
  onSubmit: (isDeclarationChecked: boolean) => void;
  declarationLabel: string | React.ReactNode;
  mandatoryErrorText: string;
  // isRequired?: boolean,
}

const style = {
  border: "1px solid #ccc",
  pt: 2,
  px: 2,
  pb: 2,
};
const declarationHeaderStyle: React.CSSProperties = {
  position: "absolute",
  top: "0",
  left: "50%",
  transform: "translateX(-50%) translateY(calc(50% - 2rem - 12px))",
  background: "white",
  // padding: '1rem',
  padding: "0.5rem 2rem",
  marginBottom: "1rem",
  marginTop: "1rem",
};

const Declaration = (props: DeclarationProps) => {
  const {
    id,
    label,
    onSubmit,
    declarationLabel: declarationText,
    mandatoryErrorText,
    // isRequired = false,
  } = props;
  const [haveSubmittedOrUpdated, setHaveSubmittedOrUpdated] = React.useState(false);
  const [haveCheckedDeclaration, setHaveCheckedDeclaration] = React.useState(false);

  const onChangeCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    if (checked) {
      setHaveCheckedDeclaration(true);
    } else {
      setHaveCheckedDeclaration(false);
    }
    setHaveSubmittedOrUpdated(true);
  };
  const onClickSubmit = () => {
    setHaveSubmittedOrUpdated(true);
    if (!haveCheckedDeclaration) {
      onSubmit && onSubmit(false);
      return;
    }
    onSubmit && onSubmit(true);
  };

  return (
    <React.Fragment>
      <Grid container justifyContent='center' alignItems='center' direction={"column"} rowSpacing={3}>
        <Grid item>
          <Box sx={{ ...style, width: "fit-content" }}>
            <CheckboxSingleField
              id={id}
              onChange={onChangeCheckbox}
              item={{
                key: "declaration",
                label: declarationText,
                defaultChecked: false,
              }}
              required={true}
              isError={haveSubmittedOrUpdated && !haveCheckedDeclaration}
              errorText={haveSubmittedOrUpdated && !haveCheckedDeclaration ? mandatoryErrorText : ""}
            />
          </Box>
        </Grid>
        <Grid item>
          <Button onClick={onClickSubmit}>Confirm</Button>
        </Grid>
      </Grid>
      <Typography marginY={-3} variant={"subtitle1"} style={declarationHeaderStyle}>
        {label}
      </Typography>
    </React.Fragment>
  );
};

export { Declaration };
