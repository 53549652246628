import {
  AdultIcon,
  Box,
  Button,
  ChildIcon,
  CloseIcon,
  EPixel,
  Font,
  FormFieldProps,
  InfantIcon,
  NumberInput,
  Popper,
  PopperHandle,
  SvgIcon,
  Text,
  TextSize,
  Tooltip,
  useMediaQueries,
} from "@hkexpressairwayslimited/ui/src";
import { Stack, styled } from "@mui/material";
import { clsx } from "clsx";
import { ForwardedRef, MouseEvent, forwardRef, useState } from "react";
import classes from "./SelectPassengers.module.scss";

export type SelectPassengersValue = {
  adult: number;
  child: number;
  infant: number;
};

export type SelectPassengersProps = {
  value?: SelectPassengersValue;
  adultLabel?: string;
  childLabel?: string;
  childLabelTip?: string;
  infantLabel?: string;
  infantLabelTip?: string;
  popUpTitle?: string;
  adultLabelTip?: string;
  childToolTipLabel?: string;
  infantToolTipLabel?: string;
  max?: number;
} & Partial<FormFieldProps>;
const StyledToolTipBox = styled(Box)(({ theme }) => ({
  marginLeft: "2px",
  "& .MuiPopper-root": {
    color: "#fff !important",
  },
  "& .MuiPopper-root > div.popper-content": {
    backgroundColor: "#F6F4F9 !important",
    border: "none !important",
    fontSize: "12px !important",
    lineHeight: "18px !important",
    padding: "10px 12px !important",
    color: "#702B91 !important",
    textAlign: "left",
  },
  "& .arrowWrapper > .arrow": {
    borderTop: "none",
    borderLeft: "none",
  },
}));
export const SelectPassengers = forwardRef((props: SelectPassengersProps, ref: ForwardedRef<HTMLButtonElement>) => {
  const { value, onChange, max = 9 } = props;
  const { isDesktop, isMobile } = useMediaQueries();
  const [popperHandle, setPopperHandle] = useState<PopperHandle | null>();
  const {
    adultLabel = "Adult",
    childLabel = "Child",
    infantLabel = "Infant",
    adultLabelTip = "",
    childLabelTip = "2-11 years",
    infantLabelTip = "Below 2 years",
    popUpTitle = "No. of passengers",
    childToolTipLabel = "Children must be accompanied by an adult (at least 18 years old)",
    infantToolTipLabel = "Please note, by selecting the below you must seat your infant in your lap. Should you choose to purchase a seat for your infant, please contact our call center.",
  } = props;
  const handleNumberChange = (k: string, e: any) => {
    onChange?.({ ...value, [k]: Number(e.target.value) });
  };
  const onAnchorClick = (event: MouseEvent<HTMLElement>) => {
    popperHandle?.open(event.currentTarget);
  };
  const hidePopper = () => {
    popperHandle?.close();
  };
  return (
    <>
      <Button onClick={onAnchorClick} custom className={classes.selectPassengers}>
        <Stack direction='row'>
          {value?.adult > 0 && (
            <Stack direction='row' alignItems='center' sx={{ mr: "16px" }}>
              <SvgIcon sx={{ mr: EPixel.px4 }} className={classes.icon}>
                <AdultIcon />
              </SvgIcon>
              {value?.adult}
            </Stack>
          )}
          {value?.child > 0 && (
            <Stack direction='row' alignItems='center' sx={{ mr: "16px" }}>
              <SvgIcon sx={{ mr: EPixel.px4 }} className={classes.icon}>
                <ChildIcon />
              </SvgIcon>
              {value?.child}
            </Stack>
          )}
          {value?.infant > 0 && (
            <Stack direction='row' alignItems='center'>
              <SvgIcon sx={{ mr: EPixel.px4 }} className={classes.icon}>
                <InfantIcon />
              </SvgIcon>
              {value?.infant}
            </Stack>
          )}
        </Stack>
      </Button>
      <Popper
        sx={{
          width: isDesktop ? "calc(100% + 82px)" : "initial",
          transform: isDesktop ? "initial" : "initial !important",
          zIndex: "9998",
        }}
        arrow
        focusOutClose
        ref={setPopperHandle}
        gap={26}
      >
        <Box className={clsx(!isDesktop && classes.selectPassengersList)}>
          <Stack
            sx={{
              display: isDesktop ? "none !important" : "flex !important",
              mb: isDesktop ? 0 : EPixel.px24,
            }}
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box sx={{ fontSize: EPixel.px20, fontWeight: "bold" }}>{popUpTitle}</Box>
            <SvgIcon onClick={hidePopper} sx={{ width: EPixel.px32, height: EPixel.px32, cursor: "pointer" }}>
              <CloseIcon />
            </SvgIcon>
          </Stack>
          <Stack
            className={classes.selectPassengersListItem}
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Stack direction='row' alignItems='center'>
              <SvgIcon sx={{ mr: EPixel.px4, color: "primary.main" }} className={classes.icon}>
                <AdultIcon />
              </SvgIcon>
              <Box>
                <Box>{adultLabel}</Box>
                {adultLabelTip && (
                  <Text size={TextSize.P3Regular}>
                    <Font sx={{ color: "neutral.grey" }}>{adultLabelTip}</Font>
                  </Text>
                )}
              </Box>
            </Stack>
            <NumberInput
              max={max - (value?.child || 0)}
              min={value?.infant || 1}
              className={classes.numberInputBox}
              value={value?.adult || 1}
              onChange={(e) => handleNumberChange("adult", e)}
            />
          </Stack>
          <Stack
            className={classes.selectPassengersListItem}
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Stack direction='row' alignItems='center'>
              <SvgIcon sx={{ mr: EPixel.px4, color: "primary.main" }} className={classes.icon}>
                <ChildIcon />
              </SvgIcon>

              <Box>
                <Stack direction='row' alignItems='center'>
                  {childLabel}
                  <StyledToolTipBox>
                    <Tooltip arrow placement='bottom' variant='purple'>
                      <Box sx={{ color: "primary.main" }}>{childToolTipLabel}</Box>
                    </Tooltip>
                  </StyledToolTipBox>
                </Stack>
                {childLabelTip && (
                  <Text size={TextSize.P3Regular}>
                    <Font sx={{ color: "neutral.grey" }}>{childLabelTip}</Font>
                  </Text>
                )}
              </Box>
            </Stack>
            <NumberInput
              max={max - (value?.adult || 0)}
              className={classes.numberInputBox}
              value={value?.child || 0}
              onChange={(e) => handleNumberChange("child", e)}
            />
          </Stack>
          <Stack
            className={classes.selectPassengersListItem}
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Stack direction='row' alignItems='center'>
              <SvgIcon sx={{ mr: EPixel.px4, color: "primary.main" }} className={classes.icon}>
                <InfantIcon />
              </SvgIcon>
              <Box>
                <Stack direction='row' alignItems='center'>
                  {infantLabel}
                  <StyledToolTipBox>
                    <Tooltip arrow placement='bottom' variant='purple'>
                      <Box sx={{ color: "primary.main" }}>{infantToolTipLabel}</Box>
                    </Tooltip>
                  </StyledToolTipBox>
                </Stack>
                {infantLabelTip && (
                  <Text size={TextSize.P3Regular}>
                    <Font sx={{ color: "neutral.grey" }}>{infantLabelTip}</Font>
                  </Text>
                )}
              </Box>
            </Stack>
            <NumberInput
              max={value?.adult}
              className={classes.numberInputBox}
              value={value?.infant || 0}
              onChange={(e) => handleNumberChange("infant", e)}
            />
          </Stack>
        </Box>
      </Popper>
    </>
  );
});
SelectPassengers.displayName = "SelectPassengers";
