import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export interface MainContainerProps {}

const MainContainer = styled(Box)<MainContainerProps>(({ theme }) => ({
  // background: theme.palette.neutral.background,
  padding: 24,
}));

export { MainContainer };
