import { breakpoints } from "./breakpoints";
import * as components from "./components";
import { palette } from "./palette";
import { typography } from "./typography";

export const lightTheme = {
  palette,
  typography,
  components,
  breakpoints,
};
