export { DesktopStepper as Stepper } from "./DesktopStepper";
import { EStepperItemStatus } from "./definition";
export function returnStatus(index: number, activeIndex: number) {
  if (index < activeIndex) {
    return EStepperItemStatus.checked;
  } else {
    if (index == activeIndex) {
      return EStepperItemStatus.active;
    }
    return EStepperItemStatus.disable;
  }
}
