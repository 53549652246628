import { Box, MainContainer } from "@hkexpressairwayslimited/ui/src";
import { Grid } from "@mui/material";
import { useState } from "react";
import { inputProductData } from "../../Organisms/PaymentRequestForm/PaymentForm";
import { PaymentFormSummary } from "../../Organisms/PaymentRequestForm/PaymentFormSummary/PaymentFormSummary";
import { PaymentOptionsSelect } from "../../Organisms/PaymentRequestForm/PaymentOptions/PaymentOptionsSelect";
import { PaymentOptionsSignIn } from "../../Organisms/PaymentRequestForm/PaymentOptionsSignIn/PaymentOptionsSignIn";
import { PaymentDataProps } from "../../Organisms/PaymentRequestForm/paymentTypes";
import classes from "./PaymentOptionsPageTemplate.module.scss";

interface PaymentOptionsPageTemplateProps extends PaymentDataProps {
  summaryData?: inputProductData[];
  paymentOptions: string[];
  handlePayment?: (selectedMethod: string) => void;
}
const PaymentOptionsPageTemplate = (props: PaymentOptionsPageTemplateProps) => {
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  // console.log("Payment options in second page: ", props.paymentOptions);
  return (
    <Box>
      <MainContainer sx={{ backgroundColor: "neutral.white" }}>
        <h5 className={classes.paymentOptionsPage_header}>Payment</h5>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Box>
              <Grid container rowGap={4}>
                <Grid item xs={12}>
                  <PaymentOptionsSignIn title='Sign in to your account' brand='HK Express' />
                </Grid>
                <Grid item xs={12}>
                  <PaymentOptionsSelect
                    title='1. Select a payment method'
                    subTitles={["Credit / Debit Card", "Other payment type"]}
                    paymentOptions={props.paymentOptions}
                    setPaymentMethod={setPaymentMethod}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <PaymentFormSummary
              summaryType='payment'
              data={props.data}
              summaryData={props.summaryData}
              handlePayment={props.handlePayment}
              paymentMethod={paymentMethod}
            />
          </Grid>
        </Grid>
      </MainContainer>
    </Box>
  );
};

export { PaymentOptionsPageTemplate };
