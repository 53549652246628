import { getDomain, getHostname } from "tldts";
import { LOCALE_MAPPING } from "../constant";

export const getNextLocaleUrl = (options: {
  locale: string;
  locales: string[];
  url?: string;
  pathname?: string;
  defaultLocale?: string;
  mapLocale?: boolean;
  query?: string;
}) => {
  const pathnameLocale = getPathnameLocale(
    (options.url && new URL(options.url).pathname) || options.pathname || "",
    options.locales
  );
  const localeFallback =
    getLocaleFallback(options.locale, options.locales) || pathnameLocale || options.defaultLocale || "";
  const mappedLocale = options.mapLocale ? mapLocale(localeFallback) : localeFallback;
  const pathname = (options.url || options.pathname || "")
    .replace(/([^/])$/, "$1/")
    .replace(new RegExp(`^(/){0,1}(${sortLocales([...options.locales]).join("|")})(/|/$)`), `$1${mappedLocale}$3`)
    .replace(/\/$/, "");
  const urlQuery = options.query ? options.query : (options.url && new URL(options.url).pathname) || "";
  const url = `${pathname}${urlQuery ? `?${urlQuery}` : ""}`;

  return url;
};

export const extractPathname = (pathname: string, locales: string[]) => {
  return pathname
    .replace(/([^/])$/, "$1/")
    .replace(new RegExp(`^(/){0,1}(${sortLocales([...locales]).join("|")})(/|/$)`), `$3`)
    .replace(/\/$/, "");
};

export const getPathnameLocale = (pathname: string, locales: string[]) => {
  const lang = pathname.split("/")[1];

  return locales.find((locale) => locale === lang);
};

export const getLocaleFallback = (locale: string, locales: string[]) => {
  const sortedLocales = sortLocales(locales);
  const lang = locale.split("-")[0];
  const region = locale.split("-")[1];

  return (
    sortedLocales.find((localeToTest) => {
      if (localeToTest === locale) {
        return localeToTest;
      }
    }) ||
    sortedLocales.find((localeToTest) => {
      const langToTest = localeToTest.split("-")[0];

      if (langToTest === lang) {
        return localeToTest;
      }
    }) ||
    sortedLocales.find((localeToTest) => {
      const regionToTest = localeToTest.split("-")[1];

      if (regionToTest === region) {
        return localeToTest;
      }
    })
  );
};

export const sortLocales = (locales: string[]) => [...locales].sort((a, b) => b.localeCompare(a));

export const mapLocale = (locale: string) => {
  return Object.entries(LOCALE_MAPPING).find(([key]) => new RegExp(key).test(locale))?.[1] || locale;
};

export const getCookieDomain = (hostname?: string) => {
  if (typeof window === "undefined") {
    if (hostname) {
      return `.${getDomain(hostname)}`;
    } else {
      return undefined;
    }
  }
  const domain = getDomain(hostname || window.location.href);

  return (domain ? `.${domain}` : getHostname(window.location.href)) || undefined;
};
