import { forwardRef, ReactNode } from "react";

import { clsx } from "clsx";

import { Box } from "@hkexpressairwayslimited/ui/src";

import classes from "./Text.module.scss";

export enum TextSize {
  // New
  P1Regular = "P1Regular",
  P1Medium = "P1Medium",
  P2Regular = "P2Regular",
  P2Medium = "P2Medium",
  P3Regular = "P3Regular",
  P3Medium = "P3Medium",
  ButtonRegular = "ButtonRegular",

  // Obsolete
  Body1 = "Body1",
  Body2Bold = "Body2Bold",
  Body2 = "Body2",
  Body3 = "Body3",
  Body3Light = "Body3Light",
  Body4 = "Body4",
  Body5 = "Body5",
}

export type TextColor = React.CSSProperties["color"];

export type TextProps = {
  className?: string;

  size?: TextSize;

  /** Shorthand for `component="span"`, `false` by default, default root element is `p` */
  span?: boolean;

  color?: TextColor;

  small?: boolean;
  bold?: boolean;
  light?: boolean;

  ellipsis?: boolean;

  children?: ReactNode;

  style?: React.CSSProperties;
};

export const Text = forwardRef<HTMLParagraphElement | HTMLSpanElement, TextProps>(
  (
    { className, span, size = !span && TextSize.P1Regular, small, bold, light, color, style = {}, ellipsis, ...others },
    ref
  ) => {
    const textComponent = small ? "small" : span ? "span" : "p";

    return (
      <Box
        className={clsx(
          classes.text,
          {
            [classes.text__small]: small,
            [classes.text__light]: light,
            [classes.text__bold]: bold,
            [classes.text__ellipsis]: ellipsis,
            [classes.text__body1]: size === TextSize.Body1,
            [classes.text__body2]: size === TextSize.Body2,
            [classes.text__body2Bold]: size === TextSize.Body2Bold,
            [classes.text__body3]: size === TextSize.Body3,
            [classes.text__body3Light]: size === TextSize.Body3Light,
            [classes.text__body4]: size === TextSize.Body4,
            [classes.text__body5]: size === TextSize.Body5,
            [classes.text__p1Regular]: size === TextSize.P1Regular,
            [classes.text__p1Medium]: size === TextSize.P1Medium,
            [classes.text__p2Regular]: size === TextSize.P2Regular,
            [classes.text__p2Medium]: size === TextSize.P2Medium,
            [classes.text__p3Regular]: size === TextSize.P3Regular,
            [classes.text__p3Medium]: size === TextSize.P3Medium,
            [classes.text__buttonRegular]: size === TextSize.ButtonRegular,
          },
          className
        )}
        style={{ color, ...style }}
        component={textComponent}
        {...others}
        ref={ref}
      />
    );
  }
);
Text.displayName = "Text";
