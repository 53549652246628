import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";

interface ButtonProps {}

const StyledIconButton = styled(IconButton)<ButtonProps>(({}) => ({
  // backgroundColor: colors.colorSecondary,
}));

export { StyledIconButton as IconButton };
