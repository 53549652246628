import React from "react";
import { styled } from "@mui/material/styles";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Grid, Link, Theme } from "@mui/material";

export interface SocialIconListProps {
  fbLink?: string;
  lkLink?: string;
  ytLink?: string;
  igLink?: string;
  theme?: Theme;
  style?: React.CSSProperties;
}

const SocialMediaWrapper = styled(Grid)(({ theme }) => ({
  // marginBottom: '48px',
  a: {
    display: "inline-block",
  },
}));

const SocialIconList = (props: SocialIconListProps) => {
  const { fbLink, lkLink, ytLink, igLink, theme, style = {} } = props;
  return (
    <SocialMediaWrapper theme={theme} style={style} container justifyContent='space-between' spacing={2}>
      {!!fbLink && (
        <Grid item>
          <Link href={fbLink} underline='none'>
            <FacebookIcon fontSize='large' />
          </Link>
        </Grid>
      )}
      {!!lkLink && (
        <Grid item>
          <Link href={lkLink} underline='none'>
            <LinkedInIcon fontSize='large' />
          </Link>
        </Grid>
      )}
      {!!ytLink && (
        <Grid item>
          <Link href={ytLink} underline='none'>
            <YouTubeIcon fontSize='large' />
          </Link>
        </Grid>
      )}
      {!!igLink && (
        <Grid item>
          <Link href={ytLink} underline='none'>
            <InstagramIcon fontSize='large' />
          </Link>
        </Grid>
      )}
    </SocialMediaWrapper>
  );
};

export { SocialIconList };
