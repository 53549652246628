import { EBorders, EPixel, ERadius, ESpacing } from "@hkexpressairwayslimited/ui/src";
import {
  Box,
  BoxProps,
  ClickAwayListener,
  ClickAwayListenerProps,
  Popper as MuiPopper,
  PopperProps as MuiPopperProps,
  Theme,
  styled,
} from "@mui/material";
import { nanoid } from "nanoid";
import { ReactNode, forwardRef, useCallback, useImperativeHandle, useMemo, useState } from "react";

export type PopperHandle = {
  open: (targetAnchor: HTMLElement) => void;
  close: () => void;
  showPanel: boolean;
};

export type PopperProps = {
  gap?: number;
  arrow?: boolean;
  focusOutClose?: boolean;
  children: ReactNode;
  baseUi?: boolean;
  clickAlwayListenerProps?: ClickAwayListenerProps;
  open?: boolean;
  tooltip?: boolean;
} & Omit<MuiPopperProps, "open" | "children">;

export interface CustomBoxProps extends BoxProps {
  tooltip?: boolean;
}

const StyledPopper = styled(MuiPopper)(({ theme, baseUi, arrow, gap = 0 }: { theme: Theme } & PopperProps) => ({
  zIndex: 998,
  '&[data-popper-placement*="top"] .popper-content': {
    marginBottom: `${gap + (baseUi ? 0 : arrow ? 12 : 0)}px`,
    marginTop: "unset",
  },
  '&[data-popper-placement*="top"] .arrowWrapper': {
    bottom: "0",
  },
  '&[data-popper-placement*="top"] .arrowWrapper .arrow': {
    transform: "rotate(0.625turn)",
    marginBottom: `${gap + 8}px`,
    marginTop: "unset",
  },
  // left right TBD
}));

const Arrow = styled(Box)<CustomBoxProps>(({ theme, tooltip }) => ({
  width: "16px",
  height: "16px",
  transform: "rotate(0.125turn)",
  borderTop: tooltip ? 0 : EBorders.b1,
  borderLeft: tooltip ? 0 : EBorders.b1,
  backgroundColor: tooltip ? "#f4f4f7" : theme.palette.neutral.white,
}));

export const Popper = forwardRef<PopperHandle, PopperProps>(
  (
    { className, arrow, focusOutClose, children, baseUi, gap = 0, clickAlwayListenerProps = {}, tooltip, ...others },
    ref
  ) => {
    const anchorId = useMemo(() => nanoid(), []);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
    const [arrowEl, setArrowEl] = useState<HTMLElement | null>();

    const showPanel = useMemo(() => !!anchorEl, [anchorEl]);

    const openPanel = useCallback(
      (targetAnchor: HTMLElement) => {
        setAnchorEl(targetAnchor);
      },
      [setAnchorEl]
    );

    const closePanel = useCallback(() => {
      setAnchorEl(null);
    }, [setAnchorEl]);

    useImperativeHandle(
      ref,
      () => ({
        open: openPanel,
        close: closePanel,
        showPanel,
      }),
      [openPanel, closePanel, showPanel]
    );

    return (
      <StyledPopper
        id={anchorId}
        anchorEl={anchorEl}
        placement='bottom'
        disablePortal={true}
        gap={gap}
        arrow={arrow}
        modifiers={[
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "viewport",
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: "viewport",
            },
          },
          {
            name: "arrow",
            enabled: arrow,
            options: {
              element: arrowEl,
            },
          },
          {
            name: "applyArrowHide",
            enabled: true,
            phase: "write",
            fn({ state }: any) {
              if (!baseUi && arrow) {
                const { arrow: arrowElm } = state.elements;
                if (arrowElm) {
                  if (state.modifiersData.arrow.centerOffset > 0) {
                    arrowElm.style.left = "16px";
                  }
                  if (state.modifiersData.arrow.centerOffset < 0) {
                    arrowElm.style.left = "-16px";
                  }
                }
              }
            },
          },
        ]}
        {...others}
        open={others.open === undefined ? showPanel : others.open}
      >
        {!baseUi && arrow && (
          <Box ref={setArrowEl} className='arrowWrapper'>
            <Arrow
              className='arrow'
              sx={{
                marginTop: `${gap + 4}px`,
              }}
              tooltip={tooltip}
            />
          </Box>
        )}
        <ClickAwayListener
          {...clickAlwayListenerProps}
          onClickAway={() => {
            focusOutClose && closePanel();
          }}
        >
          <Box
            className='popper-content'
            sx={
              baseUi
                ? {}
                : {
                    fontSize: EPixel.px12,
                    marginTop: `${gap + (baseUi ? 0 : arrow ? 12 : 0)}px`,
                    padding: `10px ${ESpacing._xs}`,
                    border: tooltip ? "none" : EBorders.b1,
                    borderRadius: tooltip ? ERadius.r200 : ERadius.r300,
                    color: `${tooltip ? "purple.default" : "neutral.black"}`,
                    textAlign: tooltip ? "left" : "none",
                    backgroundColor: tooltip ? "#f4f4f7" : "neutral.white",
                    minWidth: tooltip ? "244px" : "unset",
                    maxWidth: tooltip ? "244px" : "unset",
                    boxShadow: tooltip ? "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" : "none",
                  }
            }
          >
            {children}
          </Box>
        </ClickAwayListener>
      </StyledPopper>
    );
  }
);

Popper.displayName = "Popper";
