import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function MultipleIcon() {
  return (
    <SvgIcon>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M0 4C0 1.79086 1.79086 0 4 0H20C22.2091 0 24 1.79086 24 4V20C24 22.2091 22.2091 24 20 24H4C1.79086 24 0 22.2091 0 20V4Z'
          fill='#702B91'
        />
        <line x1='5' y1='12.2' x2='19' y2='12.2' stroke='white' strokeWidth='1.6' />
      </svg>
    </SvgIcon>
  );
}
